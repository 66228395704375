
import global from '../../common/utils/global.js';
import { formatZuluTime } from '../../common/utils/timeformats.js';
import { WebSocketService } from '../../common/data/services/websocketservice.js';
import { displayMainUnitsGroupsList, openNewUnitGroupForm, openEditDeleteUnitsGroupForm, openAssignUnitsGroupForm } from './components/unitgroupsview.js';
import { getUnits, getTrailers, updateUnitMetrics } from '../../common/data/handleunitdata.js';
import { openUnitConfigForm } from './components/unitconfig.js';
import { displayDetailPanes, displayUnitsListTrailersOnly } from './detailpage.js';
import { displayStatusSummary, createSubunitContainers, splitSubunits, loadUnitStatusDiagram, loadUnitOnlyStatusDiagram, loadTrailerStatusDiagram } from './diagrams/unitstatusesdiagrams.js';

const wsService = new WebSocketService();

function getMainPaneSensorUpdates(units) {
    wsService.removeListeners(); //Remove all event listeners
    wsService.disconnect(); //Ensure any previous connection is closed    

    wsService.connect('sensorUpdates');

    wsService.addEventListener('message', (event) => {
        const data = event.detail;
        console.log('Received message for dashboard:', data);
        // Handle dashboard-specific data
        displaySensorUpdatesMain(units, data);
    });

    window.addEventListener('unload', () => {
        wsService.disconnect();
    });
}

function displaySensorUpdatesMain(units, data) {

    let unit = units.find(u => u.imei === data.imei);

    if (!unit) {
        console.warn(`Unit with IMEI ${data.imei} not found.`);
        return;
    }

    unit = updateUnitMetrics(unit, data);
    displayStatusSummary(units || []);
    
    if (document.getElementById(`u${data.imei}pressure${data.wheelSensorUID}`)) {
        const pressure = parseFloat(data .currentPressure).toFixed(1);
        document.getElementById(`u${data.imei}pressure${data.wheelSensorUID}`).innerText = pressure;
        document.getElementById(`u${data.imei}pressure${data.wheelSensorUID}`).className = `sensorvaluesstatus ${(unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
            unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID)).pressureLabelColour}`;
    }

    if (document.getElementById(`u${data.imei}temperature${data.wheelSensorUID}`)) {
        document.getElementById(`u${data.imei}temperature${data.wheelSensorUID}`).innerText = `${data.currentTemperature}`;
        document.getElementById(`u${data.imei}temperature${data.wheelSensorUID}`).className = `sensorvaluesstatus ${(unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
            unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID)).temperatureLabelColour}`;
    }

    if (document.getElementById(`u${data.imei}voltage${data.wheelSensorUID}`)) {
        document.getElementById(`u${data.imei}voltage${data.wheelSensorUID}`).innerText = `${data.currentVoltage.toFixed(1)}`;
        document.getElementById(`u${data.imei}voltage${data.wheelSensorUID}`).className = `sensorvaluesstatus ${(unit.wheelSensors.find(s => s.sensorId === data.wheelSensorUID) ||
            unit.spareSensors.find(s => s.sensorId === data.wheelSensorUID)).voltageLabelColour}`;
    }

    if (document.getElementById('lastupdatedmain')) {
        document.getElementById('lastupdatedmain').innerText = `${formatZuluTime(data.payloadTS)}`;
    }    
}

export const setMainInterval = async () => {

    main();
    if (global.mainIntervalId) {
        clearInterval(global.mainIntervalId);
    }
  
    global.mainIntervalId = setInterval(async () => {
  
        main();
    }, 300000); //5 minutes: 1 minute = 60,000 milliseconds; 30 minutes = 30 * 60000 = 1800000 milliseconds;  
  }

export const main = async () => {
    let units;

    try {
        units = await getUnits();
    } catch (error) {
        console.error("❌ Error fetching units:", error);
    }

    global.units = units;
    try {
        if (global.showMainPage) {

            for (let u = 0; u < units.length; u++) {
                //units[u].trailers = await getUnitTrailers(resource, units[u]);
            }
            getMainPaneSensorUpdates(units);
            displayStatusSummary(units || []);
            displayMainPane(units);            
        }
        else {
            if (global.mainIntervalId) {
                clearInterval(global.mainIntervalId);
            }
        }       

    } catch (error) {
        console.log("mainpageJS: main - ERROR " + error + ")");
        console.error(error);
        return;
    }
}

export function displayMainPane(units) {

    if (global.mainUnitsListSelected) {
        displayMainUnitsList(units);
    } else {
        displayMainUnitsGroupsList(units);
    }
    
    removeDetailPanes();
    document.getElementById('mainpane').style.display = 'block';
    document.getElementById('unitslistmain').style.display = 'block';
    switch (global.selectedMetric) {
        case 'PRESSURE':
            document.getElementById('rbtnPressure').checked = true;
            break;
        case 'TEMPERATURE':
            document.getElementById('rbtnTemperature').checked = true;
            break;
        case 'VOLTAGE':
            document.getElementById('rbtnVoltage').checked = true;
            break;
    }

    const statusRadioButtons = document.getElementById('statusradiobtns');
    statusRadioButtons.onclick = (e) => {

        if (global.mainUnitsListSelected)
            displayStatusSummary(units || []);
        else
            displayStatusSummary(global.selectedGroupUnits || []);

        e.stopImmediatePropagation();
    };

    if (!global.unitsGroupsEventsRegistered) {
        global.unitsGroupsEventsRegistered = true;
        registerUnitListEvents(units)
        //registerUnitGroupEvents(units);
    }

    global.showMainPage = true;
}

function registerUnitListEvents(units) {

    const unitsListButtonMain = document.getElementById('unitslistbuttonmain');
    unitsListButtonMain.onclick = (e) => {
        e.stopImmediatePropagation();

        global.mainUnitsListSelected = true;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnTemperature').disabled = false;
        document.getElementById('rbtnVoltage').disabled = false;

        //***displayMainPane(units);
        main();
    };

    const unitGroupsButtonMain = document.getElementById('unitgroupsbuttonmain');
    unitGroupsButtonMain.onclick = (e) => {
        e.stopImmediatePropagation();

        global.mainUnitsListSelected = false;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnPressure').checked = true;
        global.selectedMetric = 'PRESSURE';

        document.getElementById('rbtnTemperature').disabled = true;
        document.getElementById('rbtnVoltage').disabled = true;

        //***displayMainPane(units);
        main();
    };

    const unitsMenuMain = document.getElementById('unitsmenumain');
    unitsMenuMain.onclick = (e) => {
        if (e.target.tagName === 'LI') {
            switch (e.target.id) {                
                case 'truckslistmainpage':
                    displayTrucksList(units);
                    break;
                case 'trailerslistmainpage':
                    displayTrailersList(units);
                    break;
                case 'configuretrailersmainpage':
                    openUnitConfigForm(null, null, 'TRAILER', null);
                    break;
            }
        }
    }

    const unitsListMenu = document.getElementById('unitslistmenu');
    unitsListMenu.onclick = (e) => {
        if (e.target.tagName === 'LI') {
            switch (e.target.id) {
                case 'truckslistdetailpage':
                    //displayTrucksList(units)
                break;
                case 'trailerslistdetailpage':
                    displayUnitsListTrailersOnly();
                    break;
                case 'configuretrailersdetailpage':
                    openUnitConfigForm(null, null, 'TRAILER', null);
                    break;
            }
        }
    }

    const unitGroupsMenuMain = document.getElementById('unitgroupsmenumain');
    unitGroupsMenuMain.onclick = (e) => {        
        if (e.target.tagName === 'LI') {
            switch (e.target.id) {
                case 'newunitsgroupmain':
                    openNewUnitGroupForm(units);
                    break;
                case 'editdeleteunitsgroupmain':
                    openEditDeleteUnitsGroupForm(units);
                    break;
                case 'assignunitsgroupmain':
                    openAssignUnitsGroupForm(units);
                    break;
            }
        }
        //e.stopImmediatePropagation();
    }

    const unitgroupsmenu = document.getElementById('unitgroupsmenu');
    unitgroupsmenu.onclick = (e) => {        
        if (e.target.tagName === 'LI') {
            switch (e.target.id) {
                case 'newunitsgroup':
                    openNewUnitGroupForm(units);
                    break;
                case 'editdeleteunitsgroup':
                    openEditDeleteUnitsGroupForm(units);
                    break;
                case 'assignunitsgroup':
                    openAssignUnitsGroupForm();
                    break;
            }
        }
        //e.stopImmediatePropagation();
    }

    const unitslistbutton = document.getElementById('unitslistbutton');
    unitslistbutton.addEventListener('click', function clickHandler(e) {
        e.stopImmediatePropagation();
        global.showMainPage = true;
        global.mainUnitsListSelected = true;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        //***displayMainPane(units);
        main();
    });

    const unitGroupsButton = document.getElementById('unitgroupsbutton');
    unitGroupsButton.onclick = (e) => {
        e.stopImmediatePropagation();

        global.mainUnitsListSelected = false;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnTemperature').disabled = true;
        document.getElementById('rbtnVoltage').disabled = true;

        document.getElementById('rbtnPressure').checked = true;
        global.selectedMetric = 'PRESSURE';

        //***displayMainPane(units);
        main();
    };
}

function removeDetailPanes() {

    const leftPane = document.getElementById('leftpane');
    const unitsList = document.getElementById('unitslist');
    while (unitsList.hasChildNodes()) {
        unitsList.removeChild(unitsList.lastChild);
    }
    //leftPane.style.display = "none";

    let middlepane = document.getElementById('middlepane');
    while (middlepane.hasChildNodes()) {
        middlepane.removeChild(middlepane.lastChild);
    }
    //middlepane.style.display = "none";

    let rightpane = document.getElementById('rightpane');
    while (rightpane.hasChildNodes()) {
        rightpane.removeChild(rightpane.lastChild);
    }
    //rightpane.style.display = "none";

    let detailsPane = document.getElementById('detailspane');
    detailsPane.style.display = "none";
}

const displayMainUnitsList = (units) => {
    try {
        let unitsListMain = document.getElementById('unitslistmain');
        while (unitsListMain.hasChildNodes()) {
            unitsListMain.removeChild(unitsListMain.lastChild);
        }

        let unitsListFragment = document.createDocumentFragment();
        let unitsListContainerDiv = createUnitsListContainer();
        unitsListFragment.appendChild(unitsListContainerDiv);

        unitsListMain.appendChild(unitsListFragment);
       
        for (let i = 0; i < units.length; i++) {

            let u = units[i];

            const isGroupUnit = false;
            const unitDiv = createMainUnitContainer(u, isGroupUnit);
            unitsListContainerDiv.appendChild(unitDiv);

            unitDiv.onclick = (e) => {
                e.stopImmediatePropagation();
                global.showMainPage = false;
                global.selectedUnit = u;
                global.selectedUnitId =  u.unitId || u.imei;
                global.selectedGroupId = 0;
                global.sensorValuesDiagramDateSelected = false;
                global.selectedDateTime = "";
                displayDetailPanes();
            };
        }        

        $("#loadingBox").modal('hide');
        document.body.style.cursor = 'default';
        document.getElementById('splashcontainer').style.display = 'none';
    }
    catch (e) {
        console.error("mainpageJS - displayMainUnitsList: " + e.message);

        global.selectedUnitId = 0;
        return;
    }
}

function displayTrucksList(units) {
    try {
        
        // Example usage
        let trucksOnly = units.filter(unit => unit.type === 'truck');


        let unitsListMain = document.getElementById('unitslistmain');
        while (unitsListMain.hasChildNodes()) {
            unitsListMain.removeChild(unitsListMain.lastChild);
        }

        let unitsListFragment = document.createDocumentFragment();
        let unitsListContainerDiv = createUnitsListContainer();
        unitsListFragment.appendChild(unitsListContainerDiv);

        unitsListMain.appendChild(unitsListFragment);

        //global.units = units;        

        for (let u = 0; u < trucksOnly.length; u++) {

            const unit = trucksOnly[u];

            const isGroupUnit = false;
            const unitDiv = createUnitsContainer(unit, isGroupUnit);
            unitsListContainerDiv.appendChild(unitDiv);

            unitDiv.onclick = (e) => {
                e.stopImmediatePropagation();
                global.showMainPage = false;
                global.selectedUnit = unit;
                global.selectedUnitId = unit.unitId;
                global.selectedGroupId = 0;
                global.sensorValuesDiagramDateSelected = false;
                global.selectedDateTime = "";
                displayDetailPanes(unit);
            };
        }

        displayStatusSummary(trucksOnly || []);

        $("#loadingBox").modal('hide');
        document.body.style.cursor = 'default';
        //document.getElementById('splashcontainer').style.display = 'none';
    }
    catch (e) {
        console.error("mainpageJS - displayTrucksList: " + e.message);
        global.selectedUnitId = 0;
        return;
    }
}

export function createUnitsContainer(unit, isGroupUnit) {

    const unitDiv = document.createElement('div');
    unitDiv.id = isGroupUnit ? `maingroupunit${unit.unitId}` : `mainunit${unit.unitId}`;
    unitDiv.className = 'unit';

    // Create the unitContent div that will contain the unit header and status diagram
    const unitContent = document.createElement('div');
    unitContent.className = 'unit-content';

    // Create the unit header div
    const unitHeaderDiv = document.createElement('div');
    unitHeaderDiv.className = 'unitheader';
    unitHeaderDiv.textContent = unit.name || unit.imei; // Add unit name or IMEI

    unitContent.appendChild(unitHeaderDiv);

    // Create the unit status div
    const unitStatusDiv = document.createElement('div');
    unitStatusDiv.className = 'unitstatus';

    const unitStatusDiagramDiv = loadUnitOnlyStatusDiagram(unit);
    unitStatusDiagramDiv.className = 'statusdiagram';

    unitStatusDiv.appendChild(unitStatusDiagramDiv);
    unitContent.appendChild(unitStatusDiv);

    // Finally, append the unit content to the unit div
    unitDiv.appendChild(unitContent);

    return unitDiv;
}

const displayTrailersList = async(units) => {
    try {

        let trailers = await getTrailers(units);

        let unitsListMain = document.getElementById('unitslistmain');
        while (unitsListMain.hasChildNodes()) {
            unitsListMain.removeChild(unitsListMain.lastChild);
        }

        let unitsListFragment = document.createDocumentFragment();
        let unitsListContainerDiv = createUnitsListContainer();
        unitsListFragment.appendChild(unitsListContainerDiv);

        unitsListMain.appendChild(unitsListFragment);     

        for (let t = 0; t < trailers.length; t++) {

            let trailer = trailers[t];

            const isGroupUnit = false;
            const trailerDiv = createTrailerContainer(trailer, isGroupUnit);
            unitsListContainerDiv.appendChild(trailerDiv);

            trailerDiv.onclick = function (e) {
                e.stopImmediatePropagation();
                global.showMainPage = false;
                global.selectedUnit = trailer;
                global.selectedUnitId = trailer.imei;
                global.selectedGroupId = 0;
                global.sensorValuesDiagramDateSelected = false;
                global.selectedDateTime = "";
                displayDetailPanes(trailer);
            };
        }

        displayStatusSummary(trailers || []);

        $("#loadingBox").modal('hide');
        document.body.style.cursor = 'default';
        document.getElementById('splashcontainer').style.display = 'none';
    }
    catch (e) {
        console.error("mainpageJS - displayMainUnitsList: " + e.message);

        global.selectedUnitId = 0;
        return;
    }
}

// Functions to duplicate objects based on wheelId's last character
function findUnits(objectsArray) {
    const newArray = [];

    objectsArray.forEach(obj => {
        if (obj.wheelSensors && Array.isArray(obj.wheelSensors)) {
            // Find the unique last digits of all wheelIds
            const uniqueLastDigits = [...new Set(obj.wheelSensors
                .map(sensor => sensor.wheelId.slice(-1))
                .filter(digit => digit === '0'))];

            // For each unique last digit, create a new object
            uniqueLastDigits.forEach(lastDigit => {
                // Create a copy of the object
                const newObject = { ...obj };

                // Filter the wheelSensors based on the current last digit
                newObject.wheelSensors = obj.wheelSensors.filter(sensor => sensor.wheelId.endsWith(lastDigit));

                if ('spareSensors' in newObject) {
                    delete newObject.spareSensors;
                };

                // Only add the new object if there are wheelSensors that match
                if (newObject.wheelSensors.length > 0) {
                    newArray.push(newObject);
                }
            });
        } else {
            // If there are no wheelSensors, simply add the original object
            newArray.push(obj);
        }
    });

    return newArray;
}

export function createTrailerContainer(trailer, isGroupUnit) {
    
    const unitDiv = document.createElement('div');
    unitDiv.id = isGroupUnit ? `maingroupunit${trailer.Id}` : `mainunit${trailer.Id}`;
    unitDiv.className = 'unit';

    // Create the unitContent div that will contain the unit header and status diagram
    const unitContent = document.createElement('div');
    unitContent.className = 'trailer-content';
    

    // Create the unit header div
    const unitHeaderDiv = document.createElement('div');
    unitHeaderDiv.className = 'unitheader';
    unitHeaderDiv.textContent = trailer?.name|| trailer?.wheelSensors?.[0]?.repeater?.trailerName || trailer?.Id; // Add unit name or IMEI

    unitContent.appendChild(unitHeaderDiv);

    // Create the unit status div
    // const unitStatusDiv = document.createElement('div');
    // unitStatusDiv.className = 'trailerstatus';

    // const unitStatusDiagramDiv = loadTrailerStatusDiagram(trailer);
    // unitStatusDiagramDiv.className = 'statusdiagram';

    // unitStatusDiv.appendChild(unitStatusDiagramDiv);
    // unitContent.appendChild(unitStatusDiv);

    if (trailer?.wheelSensors && trailer.wheelSensors.length > 0) {
        const unitStatusDiv = document.createElement('div');
        unitStatusDiv.className = 'trailerstatus';

        const unitStatusDiagramDiv = loadTrailerStatusDiagram(trailer); // Load diagram
        unitStatusDiagramDiv.className = 'statusdiagram';

        unitStatusDiv.appendChild(unitStatusDiagramDiv);
        unitContent.appendChild(unitStatusDiv);
    }
    unitDiv.appendChild(unitContent);

    return unitDiv;
}

export function createMainUnitContainer(unit, isGroupUnit) {
    const unitDiv = document.createElement('div');
    unitDiv.id = isGroupUnit ? `maingroupunit${unit.unitId}` : `mainunit${unit.Id}`;
    unitDiv.className = 'unit';

    // Create the unitContent div that will contain the unit header and status diagram
    const unitContent = document.createElement('div');
    unitContent.className = 'unit-content';

    // Create the toggle button
    const toggleButton = document.createElement('button');
    toggleButton.className = 'toggle-button';
    toggleButton.textContent = '+';
    toggleButton.addEventListener('click', (e) => {
        e.stopPropagation();  // Prevents the unitDiv's onclick from firing
        toggleUnitView(unit, toggleButton, unitDiv);
    });

    // Create the unit header div
    const unitHeaderDiv = document.createElement('div');
    unitHeaderDiv.className = 'unitheader';
    unitHeaderDiv.textContent = unit.name || unit.imei; // Add unit name or IMEI

    // Append the toggle button and the unitHeader to the unitContent in the desired order
    unitContent.appendChild(toggleButton);
    unitContent.appendChild(unitHeaderDiv);

    // Create the unit status div
    const unitStatusDiv = document.createElement('div');
    unitStatusDiv.className = 'unitstatus';

    const unitStatusDiagramDiv = loadUnitStatusDiagram(unit);
    unitStatusDiagramDiv.className = 'statusdiagram';

    unitStatusDiv.appendChild(unitStatusDiagramDiv);
    unitContent.appendChild(unitStatusDiv);

    // Finally, append the unit content to the unit div
    unitDiv.appendChild(unitContent);

    return unitDiv;
}

function toggleUnitView(unit, toggleButton, unitDiv) {
    const isExpanded = toggleButton.textContent === '-';

    if (isExpanded) {
        toggleButton.textContent = '+';
        const subunitContainer = document.getElementById(`subunitcontainer${unit.unitId}`);
        if (subunitContainer) {
            subunitContainer.style.display = 'none';  // Hide trailers
        }
    } else {
        toggleButton.innerHTML = '-';
        let subunitContainer = document.getElementById(`subunitcontainer${unit.unitId}`);

        // Create the subunit container if it doesn't exist
        if (!subunitContainer) {
            subunitContainer = document.createElement('div');
            subunitContainer.id = `subunitcontainer${unit.unitId}`;
            subunitContainer.className = 'subunit-container';

            // Generate subunits based on the sensor's wheel ID
            const subunits = createSubunitContainers(unit);
            subunitContainer.appendChild(subunits);

            unitDiv.appendChild(subunitContainer);
        } else {
            subunitContainer.style.display = 'block';  // Show subunits
        }
    }
}

const createUnitsListContainer = () => {

    let unitsListContainerDiv = document.createElement('div');
    unitsListContainerDiv.id = 'unitslistcontainer';
    unitsListContainerDiv.className = 'unitslistcontainer';
    if (!global.mainUnitsListSelected) {
        unitsListContainerDiv.classList.add('unitsgrouplistcontainer');
    }

    return unitsListContainerDiv;
}





