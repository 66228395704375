// import global from '../utils/global.js';
// import { SPARE_AXLE, UNPAIRED_AXLE } from '../utils/global.js';
// import { addLeadingZero } from '../utils/general.js';
// import { formatZuluTime } from '../utils/timeformats.js';
// import { fetchUnits } from './units.js';
import { fetchRepeaters, createRepeater, updateRepeater, deleteRepeater, createSignalBooster } from './repeaters.js';

export const updateRepeaters = async(unit, axleData) => {

    const existingRepeaters = await fetchRepeaters();

    for (const axle of axleData) {
        for (const side of ["left", "right"]) {
            const repeater = axle[side];
            if (repeater.unitId == null) {
                repeater.unitId = unit.Id;
            }
            try {
                // Check if this repeater already exists
                const existingRepeater = existingRepeaters.find(r =>
                    r.Id === repeater.Id 
                );

                if (existingRepeater) {  
                    // Update the existing repeater
                    await updateRepeater(unit.customerId, repeater);
                } else {
                    // Create a new repeater
                    await createRepeater(unit.customerId, repeater);
                }
            } catch (error) {
                // Log the error and continue
                console.error(`Error processing repeater ${repeater.Id || "unknown"}:`, error);
            }
        }
    }
}

export const deleteRepeaters = async (customerId, repeaters) => {
    for (const repeater of repeaters) {
        try {
            await deleteRepeater(customerId, repeater.Id);
        } catch (error) {
            console.error(`Failed to delete repeater with ID ${repeater.Id}:`, error);
        }
    }
};

export const createSignalBoosters = async (unit, signalBoosters) => {
    const failedBoosters = [];

    for (let s = 0; s < signalBoosters.length; s++) {
        const signalBooster = signalBoosters[s];

        try {
            await createSignalBooster(unit.customerId, signalBooster);
        } catch (error) {
            // Log the error and track the failed booster
            console.error(`Error processing signal booster with ID ${signalBooster.unitId || "unknown"}:`, error);
            failedBoosters.push(signalBooster);
        }
    }

    // Optionally handle or report the failedBoosters after the loop
    if (failedBoosters.length > 0) {
        console.warn(`The following signal boosters failed to process:`, failedBoosters);
    }
};



