
import { addLeadingZero } from '../../../common/utils/general.js';
import { createDiagramView, createTrailerHeader } from '../diagrams/unitdiagrams.js';
import { openNotificationsForm } from './wheelsdiagram.js';

async function createRepeaterDiagram(unit) {
    displayDiagram(unit);
}

function displayDiagram(unit) {

    const diagram = document.getElementById('rightpane');
    while (diagram.hasChildNodes()) {
        diagram.removeChild(diagram.lastChild);
    }
    const diagramFragment = document.createDocumentFragment();

    let diagramDiv = document.createElement('div');
    diagramDiv.id = `repeaterdiagram`;
    diagramDiv.className = 'unitdiagram';

    diagramFragment.appendChild(diagramDiv);
    diagram.appendChild(diagramFragment);

    const repeaterDiagramButtons = createRepeaterDiagramButtons(unit)
    diagramDiv.appendChild(repeaterDiagramButtons);

    const repeaterDiagramUnitFrame = unit.wheelSensors && unit.wheelSensors.length ? createDiagramWheelSensorsUnitFrame(unit) : createDiagramRepeatersOnlyUnitFrame(unit);
    diagramDiv.appendChild(repeaterDiagramUnitFrame);
}

function createDiagramRepeatersOnlyUnitFrame(unit) {    

    const repeaterDiagramUnitFrame = document.createElement('div');  

    repeaterDiagramUnitFrame.appendChild(createDiagramRepeatersOnlyUnitBox(unit));

    repeaterDiagramUnitFrame.appendChild(createGatewayElements(unit));

    return repeaterDiagramUnitFrame
}

function createDiagramRepeatersOnlyUnitBox(unit) {
    const repeaterDiagramUnitBox = document.createElement('div');

    // Check for valid axle data or repeaters
    const axleDataSource = unit?.axleData || unit?.repeaters;
    
    if (!axleDataSource) {
        console.warn("No axle data or repeaters found for unit.");
        return repeaterDiagramUnitBox; // Return empty container
    }

    if (unit?.axleData) {
        for (let a = 0; a < unit.axles; a++) {
            const unitBoxAxle = createRepeatersOnlyUnitBoxAxle(axleDataSource, a);
            repeaterDiagramUnitBox.appendChild(unitBoxAxle);
        }
    }

    if (unit?.repeaters) {
        for (let a = 0; a < unit.axles; a++) {
            const unitBox = axleDataSource[0].location.slice(-1);
            const unitBoxAxle = createUnitBoxAxle(unitBox, axleDataSource, a + +1);
            repeaterDiagramUnitBox.appendChild(unitBoxAxle);
        }
    }

    return repeaterDiagramUnitBox;
}


function createRepeatersOnlyUnitBoxAxle(axles, axle) {

    const unitBoxAxle = document.createElement('div');
    unitBoxAxle.className = 'diagramaxle';
    
    const axleRepeaterLeft = axles[axle].left;
    if (axleRepeaterLeft) {
        const repeaterLeft = createSensorElement(axleRepeaterLeft);
        unitBoxAxle.appendChild(repeaterLeft);
    }

    const axleRepeaterRight = axles[axle].right;
    if (axleRepeaterRight) {
        const repeaterRight = createSensorElement(axleRepeaterRight);
        unitBoxAxle.appendChild(repeaterRight);
    }

    return unitBoxAxle;
}

function createDiagramWheelSensorsUnitFrame(unit) {    

    const repeaterDiagramUnitFrame = document.createElement('div');   

    let unitBoxes = [...new Set(Object.keys(unit.wheelSensors).map(item => unit.wheelSensors[item].wheelId.slice(-1)))].sort();
    for (let ub = 0; ub < unitBoxes.length; ub++) {
        repeaterDiagramUnitFrame.appendChild(createDiagramUnitBox(unit, unit.unitConfig[ub].unitNumber));
    }

    repeaterDiagramUnitFrame.appendChild(createGatewayElements(unit));

    return repeaterDiagramUnitFrame
}

function createGatewayElements(unit) {

    const gatewayElements = document.createElement('div');
    gatewayElements.className = 'diagramaxle';

    gatewayElements.appendChild(createGatewayElement(unit));
    gatewayElements.appendChild(createSinkElement(unit));

    return gatewayElements;
}

function createGatewayElement(unit) {

    const gateway = document.createElement('div');
    gateway.className = 'gateway';
    gateway.appendChild(createGatewayTopElement());
    gateway.appendChild(createGatewayInfoElement(unit));
    gateway.appendChild(createGatewayBottomElement());

    return gateway;
}

function createSinkElement(unit) {
    
    const sink = document.createElement('div');
    sink.className = 'sink';

    //sink.appendChild(createSensorTopElement());
    sink.appendChild(createSyncSensorValues(unit));
    //sink.appendChild(createSensorBottomElement());

    return sink;
}

function createSyncSensorValues(unit) {

    const sensorValues = document.createElement('div');
    sensorValues.className = 'sinkvalues';

    sensorValues.appendChild(createSinkIdElement(unit?.sinkId || '--'));
    //sensorValues.appendChild(createSyncSignalStrengthElement(unit?.sinkPowerSupplyVoltage || '--'));
    sensorValues.appendChild(createSyncVoltageElement(unit?.sinkVoltage || '--'));

    return sensorValues;
}

function createSinkIdElement(sinkId) {

    const sinkIdDiv = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    //if (axleSensor.signalStrengthLabelColour === "blue")
    //    sensorValuesClasses = ['repeatersensorvaluesdefault', 'blue'];

    sinkIdDiv.classList.add(...sensorValuesClasses);
    sinkIdDiv.innerText = sinkId;

    return sinkIdDiv;
}

function createSyncSignalStrengthElement(sinkPowerSupplyVoltage) {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    //if (axleSensor.signalStrengthLabelColour === "blue")
    //    sensorValuesClasses = ['repeatersensorvaluesdefault', 'blue'];

    signalStrength.classList.add(...sensorValuesClasses);
    signalStrength.innerText = `${sinkPowerSupplyVoltage} V`;

    return signalStrength;
}

function createSyncVoltageElement(sinkVoltage) {

    const sensorVoltage = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    //if (axleSensor.voltageLabelColour === "yellow")
    //    sensorValuesClasses = ['repeatersensorvaluesdefault', 'yellow'];
    //const voltage = Math.round(axleSensor.currentVoltage * 1000) / 1000;

    sensorVoltage.classList.add(...sensorValuesClasses);
    sensorVoltage.innerText = `${sinkVoltage} V`;

    return sensorVoltage;
}

function createGatewayTopElement() {

    const gatewayTop = document.createElement('div');
    gatewayTop.className = 'gatewaytop';
    const gatewayTopLeft = document.createElement('div');
    gatewayTopLeft.className = 'gatewaytopleft';
    const gatewayTopRight = document.createElement('div');
    gatewayTopRight.className = 'gatewaytopright';
    gatewayTop.appendChild(gatewayTopLeft);
    gatewayTop.appendChild(gatewayTopRight);
    
    return gatewayTop;
}

function createGatewayBottomElement() {

    const gatewayBottom = document.createElement('div');
    gatewayBottom.className = 'gatewaybottom';

    return gatewayBottom;
}

function createGatewayInfoElement(unit) {

    const gatewayInfo = document.createElement('div');
    gatewayInfo.className = 'gatewayinfo';

    gatewayInfo.appendChild(createGatewayInfo(unit.imei));
    gatewayInfo.appendChild(createGatewayInfo(unit.simCardNumber));
    gatewayInfo.appendChild(createGatewaySignalStrengthElement(unit.gatewayPowerSupplyVoltage));
    gatewayInfo.appendChild(createBatteryVoltageElement(unit.gatewayBatteryVoltage));

    return gatewayInfo;
}

function createGatewayInfo(info) {

    const deviceInfo = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];

    deviceInfo.classList.add(...sensorValuesClasses);
    deviceInfo.innerText = info;

    return deviceInfo;
}

function createGatewaySignalStrengthElement(signalStrengthValue) {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];    

    signalStrength.classList.add(...sensorValuesClasses);
    signalStrength.innerText = `${signalStrengthValue} V`;

    return signalStrength;
}

function createBatteryVoltageElement(voltage) {

    const batteryVoltage = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];

    batteryVoltage.classList.add(...sensorValuesClasses);
    batteryVoltage.innerText = `${voltage} V`;

    return batteryVoltage;
}


function createDiagramUnitBox(unit, unitBox) {

    const unitSensors = unit.trailerNumber ? unit.wheelSensors.filter(s => parseInt(s.wheelId.slice(-1)) === unit.trailerNumber) : unit.wheelSensors.filter(s => parseInt(s.wheelId.slice(-1)) === unitBox);
    const unitRepeaters = unit.trailerNumber ? unit.repeaters.filter(r => parseInt(r.location.slice(-1)) === unit.trailerNumber) : unit.repeaters.filter(r => parseInt(r.location.slice(-1)) === unitBox);
    const repeaterDiagramUnitBox = document.createElement('div');
    const linkedtrailer = unit.trailers?.find(trailer => trailer.trailerNumber === unitBox);
    if (unitBox !== 0 && linkedtrailer) {
        repeaterDiagramUnitBox.appendChild(createTrailerHeader('r', linkedtrailer.name));
    }

    let unitBoxAxle = {};
    let axles = [];

    for (let ws = 0; ws < unit.wheelSensors.length; ws++) {
        if(unit.trailerNumber && parseInt(unit.wheelSensors[ws].wheelId.slice(-1)) === unit.trailerNumber){
            axles.push(unit.wheelSensors[ws].wheelId.slice(1, 3));
        } else {
            if (parseInt(unit.wheelSensors[ws].wheelId.slice(-1)) === unitBox) {
                axles.push(unit.wheelSensors[ws].wheelId.slice(1, 3));
            }
        }
    }
    
    axles = [...new Set(axles)].sort();
    for (let a = 0; a < axles.length; a++) {        
        unitBoxAxle = createUnitBoxAxle(unit.trailerNumber ? unit.trailerNumber : unitBox, unitRepeaters, a + +1);
        repeaterDiagramUnitBox.appendChild(unitBoxAxle);
    }

    return repeaterDiagramUnitBox;
}

function createUnitBoxAxle(unitBox, sensors, axle) {

    const unitBoxAxle = document.createElement('div');
    unitBoxAxle.className = 'diagramaxle';
    const sensorSuffix = `${addLeadingZero(axle)}${unitBox}`;
    const repeaterLeft = sensors.filter(s =>
        s.location.endsWith(sensorSuffix) && ["L"].includes(s.location.charAt(0))
    )[0];
    if (repeaterLeft) {
        let sensorLeft = createSensorElement(repeaterLeft);
        unitBoxAxle.appendChild(sensorLeft);
    }

    const repeaterRight = sensors.filter(s =>
        s.location.endsWith(sensorSuffix) && ["R"].includes(s.location.charAt(0))
    )[0]; //** create empty if no repeater*/
    if (repeaterRight) {
        const  sensorRight = createSensorElement(repeaterRight);
        unitBoxAxle.appendChild(sensorRight);
    }

    return unitBoxAxle;
}

const createSensorElement = (repeater) => { 

    const sensor = document.createElement('div');
    sensor.id = `sensor${repeater.Id}`;
    sensor.className = 'repeater';     
    sensor.appendChild(createSensorTopElement());    
    sensor.appendChild(createSensorValuesElement(repeater));
    sensor.appendChild(createSensorBottomElement());
    return sensor;
}

function createSensorTopElement() {

    const sensorTop = document.createElement('svg');
    sensorTop.className = 'repeaterimages repeatertop';
    return sensorTop;
}

function createSensorBottomElement() {

    const sensorBottom = document.createElement('svg');
    sensorBottom.className = 'repeaterimages repeaterbottom';
    return sensorBottom;
}

function createSensorValuesElement(axleSensor) {   

    const sensorValues = document.createElement('div');
    sensorValues.className = 'repeatersensorvalues';

    if (axleSensor) {
        sensorValues.appendChild(createRepeaterIdElement(axleSensor.repeaterId || axleSensor.Id));
        sensorValues.appendChild(createWheelConfigElement(axleSensor.wheelConfiguration || axleSensor.config.wheel));
        sensorValues.appendChild(createSensorVoltageElement(axleSensor.voltage));
    } else {
        sensorValues.appendChild(createNoSignalElement());
    }


    return sensorValues;   
}

function createNoSignalElement() {

    const noSignal = document.createElement('div');
    noSignal.className = 'repeatersensorvaluesdefault wheelnosignal';
    noSignal.innerText = "No Signal";

    return noSignal;
}

function createRepeaterIdElement(repeaterId) {

    const repeaterIdDiv = document.createElement('div');
    repeaterIdDiv.className = 'repeatersensorvaluesdefault sensorid';
    repeaterIdDiv.innerText = repeaterId;

    return repeaterIdDiv;
}

function createWheelConfigElement(wheelConfig) {

    const wheelConfigDiv = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];

    wheelConfigDiv.classList.add(...sensorValuesClasses);
    wheelConfigDiv.innerText = wheelConfig;
    return wheelConfigDiv;
}

function createSignalStrengthElement(axleSensor) {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    if (axleSensor.signalStrengthLabelColour === "blue")
        sensorValuesClasses = ['repeatersensorvaluesdefault', 'blue'];

    signalStrength.classList.add(...sensorValuesClasses);
    signalStrength.innerText = Math.round(axleSensor.signalStrength * 1000) / 1000 + " dBi";

    return signalStrength;
}

function createSensorVoltageElement(voltage) {

    const voltageDiv = document.createElement('div');

    let sensorValuesClasses = ['repeatersensorvaluesdefault'];

    if(voltage){
    voltage = Math.round((voltage / 1000) * 100) / 100;
    if (voltage < 3.2)
        sensorValuesClasses = ['repeatersensorvaluesdefault', 'yellow'];

    voltageDiv.classList.add(...sensorValuesClasses);
    voltageDiv.innerText = voltage + " V";
}

    return voltageDiv;
}

async function getDiagramConfiguration(unit, diagramDiv) {

    for (let un = 0; un < unitNumbers.length; un++) {
        let unitDiv = createUnitElement(unitNumbers[un]);
        diagramDiv.appendChild(unitDiv);

        const unitAxles = [];
        for (let ls = 0; ls < unit.locationSensors.count; ls++) {
            if (unit.locationSensors[ls].wheelId.slice(-1) === unitNumbers[un].toString() && unit.locationSensors[ls].wheelId.slice(1, 3) !== "16")
                unitAxles.push(unit.locationSensors[ls].wheelId.slice(1, 3));
        }

        unitAxles = [...new Set(unitAxles)];
        for (let a = 0; a < unitAxles.length; a++) {

            let axleSensors = unit.locationSensors.filter(s => parseInt(s.wheelId.slice(1, 3)) === (a + +1));
            let axleDiv = createAxleElements(unit, a + +1, axleSensors);

            unitDiv.appendChild(axleDiv);
        }

        let unitAxleSpareSensors = unit.sensors.filter(s => parseInt(s.wheelId.slice(1, 3)) === 16);

    }
}

function createRepeaterDiagramButtons(unit) {

    let schematicDiagramButtonDivClasses = ['row', 'pane2buttons'];
    let schematicDiagramButtonDiv = document.createElement('div');
    schematicDiagramButtonDiv.classList.add(...schematicDiagramButtonDivClasses);

    let schematicDiagramButtonClasses = ['btn', 'btn-primary', 'panebutton', 'flex-child'];

    let notificationsButton = document.createElement('button');
    notificationsButton.classList.add(...schematicDiagramButtonClasses);
    notificationsButton.type = 'button';
    notificationsButton.title = 'SET NOTIFICATIONS';

    const notificationsIcon = document.createElement('svg');
    notificationsIcon.className = 'buttonicon notificationbluesvg';

    const notificationsButtonText = document.createElement('span');
    //wheelsDiagramButtonText.className = 'buttontext'; //class="buttontext buttontext-disabled"
    notificationsButtonText.innerText = 'Notifications'; //'\xa0Unit'
    notificationsButton.appendChild(notificationsIcon);
    notificationsButton.appendChild(notificationsButtonText);

    notificationsButton.onclick = function (e) {
        openNotificationsForm(unit);
        e.stopImmediatePropagation();
    };

    schematicDiagramButtonDiv.appendChild(notificationsButton);

    let repeaterDiagramButton = document.createElement('button');
    repeaterDiagramButton.classList.add(...schematicDiagramButtonClasses);
    repeaterDiagramButton.type = 'button';
    repeaterDiagramButton.title = 'VIEW WHEELS DIAGRAM';

    const tyreIcon = document.createElement('svg');
    tyreIcon.className = 'buttonicon tyrebluesvg';

    const repeaterDiagramButtonText = document.createElement('span');
    repeaterDiagramButtonText.innerText = 'Wheels Diagram';

    repeaterDiagramButton.appendChild(tyreIcon);
    repeaterDiagramButton.appendChild(repeaterDiagramButtonText);

    repeaterDiagramButton.onclick = function (e) {        
        e.stopImmediatePropagation();
        createDiagramView(unit, 'WHEELSDIAGRAM', 'rightpane');
    };

    schematicDiagramButtonDiv.appendChild(repeaterDiagramButton);

    return schematicDiagramButtonDiv;
}

export { createRepeaterDiagram };