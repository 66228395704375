import global, { SPARE_AXLE, UNPAIRED_AXLE } from '../utils/global.js';
import { getLocationCoordinates } from './units.js';
import { formatCoordinate } from '../../common/utils/coordinateformats.js';
import { addLeadingZero } from '../utils/general.js';
import { formatZuluTime } from '../utils/timeformats.js';
import { fetchUnits, fetchYards, fetchTrucks, fetchTrailers } from './units.js';

export const getUnits = async() => {

    if(!global.token){
        const maxRetries = 10;  // Prevent infinite loops
        const delay = 500; // 500ms delay between checks
        let retries = 0;
        while (!localStorage.getItem("userData") && retries < maxRetries) {
            console.log(`Local storage not initialized. Retrying... (${retries + 1}/${maxRetries})`);
            await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
            retries++;
        }

        if (!localStorage.getItem("userData")) {
            console.error("Local storage variable not found after max retries. Handling failure...");
            return null; // Handle failure case properly
        }
    }

    let [apiUnits, yards, trucks, trailers] = await Promise.all([
        fetchUnits(),
        fetchYards(),
        fetchTrucks(),
        fetchTrailers()        
    ]);

    const apiUnitsMap = new Map(apiUnits.map(unit => [unit.imeiNumber, unit]));
    const yardIMEIs = new Set(yards.map(yard => yard.IMEI));
    const truckIMEIs = new Set(trucks.map(truck => truck.IMEI));    
    const trailersByTruckIMEI = new Map();
    trailers.forEach(trailer => {
        trailer.type = 'trailer';
        trailer.unitId = trailer.Id;
        trailer.axleRanges = trailer?.axleRanges || [];
        if (trailer.sensors && Array.isArray(trailer.sensors)) {
            trailer.sensors = processAxleRanges(trailer);
            trailer.sensors.forEach(sensor => {
                sensor.sensorId = sensor.Id;
            });
            trailer.wheelSensors = trailer.sensors.filter(item => parseInt(item.wheelId?.slice(1, 3)) < SPARE_AXLE);
            trailer.spareSensors = trailer.sensors.filter(item => parseInt(item.wheelId?.slice(1, 3)) === SPARE_AXLE);
            trailer.unitConfig = getUnitConfig(trailer);
            calculateUnitFlags(trailer);             
        } 
        
        if ((!trailer.sensors || trailer.sensors.length === 0) && trailer.repeaters) {
            trailer.wheelSensors = [];
    
            trailer.repeaters.forEach(repeater => {
                let { side, axle, wheel } = repeater.config;
    
                let wheelPositions = (side === 'Left') ? [1, 2] : [3, 4];
                if (wheel === 'Single') {
                    wheelPositions = (side === 'Left') ? [1] : [4];
                }
    
                wheelPositions.forEach(pos => {
                    trailer.wheelSensors.push({
                        sensorId: `S_${trailer.unitId.slice(-3)}_${pos}${String(axle).padStart(2, '0')}${trailer.trailerNumber}`,
                        wheelId: `${pos}${String(axle).padStart(2, '0')}${trailer.trailerNumber}`, 
                        signal: false, // Indicating no signal
                        source: 'Repeater'
                    });
                });
            });

            trailer.spareSensors = [];
            if (trailer.spareWheelSlots && trailer.spareWheelSlots > 0) {
                for (let i = 1; i <= trailer.spareWheelSlots; i++) {
                    let wheelId = `${i}17${trailer.trailerNumber}`; // Follows the pattern 1170, 2170, etc.
        
                    trailer.spareSensors.push({
                        sensorId: `S_${trailer.unitId.slice(-3)}_SPARE_${i}`,
                        wheelId: wheelId,
                        signal: false, // No signal initially
                        source: 'SpareWheelSlot'
                    });
                }
            }
        }
        
        if (trailer?.truckIMEI) {
            if (!trailersByTruckIMEI.has(trailer.truckIMEI)) {
                trailersByTruckIMEI.set(trailer.truckIMEI, []);
            }
            const apiUnitData = apiUnitsMap.get(trailer.truckIMEI);
            trailer.imei = trailer.truckIMEI;            
            trailer.timeUpdated = formatZuluTime(apiUnitData.locationData?.recordTimestamp) || "Not available";
            trailer.speed = apiUnitData.locationData?.speed;
            trailer.location = apiUnitData.locationData;  
            trailersByTruckIMEI.get(trailer.truckIMEI).push(trailer);
        }
    });
    
    let units = [];

    // Process trucks (with their linked trailers)
    trucks.forEach(truck => {
        const truckIMEI = truck.IMEI;   
        const apiUnitData = apiUnitsMap.get(truckIMEI);   
        if (!apiUnitData) return; // Ensure the API unit exists

        if (truck.sensors && Array.isArray(truck.sensors)) {
            truck.sensors = processAxleRanges(truck);
            truck.sensors.forEach(sensor => {
                sensor.sensorId = sensor.Id;
            });
        }
        let unitSensors = [...(truck.sensors || [])];
        let unitRepeaters = [...(truck.repeaters || [])];

        if ((!truck.sensors || truck.sensors.length === 0) && truck.repeaters) {
            truck.repeaters.forEach(repeater => {
                let { side, axle, wheel } = repeater.config;
    
                let wheelPositions = (side === 'Left') ? [1, 2] : [3, 4];
                if (wheel === 'Single') {
                    wheelPositions = (side === 'Left') ? [1] : [4];
                }
    
                wheelPositions.forEach(pos => {
                    unitSensors.push({
                        sensorId: `S_${truck.Id.slice(-3)}_${pos}${String(axle).padStart(2, '0')}0`,
                        wheelId: `${pos}${String(axle).padStart(2, '0')}0`,
                        signal: false, // Indicating no signal
                        source: 'Repeater'
                    });
                });
            });

            
            if (truck.spareWheelSlots && truck.spareWheelSlots > 0) {
                for (let i = 1; i <= truck.spareWheelSlots; i++) {
                    let wheelId = `${i}170`; // Follows the pattern 1170, 2170, etc.
        
                    unitSensors.push({
                        sensorId: `S_${truck.Id.slice(-3)}_SPARE_${i}`,
                        wheelId: wheelId,
                        signal: false, // No signal initially
                        source: 'SpareWheelSlot'
                    });
                }
            }
        }

        const linkedTrailers = trailersByTruckIMEI.get(truckIMEI) || [];
        linkedTrailers.forEach(trailer => {
            if (trailer.wheelSensors) {
                unitSensors = unitSensors.concat(trailer.wheelSensors);
            }
            if (trailer.spareSensors) {
                unitSensors = unitSensors.concat(trailer.spareSensors);
            }
            if (trailer.repeaters) {
                unitRepeaters = unitRepeaters.concat(trailer.repeaters);
            }
        });
        units.push({

            customerId: truck.customerId,  

            imei: apiUnitData.imeiNumber,
            deviceName: apiUnitData.deviceName,
            deviceType: apiUnitData.unitType,

            simCardNumber: apiUnitData.simCardNumber,
            gatewayBatteryVoltage: apiUnitData.gateway?.batteryVoltage,
            gatewayPowerSupplyVoltage: apiUnitData.gateway?.externalVoltage,
            sinkId: apiUnitData.sinkId,
            sinkVoltage: apiUnitData.sinkVoltage,
            sinkPowerSupplyVoltage: apiUnitData.sinkPowerSupplyVoltage,
            
            timeUpdated: formatZuluTime(apiUnitData.locationData?.recordTimestamp) || "Not available",
            speed: apiUnitData.locationData?.speed,
            location: apiUnitData.locationData, 

            notificationSettings: apiUnitData.notificationSettings,

            type: 'truck',
            unitType: 'TRUCK',
            unitId: truck.Id,
            name: truck.name || apiUnitData.imeiNumber,
            unitNumber: 0,
            driverName: truck.driverName,
            driverContactNumber: truck.driverContactNumber,
            
            axleRanges: truck.axleRanges || [],
            axles: truck.axles,
            repeaters: unitRepeaters,
            trailers: linkedTrailers,

            wheelSensors: unitSensors.filter(item => parseInt(item.wheelId?.slice(1, 3)) < SPARE_AXLE),
            spareSensors: unitSensors.filter(item => parseInt(item.wheelId?.slice(1, 3)) === SPARE_AXLE),
            unpairedSensors: unitSensors.filter(item => parseInt(item.wheelId?.slice(1, 3)) === UNPAIRED_AXLE),                   

        });
    });
    
    // Process standalone trailers (trailers 'linked' to yard IMEIs)
    trailers.forEach(trailer => {
        if (!trailer?.truckIMEI) { // If it has no truck, it's standalone
            const apiUnitData = trailer?.lastReceivedIMEI 
            ? apiUnitsMap.get(trailer.lastReceivedIMEI) 
            : {}; // Default to an empty object to avoid errors
            
            const unitSensors = [...(trailer.sensors || [])];

            if ((!trailer.sensors || trailer.sensors.length === 0) && trailer.repeaters) {
                trailer.repeaters.forEach(repeater => {
                    let { side, axle, wheel } = repeater.config;
    
                    let wheelPositions = (side === 'Left') ? [1, 2] : [3, 4];
                    if (wheel === 'Single') {
                        wheelPositions = (side === 'Left') ? [1] : [4];
                    }
    
                    wheelPositions.forEach(pos => {
                        unitSensors.push({
                            sensorId: `S_${trailer.unitId.slice(-3)}_${pos}${String(axle).padStart(2, '0')}${trailer.trailerNumber}`,
                            wheelId: `${pos}${String(axle).padStart(2, '0')}${trailer.trailerNumber}`,
                            signal: false, // Indicating no signal
                            source: 'Repeater'
                        });
                    });
                });

                if (trailer.spareWheelSlots && trailer.spareWheelSlots > 0) {
                    for (let i = 1; i <= trailer.spareWheelSlots; i++) {
                        let wheelId = `${i}17${trailer.trailerNumber}`; // Follows the pattern 1170, 2170, etc.
            
                        unitSensors.push({
                            sensorId: `S_${trailer.unitId.slice(-3)}_SPARE_${i}`,
                            wheelId: wheelId,
                            signal: false, // No signal initially
                            source: 'SpareWheelSlot'
                        });
                    }
                }
            }

            units.push({
                customerId: trailer.customerId,  

                imei: apiUnitData.imeiNumber || '',
                simCardNumber: apiUnitData.simCardNumber || '',
                deviceName: apiUnitData.deviceName || '',
                deviceType: apiUnitData.unitType || '',
    
                gatewayBatteryVoltage: apiUnitData.gateway?.batteryVoltage || '',
                gatewayPowerSupplyVoltage: apiUnitData.gateway?.externalVoltage || '',
                sinkId: apiUnitData.sinkId || '',
                sinkVoltage: apiUnitData.sinkVoltage || '',
                sinkPowerSupplyVoltage: apiUnitData.sinkPowerSupplyVoltage || '',
                
                timeUpdated: formatZuluTime(apiUnitData.locationData?.recordTimestamp) || "Not available",
                speed: apiUnitData.locationData?.speed || '',
                location: apiUnitData.locationData || '', 
    
                notificationSettings: apiUnitData.notificationSettings || '',

                type: 'trailer',
                unitType: 'TRAILER',
                unitId: trailer.Id,
                name: trailer.name,
                
                unitNumber: trailer.trailerNumber,
                trailerNumber: trailer.trailerNumber,
                trailers: [],

                axleRanges: trailer?.axleRanges || [],
                axles: trailer.axles,
                repeaters: trailer.repeaters,
                trailerSensors: trailer.sensors,
                wheelSensors: unitSensors.filter(item => parseInt(item.wheelId?.slice(1, 3)) < SPARE_AXLE),
                spareSensors: unitSensors.filter(item => parseInt(item.wheelId?.slice(1, 3)) === SPARE_AXLE),
                unpairedSensors: unitSensors.filter(item => parseInt(item.wheelId?.slice(1, 3)) === UNPAIRED_AXLE),       
            });
        }
    });    

    // Add apiUnits that are NOT linked to a truck and are NOT a yard --- revisit???
    apiUnits.forEach(apiUnit => {
        if (!truckIMEIs.has(apiUnit.imeiNumber) && !yardIMEIs.has(apiUnit.imeiNumber)) {
            units.push({
                customerId: apiUnit.customerId,
                imei: apiUnit.imeiNumber,
                deviceName: apiUnit.deviceName,
                deviceType: apiUnit.unitType,

                gatewayBatteryVoltage: apiUnit.gateway?.batteryVoltage || '',
                gatewayPowerSupplyVoltage: apiUnit.gateway?.externalVoltage || '',
                sinkId: apiUnit.sinkId || '',
                sinkVoltage: apiUnit.sinkVoltage || '',
                sinkPowerSupplyVoltage: apiUnit.sinkPowerSupplyVoltage || '',
                
                timeUpdated: formatZuluTime(apiUnit.locationData?.recordTimestamp) || "Not available",
                speed: apiUnit.locationData?.speed || '',
                location: apiUnit.locationData || '', 
                
                type: 'unassigned',
                unitType: 'UNASSIGNED',
                unitId: apiUnit.Id,
                name: apiUnit.imeiNumber,
                unitNumber: 0,
                axles: [],
                repeaters: [],
                wheelSensors: [],
                spareSensors: [],
                unpairedSensors: []
            });
        }
    });

    units.forEach(unit => {
        unit.unitConfig = getUnitConfig(unit);
        calculateUnitFlags(unit); 
        }
    )

    units.sort((a, b) => {
        if (b.blinkFlags !== a.blinkFlags) {
            return b.blinkFlags - a.blinkFlags;
        }
        if (b.redFlags !== a.redFlags) {
            return b.redFlags - a.redFlags;
        }
        if (b.orangeFlags !== a.orangeFlags) {
            return b.orangeFlags - a.orangeFlags;
        }
        if (b.purpleFlags !== a.purpleFlags) {
            return b.purpleFlags - a.purpleFlags;
        }
        if (b.yellowFlags !== a.yellowFlags) {
            return b.yellowFlags - a.yellowFlags;
        }
        return b.grayFlags - a.grayFlags; // Fallback to sorting by gray flags last
    });

    //const unitData = await processUnitData(units);  
    global.units = units;
    return units;
}

const processAxleRanges = (unit) => {
    let sensors = [];
    if (unit.sensors && unit.sensors.length > 0) {        
    
        unit.sensors.forEach(sensor => {
            sensor.wheelId = sensor.tyreId || "";
            const axleNumber = sensor.wheelId?.slice(1, 3);
    
            // Find the corresponding axleRange by axleNumber
            const axleRange = unit.axleRanges?.find(range => range.axleNumber === axleNumber);
    
            // Create sensor object
            let sensorData = {
                ...sensor,
                recommendedPressure: axleRange?.recommendedPressure || 0,
                pressureDeviationAllowedLow: axleRange?.pressureDeviationAllowedLow || 0,
                pressureDeviationAllowedHigh: axleRange?.pressureDeviationAllowedHigh || 0,
                minPressure: axleRange?.minPressure || 0,
                maxPressure: axleRange?.maxPressure || 0,
                maxTemperature: axleRange?.maxTemperature || 0,
                minVoltage: 2.5, // Or axleRange?.minVoltage
                signal: getSensorSignalStatus(sensor)
            };
    
            sensors.push(sensorData);
        });
    }
    return sensors;
}

export async function getImeiTruck(unit) {
    let trucks = await fetchTrucks(unit.customerId);
    const truck =  trucks.find(
        t => t.IMEI === unit.imei
    );
    return truck;
}

// export async function getUnits() {
//     const repeaters = await fetchRepeaters();
//     const repeaterMap = new Map();
//     repeaters.forEach(repeater => {
//         if (!repeaterMap.has(repeater.unitId)) {
//             repeaterMap.set(repeater.unitId, []);
//         }
//         repeaterMap.get(repeater.unitId).push(repeater);
//     });
//     let apiUnits = await fetchUnits();

//     let trucks = await fetchTrucks();
//     let trailers = await fetchTrailers();

//     trailers = trailers.map(trailer => {
//         const trailerRepeaters = repeaterMap.get(trailer.Id) || [];
//         const imeiNumber = trailerRepeaters[0]?.imeiNumber || null;
//         const yardUnit = units.find(unit => unit.imeiNumber === imeiNumber);
//         return {
//             ...trailer,
//             imeiNumber,
//             repeaters: trailerRepeaters,
//             locationData: yardUnit?.locationData || null
//         };
//     });
//     const trailerMap = new Map();
//     trailers.forEach(trailer => {
//     if (!trailerMap.has(trailer.truckId)) {
//         trailerMap.set(trailer.truckId, []);
//     }
//     trailerMap.get(trailer.truckId).push(trailer);
// });
//     const yards = await fetchYards();
//     units = units.filter(unit => 
//         !yards.some(yard => yard.IMEI === unit.imeiNumber)
//     );
//     trucks = trucks.map(truck => {
//         const truckRepeaters = repeaterMap.get(truck.Id) || [];
//         const truckTrailers = trailerMap.get(truck.Id) || [];
//             // Collect all repeaters from linked trailers
//     const trailerRepeaters = truckTrailers.flatMap(trailer => trailer.repeaters || []);
    
//     // Combine truck repeaters and trailer repeaters
//     const repeaters = [...truckRepeaters, ...trailerRepeaters];
//         return {
//             ...truck,
//             repeaters: repeaters,
//             trailers: truckTrailers
//         };
//     });
//     trailers = trailers.filter(trailer => trailer.truckId == null);

//     let unitData = mergeUnits(units, trucks);
//     unitData = [...unitData, ...trailers]
//     units = await processUnitData(unitData);  
//     global.units = units;
//     return units;
// }

const mergeUnits = (units, trucks) => {
    // Merge linkedTrailers with trailers where name and customerId match
    const combinedUnits = units.map(unit => {
        // Find a matching trailer in trailers
        const overridingUnit = trucks.find(
            truck => truck.IMEI === unit.imeiNumber && truck.customerId === unit.customerId
        );
        
        return overridingUnit
            ? { ...unit, ...overridingUnit } // Merge objects
            : unit; // Keep original if no match found
    });

    // Add trailers that don't exist in linkedTrailers
    const additionalUnits = trucks.filter(truck =>
        !units.some(
            unit => unit.imeiNumber === truck.IMEI && unit.customerId === truck.customerId
        )
    );

    return [...combinedUnits, ...additionalUnits];
};


async function processUnitData(data) {    

    let units = [];
    for (let i = 0; i < data.length; i++) {
        const unit = data[i];
        unit.repeaters = data[i].repeaters || []; 
        const repeaterIds = unit.repeaters.map(repeater => repeater.Id);
        // const sensors = data[i].imeiNumber
        // ? unit.trailerNumber
        //     ? await fetchWheelSensorData(data[i].imeiNumber) // If imeiNumber exists and unit has a trailerNumber
        //     : (await fetchWheelSensorData(unit.imei) || []) // If imeiNumber exists but no trailerNumber
        //           .filter(sensor => 
        //               sensor?.repeater?.repeaterId && repeaterIds.includes(sensor.repeater.repeaterId)
        //           )
        // : []; // If imeiNumber does not exist
        
        // Add properties from axleRanges to wheelSensors
        sensors.forEach(sensor => {
            // Extract the axle number from the wheelId
            const axleNumber = sensor.wheelId.slice(1);

            // Find the corresponding axleRange by axleNumber
            const axleRange = unit.axleRanges.find(range => range.axleNumber === axleNumber);

            // If axleRange is found, add the properties to the wheelSensor
            if (axleRange) {
                sensor.recommendedPressure = axleRange.recommendedPressure;
                sensor.pressureDeviationAllowedLow = axleRange.pressureDeviationAllowedLow;
                sensor.pressureDeviationAllowedHigh = axleRange.pressureDeviationAllowedHigh;
                sensor.minPressure = axleRange.minPressure;
                sensor.maxPressure = axleRange.maxPressure;
                sensor.maxTemperature = axleRange.maxTemperature;
                sensor.minVoltage = 2.5; //axleRange.minVoltage;
            }
            sensor.signal = getSensorSignalStatus(sensor);
        });
        
        unit.wheelSensors = sensors.filter(item => parseInt(item.tyreId.slice(1, 3)) < SPARE_AXLE);
        unit.spareSensors = sensors.filter(item => parseInt(item.tyreId.slice(1, 3)) === SPARE_AXLE);
        unit.unpairedSensors = sensors.filter(item => parseInt(item.tyreId.slice(1, 3)) === UNPAIRED_AXLE);

        unit.unitConfig = getUnitConfig(unit);
        calculateUnitFlags(unit);        

        units.push(unit);
    }

    units.sort((a, b) => {
        if (b.blinkFlags !== a.blinkFlags) {
            return b.blinkFlags - a.blinkFlags;
        }
        if (b.redFlags !== a.redFlags) {
            return b.redFlags - a.redFlags;
        }
        if (b.orangeFlags !== a.orangeFlags) {
            return b.orangeFlags - a.orangeFlags;
        }
        if (b.purpleFlags !== a.purpleFlags) {
            return b.purpleFlags - a.purpleFlags;
        }
        if (b.yellowFlags !== a.yellowFlags) {
            return b.yellowFlags - a.yellowFlags;
        }
        return b.grayFlags - a.grayFlags; // Fallback to sorting by gray flags last
    });
    return units;
}

const mergeTrailers = (linkedTrailers, trailers) => {
    // Merge linkedTrailers with trailers where name, customerId, or Id match
    const combinedTrailers = linkedTrailers.map(linkedTrailer => {
        // Find a matching trailer in trailers by Id
        const overridingTrailer = trailers.find(
            trailer => 
                trailer.Id === linkedTrailer.unitId || 
                trailer.Id === linkedTrailer.Id 
        );

        return overridingTrailer
            ? { ...overridingTrailer, ...linkedTrailer } // Keep linkedTrailer data when IDs match
            : linkedTrailer; // Keep original if no match found
    });

    // Add trailers that don't exist in linkedTrailers
    const additionalTrailers = trailers.filter(trailer =>
        !linkedTrailers.some(
            linkedTrailer => 
                (linkedTrailer.Id && linkedTrailer.Id === trailer.Id) || 
                (linkedTrailer.unitId && linkedTrailer.unitId === trailer.unitId) || 
                (linkedTrailer.Id && linkedTrailer.Id === trailer.unitId) || 
                (linkedTrailer.unitId && linkedTrailer.unitId === trailer.Id)
        )
    );

    return [...combinedTrailers, ...additionalTrailers];
};

export const 
getTrailers = async (units) => {

    let linkedTrailers = getLinkedTrailers(units);
    linkedTrailers = mapTrailerNames(linkedTrailers);

    let trailers = await fetchTrailers();

    // Combine arrays
    const result = mergeTrailers(linkedTrailers, trailers);

    return result;
}

const mapTrailerNames = (linkedTrailers) => {
    linkedTrailers.forEach((linkedTrailer) => {
        const { trailers, wheelSensors } = linkedTrailer;

        // Ensure trailers is defined and is an array
        if (Array.isArray(trailers)) {
            // Iterate over wheel sensors
            wheelSensors.forEach((sensor) => {
                // Extract the last digit of the wheelId
                const lastDigit = parseInt(sensor.wheelId.slice(-1), 10);

                // Find the matching trailer based on trailerNumber
                const matchingTrailer = trailers.find(
                    (trailer) => trailer.trailerNumber === lastDigit
                );

                // If a matching trailer is found, copy its properties to the linked trailer
                if (matchingTrailer) {
                    linkedTrailer.Id = matchingTrailer.Id;
                    linkedTrailer.trailerNumber = matchingTrailer.trailerNumber;
                    linkedTrailer.name = matchingTrailer.name;
                }
            });
        }
    });
    return linkedTrailers;
};

function getLinkedTrailers(units) {
    const newArray = [];

    units.forEach(obj => {
        if (obj.wheelSensors && Array.isArray(obj.wheelSensors)) {
            // Find the unique last digits of all wheelIds
            const uniqueLastDigits = [...new Set(obj.wheelSensors
                .map(sensor => sensor.wheelId.slice(-1))
                .filter(digit => digit !== '0'))];

            // For each unique last digit, create a new object
            uniqueLastDigits.forEach(lastDigit => {
                // Create a copy of the object
                const newObject = { ...obj };

                // Filter the wheelSensors based on the current last digit
                newObject.wheelSensors = obj.wheelSensors.filter(sensor => sensor.wheelId.endsWith(lastDigit));
                newObject.spareSensors = obj.spareSensors.filter(sensor => sensor.wheelId.endsWith(lastDigit));

                // Remove the imei property
                //delete newObject.imei;

                // Only add the new object if there are wheelSensors that match
                if (newObject.wheelSensors.length > 0) {
                    newArray.push(newObject);
                }
            });
        } else {
            // If there are no wheelSensors, simply add the original object
            const newObject = { ...obj };
            //delete newObject.imei;
            newArray.push(newObject);
        }
    });

    return newArray;
}

function getSensorSignalStatus(sensor) {
    // Convert the payloadTS to a Date object
    let payloadTS = new Date(sensor.payloadTS);

    // Get current time in South African Standard Time (SAST), which is UTC+2
    let now = new Date();
    //let nowSAST = new Date(now.getTime() + (2 * 60 * 60 * 1000)); // Adjust to SAST

    // Subtract 40 minutes (40 * 60 * 1000 milliseconds) from the current time
    let thresholdTime = new Date(now.getTime() - (40 * 60 * 1000));

    // Compare the payloadTS with the thresholdTime
    if (payloadTS < thresholdTime) {
        return false;
    } else {
        return true;
    }
}

const calculateUnitFlags = (unit) =>{

    unit.grayFlags = 0;
    unit.blinkFlags = 0;    
    unit.redFlags = 0;
    unit.purpleFlags = 0;
    unit.orangeFlags = 0;
    unit.yellowFlags = 0;

    const sensors = [...unit.wheelSensors, ...unit.spareSensors];
    for (let s = 0; s < sensors.length; s++) {
        if (sensors[s].signal) {
            if (parseFloat(sensors[s].currentPressure) > parseFloat(sensors[s].maxPressure))
                unit.purpleFlags++;
            if (sensors[s].wheelId.slice(1, 3) !== SPARE_AXLE.toString() && parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].recommendedPressure) * 20 / 100)
                unit.blinkFlags++;
            if (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].minPressure))
                unit.redFlags++;
            if (parseFloat(sensors[s].currentTemperature) > parseFloat(sensors[s].maxTemperature))
                unit.orangeFlags++;
            if (parseFloat(sensors[s].currentVoltage) < parseFloat(sensors[s].minVoltageValue))
                unit.yellowFlags++;
        } else {
            unit.grayFlags++;
        }
    }
    return unit;
}

function getUnitConfig(unit) {

    let unitConfig = [];

    // Check if wheelSensors is not empty before proceeding
    const wheelSensorUnitNumbers = unit.wheelSensors.length === 0
        ? []
        : [...new Set(unit.wheelSensors.map(item => parseInt(item.wheelId.slice(-1))))];

    let maxUnitNumber = wheelSensorUnitNumbers.length === 0
        ? 0
        : Math.max(...wheelSensorUnitNumbers);

    // Generate unit numbers based on maxUnitNumber
    const unitNumbers = unit.trailerNumber !== undefined
    ? [unit.trailerNumber.toString()]
    : Array.from({ length: maxUnitNumber + 1 }, (_, index) => index.toString()).sort();

    // Populate unitConfig array
    for (let un = 0; un < unitNumbers.length; un++) {
        unitConfig.push(getUnitNumberConfig(unit, parseInt(unitNumbers[un])));
    }

    return unitConfig;
}

function getUnitNumberConfig(unit, unitNumber) {
    const unitNumberConfig = {};
    if(unit.trailers && unit.trailers.length > 0 ){
        unitNumberConfig.unitNumber = unitNumber
        unitNumberConfig.name = unitNumber === 0 ? unit?.name || `unit ${unitNumber}` : (unit.trailers.find(trailer => trailer.trailerNumber === unitNumber)?.name || `trailer ${unitNumber}`);
    } else { //dealing with a trailer        
        unitNumberConfig.unitNumber = 0;
        unitNumberConfig.name = unit?.name || `trailaer ${unit.trailerNumber}`
     }    
    const axles = getAxles(unit, unitNumber);
    unitNumberConfig.axles = axles;    
    unitNumberConfig.axleCount = axles.length;
    unitNumberConfig.sensorCount = countAxleSensors(axles);
    return unitNumberConfig;
}

function countAxleSensors(axles) {
    let sensorCount = 0;
    for (let a = 0; a < axles.length; a++) {
        if (axles[a].wheelConfig === 0) sensorCount = sensorCount + 2;
        else sensorCount = sensorCount + 4;
    }
    return sensorCount;
}

function getAxles(unit, unitNumber) {

    // Check if wheelSensors is not empty before proceeding
    const wheelSensorAxles = unit.wheelSensors.length === 0
        ? []
        : [...new Set(unit.wheelSensors
            .filter(item => item.wheelId.slice(-1) === unitNumber.toString())
            .map(item => item.wheelId.slice(1, 3)))];

    let maxAxles = wheelSensorAxles.length === 0
        ? 0
        : Math.max(...wheelSensorAxles.map(Number));

    // Generate axle numbers based on maxAxles
    const axleNumbers = Array.from({ length: maxAxles }, (_, index) => addLeadingZero(index + 1)).sort();

    // Populate axles array
    const axles = [];
    for (let a = 0; a < axleNumbers.length; a++) {
        axles.push(getAxleWheelConfig(unit, unitNumber, axleNumbers[a]));
    }

    return axles;
}

function getAxleWheelConfig(unit, unitNumber, axle) {
    const axleWheelConfig = {};
    axleWheelConfig.axle = axle;
    axleWheelConfig.wheelConfig = getWheelConfig(unit, unitNumber, axle);
    return axleWheelConfig;
}

function getWheelConfig(unit, unitNumber, axle) {

        let wheelConfig = 0;

        // Check if wheelSensors is not empty before proceeding
        const wheels = unit.wheelSensors.length === 0
            ? []
            : [...new Set(unit.wheelSensors
                .filter(item => parseInt(item.wheelId.slice(-1)) === unitNumber && item.wheelId.slice(1, 3) === axle)
                .map(item => item.wheelId.slice(0, 1)))];

        if (wheels.length > 2) {
            wheelConfig = 1;
        } else {
            const wheelsToCheck = ["2", "3"];
            if (wheelsToCheck.some(wheel => wheels.includes(wheel))) {
                wheelConfig = 1;
            }
        }

    return wheelConfig;
}

export function updateUnitMetrics(unit, data) {

    const sensor = unit.wheelSensors.find(s => s.Id === data.wheelSensorUID) ||
        unit.spareSensors.find(s => s.id === data.wheelSensorUID);

    if (!sensor) {
        console.warn(`Sensor with ID ${data.wheelSensorUID} not found.`);
        return;
    }

    // Update sensor values from WebSocket payload
    sensor.signal = true;
    sensor.currentPressure = parseFloat(data.currentPressure).toFixed(2);
    sensor.currentTemperature = data.currentTemperature;
    sensor.currentVoltage = parseFloat(data.currentVoltage).toFixed(2);
    sensor.currentRotation = data.currentRotation;  // >1000 groen   

    sensor.sensorType = 'tyre-correct';
    if (Math.abs(parseInt(sensor.currentRotation)) < 1000) {
        sensor.rotationLabelColour = 'green';
        sensor.rotationText = 'Stopped';
    } else {
        sensor.rotationLabelColour = 'signalgreen';
        sensor.rotationText = 'Rolling';
    }

    if (sensor.currentPressure < sensor.minPressure) {
        sensor.pressureLabelColour = 'red';
        sensor.sensorType = 'tyre-red';
        if (sensor.wheelId.slice(1, 3) !== SPARE_AXLE && sensor.currentPressure < parseFloat(sensor.recommendedPressure) * 20 / 100) {
            sensor.sensorType = 'wheelblink';
        }
    } else if (sensor.currentPressure > sensor.maxPressure) {
        sensor.pressureLabelColour = 'purple';
        sensor.sensorType = 'tyre-purple';
    } else {
        sensor.pressureLabelColour = 'green';
    }

    if (sensor.currentTemperature > sensor.maxTemperature) {
        sensor.temperatureLabelColour = "orange";
        if (sensor.sensorType !== "tyre-red") {
            sensor.sensorType = "tyre-orange";
        }
    } else {
        sensor.temperatureLabelColour = "green";
    }

    if (sensor.currentVoltage < sensor.minVoltage) {
        sensor.voltageLabelColour = "yellow";
        if (sensor.sensorType !== "tyre-purple" && sensor.sensorType !== "tyre-red" && sensor.sensorType !== "tyre-orange") {
            sensor.sensorType = "tyre-yellow";
        }
        sensors[s].voltageLabelColour = "yellow";
    } else {
        sensor.voltageLabelColour = "green";
    }

    return unit;
}

export async function getCurrentLocation(imei, timestamp) {
    try {
        const results = await getLocationCoordinates(imei, timestamp);

        if (results.data) {
            const data = results.data;
            const currentLocation = {
                speed: data.speed,
                longitude: formatCoordinate(data.longitude),
                latitude: formatCoordinate(data.latitude)
            };
            return currentLocation;
        } else {
            throw new Error('No data available');
        }
    } catch (error) {
        console.log('No location data is available!');
        throw error;
    }
}

function getAllUnitsSensorValueFlags(units) {    

    for (let u = 0; u < units.length; u++) {
        let purpleflags = 0, blinkflags = 0, redflags = 0, orangeflags = 0, yellowflags = 0, blueflags = 0;
        for (let s = 0; s < units[u].sensors.length; s++) {
            if (parseFloat(units[u].sensors[s].currentPressure) > parseFloat(units[u].sensors[s].maxPressureValue))
                purpleflags++;
            if (units[u].sensors[s].sensorName.slice(0, 1) !== "9" && parseFloat(units[u].sensors[s].currentPressure) < parseFloat(units[u].sensors[s].manufacturersRecommendedPressure) * 20 / 100)
                blinkflags++;
            if (parseFloat(units[u].sensors[s].currentPressure) < parseFloat(units[u].sensors[s].minPressureValue))
                redflags++;
            if (parseFloat(units[u].sensors[s].temperatureValue) > parseFloat(units[u].sensors[s].maxTemperatureValue))
                orangeflags++;
            if (parseFloat(units[u].sensors[s].voltageValue) < parseFloat(units[u].sensors[s].minVoltageValue))
                yellowflags++;
            if (units[u].sensors[s].currentPressure == null && units[u].sensors[s].temperatureValue == null && units[u].sensors[s].voltageValue == null)
                blueflags++;
        }
        units[u].blueFlags = blueflags;
        units[u].purpleFlags = purpleflags;
        units[u].blinkFlags = blinkflags;
        units[u].redFlags = redflags;
        units[u].orangeFlags = orangeflags;
        units[u].yellowFlags = yellowflags;
    }

    return units;
}

function joinTruckAndTrailers(units) {

    let unitWithTrailers = [...units];
    let unitsToRemove = [];

    for (let i = 0; i < unitWithTrailers.length; i++) {

        unitWithTrailers[i].linkedblueFlags = unitWithTrailers[i].blueFlags;
        unitWithTrailers[i].linkedpurpleFlags = unitWithTrailers[i].purpleFlags;
        unitWithTrailers[i].linkedblinkFlags = unitWithTrailers[i].blinkFlags;
        unitWithTrailers[i].linkedredFlags = unitWithTrailers[i].redFlags;
        unitWithTrailers[i].linkedorangeFlags = unitWithTrailers[i].orangeFlags;
        unitWithTrailers[i].linkedyellowFlags = unitWithTrailers[i].yellowFlags;

        //currently only ONE trailer per unit
        if (unitWithTrailers[i].trailers.length > 0) {
            for (let j = 0; j < units.length; j++) {
                for (let t = 0; t < unitWithTrailers[i].trailers.length; t++) {
                    if (unitWithTrailers[i].trailers[t].imei === units[j].imei) {

                        unitWithTrailers[i].linkedblueFlags = unitWithTrailers[i].linkedblueFlags + units[j].blueFlags;
                        unitWithTrailers[i].linkedpurpleFlags = unitWithTrailers[i].linkedpurpleFlags + units[j].purpleFlags;
                        unitWithTrailers[i].linkedblinkFlags = unitWithTrailers[i].linkedblinkFlags + units[j].blinkFlags;
                        unitWithTrailers[i].linkedredFlags = unitWithTrailers[i].linkedredFlags + units[j].redFlags;
                        unitWithTrailers[i].linkedorangeFlags = unitWithTrailers[i].linkedorangeFlags + units[j].orangeFlags;
                        unitWithTrailers[i].linkedyellowFlags = unitWithTrailers[i].linkedyellowFlags + units[j].yellowFlags;
                        unitsToRemove.push(units[j].imei);
                    }
                }
            }
        }
    }

    for (let i = 0; i < unitWithTrailers.length; i++) {
        for (let j = 0; j < unitsToRemove.length; j++) {
            if (unitWithTrailers[i] && (unitWithTrailers[i].imei === unitsToRemove[j])) {
                unitWithTrailers.splice(i, 1); i--;
            }
        }
    }

    unitWithTrailers.sort(function (a, b) {
        if (a.unitName > b.unitName) return 1;
        if (a.unitName < b.unitName) return -1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedblueFlags > b.linkedblueFlags) return -1;
        if (a.linkedblueFlags < b.linkedblueFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedyellowFlags > b.linkedyellowFlags) return -1;
        if (a.linkedyellowFlags < b.linkedyellowFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedorangeFlags > b.linkedorangeFlags) return -1;
        if (a.linkedorangeFlags < b.linkedorangeFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedpurpleFlags > b.linkedpurpleFlags) return -1;
        if (a.linkedpurpleFlags < b.linkedpurpleFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedredFlags > b.linkedredFlags) return -1;
        if (a.linkedredFlags < b.linkedredFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedblinkFlags > b.linkedblinkFlags) return -1;
        if (a.linkedblinkFlags < b.linkedblinkFlags) return 1;
        return 0;
    });

    return unitWithTrailers;
}
