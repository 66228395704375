import { getUserData } from './auth.js';

const baseUrl = 'https://cloud-connector-dashboard.restiolabs.com/api';

const { token, parentCustomerId } = getUserData() || {};

export const getUsers = async() => {
    try {
        const apiUrl = `${baseUrl}/customer/${parentCustomerId}/user`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        console.error('getunitdataJS: fetchUnits - ERROR ', e);
    }
}

export async function addUser(user) {
    try {       
        const apiUrl = `${baseUrl}/customer/${parentCustomerId}/user`
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating action log:", e);
    }
}

export async function editUser(userId, updatedUser) {
    try {
    const apiUrl = `${baseUrl}/customer/${parentCustomerId}/user/${userId}`;

        const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedUser)
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating user:", e);
    }
}

export async function deleteUser(userId) {
    try {

        const apiUrl = `${baseUrl}/customer/${parentCustomerId}/user/${userId}`;

        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Bad network response: ${response.status} - ${errorText}`);
        }


    } catch (e) {
        console.error('userdataJS: deleteUser - ERROR', e);
        throw e;  // Re-throw the error for higher-level handling if necessary
    }
}