import global from "../utils/global.js";

const baseUrl = 'https://cloud-connector-dashboard.restiolabs.com';

export const getUserData = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
        const token = userData.token;
        const eMail = userData.email;
        const userRole = userData.role;
        let parentCustomerId = userData.customer;
        if (!parentCustomerId) {
            const customerToken = validateToken();
            parentCustomerId = customerToken?.decodedToken?.user?.customerId;
        }
        return { token, eMail, userRole, parentCustomerId };
    }
    return null;
}

const { token } = getUserData() || {};

export const getToken = async (emailAddress, password) => {
    try {
        const response = await fetch(`${baseUrl}/auth/api`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ emailAddress, password })
        });

        const data = await response.json();

        if (response.ok && data.jwtToken && data.success !== false) {

            // Successful login
            //localStorage.setItem('token', data.jwtToken);
            const tokenData = getTokenData(data.jwtToken); 
            return tokenData;

        } else {
            // Failed login
            return {
                valid: false,
                message: 'Login failed. Please check your e-Mail Address and Password.'
            };
        }
    } catch (error) {
        // Handle fetch errors (e.g., network issues)
        return {
            valid: false,
            message: `An error occurred: ${error.message}`
        };
    }
}

// auth.js
export const verifyToken = async(verificationToken) => {

    const response = await fetch(`${baseUrl}/auth/verify-passwordless`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ verificationToken })
    });

    const data = await response.json(); 
    if (!data.jwtToken || data.jwtToken == null) {
        return { valid: false, message: 'No token found' };
    }

    const tokenData = getTokenData(data.jwtToken);  
    localStorage.setItem(
        'userData',
        JSON.stringify({
          customer: tokenData.decodedToken.user.customerId,
          email: tokenData.decodedToken.user.email,
          role: tokenData.decodedToken.user.userRole,
          token: data.jwtToken
        })
      );  

    return tokenData; 
}

export async function sendLoginMail(email,  siteType) {
    try {
        
        const t = 0;
        const response = await fetch('https://cloud-connector-dashboard.restiolabs.com/auth/passwordless', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email, app: siteType })
            //body: JSON.stringify({ email: email, app: "tpm-pressuretrack" })
            //body: JSON.stringify({ emailAddress, password })
        });

        const data = await response.json();

        //if (response.ok && data.jwtToken && data.success !== false) {
        if (response.ok && data.success === true) {
            // Successful login
            //localStorage.setItem('token', data.jwtToken);
            //const tokenData = getTokenData(token); 
            //return tokenData;
            return data;
        } else {
            // Failed login
            return {
                valid: false,
                message: 'Login failed. Please check your e-Mail.'
            };
        }
    } catch (error) {
        // Handle fetch errors (e.g., network issues)
        return {
            valid: false,
            message: `An error occurred: ${error.message}`
        };
    }
}

export function validateToken() {    

    //pressuretrack@admin.com ysiQ3PcNKeQ8W4Z$
    //token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImN1c3RvbWVySWQiOiI2NmIzZDZjNmFhZWEzZTY0MzgxYTNhZTAiLCJlbWFpbCI6InByZXNzdXJldHJhY2tAYWRtaW4uY29tIn0sImlhdCI6MTcyMzYzMTgxMywiZXhwIjoxNzU1MTY3ODEzLCJhdWQiOiJodHRwczovL2Nsb3VkLWNvbm5lY3Rvci1kYXNoYm9hcmQucmVzdGlvbGFicy5jb20iLCJpc3MiOiJjbG91ZC1jb25uZWN0b3ItZGFzaGJvYXJkLnJlc3Rpb2xhYnMuY29tIn0.6BDWhCUaVS5VgPMQ3I4TDyvHvN0ANrwqvLBOYUz57c4';
    //annanda@pressuretrack.co.za    
    //token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJJZCI6IjY2ZDliOTU2NjgzZGZmZjNjOTg4MmZkNyIsImN1c3RvbWVySWQiOiI2NmIzZDZjNmFhZWEzZTY0MzgxYTNhZTAiLCJlbWFpbCI6ImFubmFuZGFAcHJlc3N1cmV0cmFjay5jby56YSIsInVzZXJSb2xlIjoiQWRtaW5pc3RyYXRvciIsImN1c3RvbWVySGllcmFyY2h5RGVwdGgiOjF9LCJpYXQiOjE3MjYxNjUzODEsImV4cCI6MTc0MTk3NjU4MSwiYXVkIjoiaHR0cHM6Ly9jbG91ZC1jb25uZWN0b3ItZGFzaGJvYXJkLnJlc3Rpb2xhYnMuY29tIiwiaXNzIjoiY2xvdWQtY29ubmVjdG9yLWRhc2hib2FyZC5yZXN0aW9sYWJzLmNvbSJ9.Nv7IXrcU6PpKCGnc3aH1bOK4NLBNYtUTC2OCgnO93A4';

    if (!token || token == null) {
        return { valid: false, message: 'No token found' };
    }
    const tokenData = getTokenData(token);
    if (tokenData.valid) {
        global.token = token;
        global.customerId = tokenData.decodedToken.user.customerId;        
    }
    // localStorage.setItem('token', token);
    // localStorage.setItem('customer', tokenData.decodedToken.user.customerId);
    // localStorage.setItem('userRole', tokenData.decodedToken.user.userRole);

    localStorage.setItem(
        'userData',
        JSON.stringify({
          customer: tokenData.decodedToken.user.customerId,
          email: tokenData.decodedToken.user.email,
          role: tokenData.decodedToken.user.userRole,
          token: token
        })
      );  
    return tokenData;
}

export function getTokenData(token) {

    // Split the JWT and decode the payload (second part)
    const payloadBase64Url = token.split('.')[1];

    try {
        // Convert base64url to base64 by replacing URL-specific characters
        const payloadBase64 = payloadBase64Url.replace(/-/g, '+').replace(/_/g, '/');

        // Decode the base64 string to JSON
        const decodedPayload = JSON.parse(atob(payloadBase64));

        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

        if (decodedPayload.exp && decodedPayload.exp > currentTime) {
            return { valid: true, token: token, decodedToken: decodedPayload };
        } else {
            return { valid: false, message: 'Token has expired' };
        }
    } catch (error) {
        // Handle errors in decoding or parsing
        return { valid: false, message: 'Failed to decode token' };
    }
}



