import { sendLoginMail, verifyToken } from "../data/auth.js";

export async function handleAuthentication() {
    const siteType = getSiteType();
    console.log(`SiteType: ${siteType}`);
    const urlParams = new URLSearchParams(window.location.search);
    const verificationToken = urlParams.get('token');
  
    if (verificationToken) {
      // Return the result of handleVerification to check if authentication succeeded
      return await handleVerification(verificationToken);
    } else {
      toggleVerifySection(false);  // Show login if there's no token
      return await login(siteType);        // Return result of login if no verification token is present
    }
  }

  const getSiteType = () => {
    const host = window.location.host.toLowerCase();  
    console.log(`HOST: ${host}`);   
    return host.includes("tpm.pressuretrack") ? "tpm-pressuretrack" : "tpm-test";
  }  
    
  export async function handleVerification(verificationToken) {
    toggleVerifySection(true);
  
    try {
      const statusElement = document.getElementById('status');
      const customerToken = await verifyToken(verificationToken);  // Fetch token via the separated function
  
      if (customerToken.valid) {
        if (statusElement) statusElement.textContent = 'Verification successful! Redirecting...';
        return customerToken; // Return the token if verification succeeds
      } else {
        if (statusElement) statusElement.textContent = 'Verification failed. Please try again.';
        return null;
      }
    } catch (error) {
      displayError('Error during authentication. Please try again.');
      return null;
    }
  }

  const login = async (siteType) => {
    try {
        document.getElementById('login').addEventListener('submit', async function (event) {
            event.preventDefault();
  
            const emailAddress = document.getElementById('username').value;
            const emailSent = await sendLoginMail(emailAddress, siteType);
  
            if (emailSent.success) {
                const loginForm = document.getElementById('login');
                if (loginForm) loginForm.style.display = 'none';
                const emailSentSection = document.getElementById('email-sent');
                if (emailSentSection) emailSentSection.style.display = 'block';
            } else {
                alert('Something went wrong. Try again!');
            }
        });
    } catch (e) {
        displayError('Login failed. Please try again.');
    }
  }

  function toggleVerifySection(showVerify) {
    const verifySection = document.getElementById('verify');
  
    //if (emailSentSection) emailSentSection.style.display = showVerify ? 'none' : 'block';
    if (verifySection) verifySection.style.display = showVerify ? 'block' : 'none';
  }
  