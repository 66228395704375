import { validateToken, getUserData } from "./auth.js";

const baseUrl = 'https://cloud-connector-dashboard.restiolabs.com/api';

const { token, parentCustomerId } = getUserData() || {};

const userData = JSON.parse(localStorage.getItem('userData'));
if (userData) {
    const token = userData.token;
    let parentCustomerId = userData.customer;
    if (!parentCustomerId) {
        const customerToken = validateToken();
        parentCustomerId = customerToken?.decodedToken?.user?.customerId;
    }
}

export const fetchRepeaters = async (unit) =>{
    try {   

        const apiUrl = unit
                ? `${baseUrl}/customer/${unit.customerId}/repeater?unitId__oid=${unit.Id}`
                : `${baseUrl}/customer/${parentCustomerId}/repeater?inclChild=true&limit=0`;        
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error('repeatersJS: fetchRepeaters - ERROR ', e);
    }
}

export const createRepeater = async(customerId, repeater) => {

    const apiUrl = `${baseUrl}/customer/${customerId}/repeater`;
    const response = await fetch(apiUrl, {
        method: "POST",
        headers: { 
            'Authorization': `Bearer ${token}`, 
            "Content-Type": "application/json" 
        },
        body: JSON.stringify({
            Id: repeater.Id,
            unitId: repeater.unitId,
            unitType: `${repeater.unitType.charAt(0).toUpperCase()}${repeater.unitType.slice(1).toLowerCase()}`,
            config: repeater.config
        })
    });
    if (!response.ok) {
        const errorDetails = await response.json();
        console.error(`Failed to create repeater: ${response.statusText} - ${JSON.stringify(errorDetails)}`);
        return;
    }
    console.log("Repeater created:", await response.json());
}

export const updateRepeater = async(customerId, repeater) => {

    const response = await fetch(`${baseUrl}/customer/${customerId}/repeater/${repeater.Id}`, {
        method: "PATCH",
        headers: { 
            'Authorization': `Bearer ${token}`, 
            "Content-Type": "application/json" 
        },
        body: JSON.stringify({
            unitId: repeater.unitId,
            unitType: repeater.unitType,
            config: repeater.config
        })
    });
    
    if (!response.ok) {
        const errorDetails = await response.json();
        console.error("Failed to update repeater:", response.status, errorDetails);
        throw new Error(`Failed to update repeater: ${response.statusText}`);
    }
    console.log("Repeater updated:", await response.json());
}

export const deleteRepeater = async (customerId, repeaterId) => {
    const apiUrl = `${baseUrl}/customer/${customerId}/repeater/${repeaterId}`;

    try {
        const response = await fetch(apiUrl, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            // Handle errors (e.g., repeater not found, unauthorized, etc.)
            const errorDetails = await response.json();
            console.error("Error deleting repeater:", errorDetails);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse and return the JSON response for a successful request
        const responseData = await response.json();
        console.log("Repeater deleted successfully:", responseData);
        return responseData;

    } catch (error) {
        console.error("An error occurred:", error.message);
        throw error;
    }
};

export const createSignalBooster = async(customerId, booster) => {

    const apiUrl = `${baseUrl}/customer/${customerId}/booster`;
    const response = await fetch(apiUrl, {
        method: "POST",
        headers: { 
            'Authorization': `Bearer ${token}`, 
            "Content-Type": "application/json" 
        },
        body: JSON.stringify({
            Id: booster.Id,
            unitId: booster.unitId,
            unitType: booster.unitType
        })
    });
    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to create signal booster: ${response.statusText} - ${JSON.stringify(errorDetails)}`);
    }
    console.log("Signal booster created:", await response.json());
}

export const deleteSignalBooster = async(customerId, boosterId) => {

    const apiUrl = `${baseUrl}/customer/${customerId}/booster/${boosterId}`;
    const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: { 
            'Authorization': `Bearer ${token}`, 
            "Content-Type": "application/json" 
        }
    });
    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to DELETE signal booster: ${response.statusText} - ${JSON.stringify(errorDetails)}`);
    }
    console.log("Signal booster deleted:", await response.json());
}
