export const showSplash = (customerToken, version) => {  
    hideSections();
    const userName = customerToken?.decodedToken?.user?.email
    const splashContainer = document.getElementById('splashcontainer');
    splashContainer.className = 'container-splash';
    while (splashContainer.hasChildNodes()) {
        splashContainer.removeChild(splashContainer.lastChild);
    }
    const splashFragment = document.createDocumentFragment();
  
    const splash = document.createElement('div');
    splash.id = 'splash';
  
    const splashIcon = document.createElement('div');
    splashIcon.id = 'splashicon';
    splashIcon.className = 'splashicon';
    splash.appendChild(splashIcon);
  
    const splashLogo = document.createElement('div');
    splashLogo.id = 'splashlogo';
    splashLogo.className = 'splashlogo';
    splash.appendChild(splashLogo);
  
    const splashLabel = document.createElement('div');
    splashLabel.id = 'splashlabel';
    splashLabel.className = 'splashlabel';
    splashLabel.innerText = `If you can't measure it, you can't manage it.`;
    splash.appendChild(splashLabel);
  
    const userNameSplash = document.createElement('div');
    userNameSplash.id = 'username';
    userNameSplash.className = 'username';
    userNameSplash.innerText = userName;
    splash.appendChild(userNameSplash);
  
    const versionSplash = document.createElement('div');
    versionSplash.id = 'splashversion';
    versionSplash.className = 'version';
    versionSplash.innerText = `version ${version}`;
    splash.appendChild(versionSplash);
  
    splashFragment.appendChild(splash);
    splashContainer.appendChild(splashFragment);
}

const hideSections = () => {
    const loginContainer = document.getElementById('logincontainer');
    if (loginContainer) loginContainer.style.display = 'none';
    document.getElementById('header').style.display = 'none';
    document.getElementById('footer').style.display = 'none';
  }  