import global from '../../../common/utils/global.js'
import { createUnitGroup, updateUnitGroupName, getUnitGroupUnits, updateUnitGroupUnits, deleteUnitGroup, getUnitGroupData } from '../../../common/data/unitgroups.js'
import { displayMainPane, createMainUnitContainer } from "../mainpage.js";
import { displayStatusSummary } from '../diagrams/unitstatusesdiagrams.js';
import { displayUnitDetail } from "../detailpage.js";

var gEditUnitGroup = false;

export function registerUnitGroupEvents(units) {

    const unitsListButtonMain = document.getElementById('unitslistbuttonmain');
    unitsListButtonMain.onclick = (e) => {
        e.stopImmediatePropagation();

        global.mainUnitsListSelected = true;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnTemperature').disabled = false;
        document.getElementById('rbtnVoltage').disabled = false;

        displayMainPane(units);
    }

    const unitGroupsButtonMain = document.getElementById('unitgroupsbuttonmain');
    unitGroupsButtonMain.addEventListener('click', function clickHandler(e) {
        global.mainUnitsListSelected = false;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnPressure').checked = true;
        global.selectedMetric = 'PRESSURE';

        document.getElementById('rbtnTemperature').disabled = true;
        document.getElementById('rbtnVoltage').disabled = true;

        displayMainPane(units);
    });

    const unitGroupsMenuMain = document.getElementById('unitgroupsmenumain');
    unitGroupsMenuMain.addEventListener('click', function clickHandler(e) {
        if (e.target.tagName === 'LI') {
            switch (e.target.id) {
                case 'newunitsgroupmain':
                    openNewUnitGroupForm(units);
                    break;
                case 'editdeleteunitsgroupmain':
                    openEditDeleteUnitsGroupForm(units);
                    break;
                case 'assignunitsgroupmain':
                    openAssignUnitsGroupForm(units);
                    break;
            }
        }
    }, false);

    const unitgroupsmenu = document.getElementById('unitgroupsmenu');
    unitgroupsmenu.addEventListener('click', function clickHandler(event) {
        if (event.target.tagName === 'LI') {
            switch (event.target.id) {
                case 'newunitsgroup':
                    openNewUnitGroupForm(units);
                    break;
                case 'editdeleteunitsgroup':
                    openEditDeleteUnitsGroupForm(units);
                    break;
                case 'assignunitsgroup':
                    openAssignUnitsGroupForm();
                    break;
            }
        }
    }, false);

    const unitslistbutton = document.getElementById('unitslistbutton');
    unitslistbutton.addEventListener('click', function clickHandler(e) {
        global.showMainPage = true;
        global.mainUnitsListSelected = true;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        displayMainPane(units);
    });

    const unitGroupsButton = document.getElementById('unitgroupsbutton');
    unitGroupsButton.addEventListener('click', function clickHandler(e) {
        global.mainUnitsListSelected = false;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnTemperature').disabled = true;
        document.getElementById('rbtnVoltage').disabled = true;

        document.getElementById('rbtnPressure').checked = true;
        global.selectedMetric = 'PRESSURE';

        displayMainPane(units);
    });
}

export function registerUnitsGroupsEvents1(units) {

    const unitsListButtonMain = document.getElementById('unitslistbuttonmain');
    unitsListButtonMain.addEventListener('click', function clickHandler(e) {
        global.mainUnitsListSelected = true;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnTemperature').disabled = false;
        document.getElementById('rbtnVoltage').disabled = false;

        displayMainPane(units);
    });

    const unitGroupsButtonMain = document.getElementById('unitgroupsbuttonmain');
    unitGroupsButtonMain.addEventListener('click', function clickHandler(e) {
        global.mainUnitsListSelected = false;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnPressure').checked = true;
        global.selectedMetric = 'PRESSURE';

        document.getElementById('rbtnTemperature').disabled = true;
        document.getElementById('rbtnVoltage').disabled = true;

        displayMainPane(units);
    });

    const unitGroupsMenuMain = document.getElementById('unitgroupsmenumain');
    unitGroupsMenuMain.addEventListener('click', function clickHandler(e) {
        if (e.target.tagName === 'LI') {
            switch (e.target.id) {
                case 'newunitsgroupmain':
                    openNewUnitGroupForm(units);
                    break;
                case 'editdeleteunitsgroupmain':
                    openEditDeleteUnitsGroupForm(units);
                    break;
                case 'assignunitsgroupmain':
                    openAssignUnitsGroupForm(units);
                    break;
            }
        }
    }, false);

    const unitgroupsmenu = document.getElementById('unitgroupsmenu');
    unitgroupsmenu.addEventListener('click', function clickHandler(event) {
        if (event.target.tagName === 'LI') {
            switch (event.target.id) {
                case 'newunitsgroup':
                    openNewUnitGroupForm(units);
                    break;
                case 'editdeleteunitsgroup':
                    openEditDeleteUnitsGroupForm(units);
                    break;
                case 'assignunitsgroup':
                    openAssignUnitsGroupForm();
                    break;
            }
        }
    }, false);

    const unitslistbutton = document.getElementById('unitslistbutton');
    unitslistbutton.addEventListener('click', function clickHandler(e) {
        global.showMainPage = true;
        global.mainUnitsListSelected = true;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];
        
        displayMainPane(units);
    });

    const unitGroupsButton = document.getElementById('unitgroupsbutton');
    unitGroupsButton.addEventListener('click', function clickHandler(e) {
        global.mainUnitsListSelected = false;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnTemperature').disabled = true;
        document.getElementById('rbtnVoltage').disabled = true;

        document.getElementById('rbtnPressure').checked = true;
        global.selectedMetric = 'PRESSURE';

        displayMainPane(units);
    });
}

export const getUnitGroupName = () => {
    let unitGroups = global.unitGroups;
    let unitGroupId = global.selectedGroupId;
    let unitGroupName = "";
    if (unitGroups.length > 0) {
        for (let i = 0; i < unitGroups.length; i++) {
            if (unitGroups[i].unitGroupId === unitGroupId) {
                unitGroupName = unitGroups[i].unitGroupName;
                break;
            }
        }
    } else {
        return "";
    }
    return unitGroupName;
}

export async function displayMainUnitsGroupsList(units) {

    try {
        const unitGroups = await getUnitGroupData();
        global.unitGroups = unitGroups;
        global.units = units;

        let unitsGroupsListMain = document.getElementById('unitslistmain');
        while (unitsGroupsListMain.hasChildNodes()) {
            unitsGroupsListMain.removeChild(unitsGroupsListMain.lastChild);
        }

        let unitsGroupsListMainFragment = document.createDocumentFragment();
        let unitsGroupsListMainContainerDiv = document.createElement('div');
        unitsGroupsListMainContainerDiv.id = 'unitgroupslistcontainer';
        unitsGroupsListMainContainerDiv.className = 'unitslistcontainer';

        unitsGroupsListMainFragment.appendChild(unitsGroupsListMainContainerDiv);
        unitsGroupsListMain.appendChild(unitsGroupsListMainFragment);

        let previousSelectedGroup = false;        
        let selectedGroupId = global.selectedGroupId;
        for (let i = 0; i < unitGroups.length; i++) {
            const unitGroupDiv = document.createElement('div');
            unitGroupDiv.className = 'unitgroup';
            const unitGroupId = unitGroups[i].unitGroupId;
            unitGroupDiv.id = `ug${unitGroupId}`;
            unitGroupDiv.show = false;
            unitGroupDiv.innerHTML = `${unitGroups[i].unitGroupName}`;
            unitsGroupsListMainContainerDiv.appendChild(unitGroupDiv);
            //const groupUnits = unitGroups[i].units;
            const groupUnits = unitGroups[i].unitsDetails;
            if (selectedGroupId > 0 && selectedGroupId === unitGroupId ) {
                unitGroupDiv.show = true;
                let selectedGroupUnits = [];
                
                for (const groupUnit of groupUnits) {
                    for (let u = 0; u < units.length; u++) {
                        if (groupUnit.imeiNumber === units[u].imei && groupUnit.unitName === units[u].name) {
                            selectedGroupUnits.push(units[u]);
                            let unitDiv = createMainUnitContainer(units[u], units, true);
                            unitGroupDiv.appendChild(unitDiv);
                            unitDiv.onclick = (event) => {
                                event.stopImmediatePropagation();
                                global.showMainPage = false;
                                global.selectedUnitId = groupUnit;
                                global.selectedGroupUnits = selectedGroupUnits;

                                displayUnitDetail('UNIT', units, units[u]);
                            };
                        }
                    }
                }
                global.selectedGroupUnits = selectedGroupUnits;
                displayStatusSummary(selectedGroupUnits || []);  
            }
 
            unitGroupDiv.onclick = function (e) {
                e.stopImmediatePropagation();
                //document.getElementById('rbtnPressure').checked = true;
                document.getElementById('rbtnTemperature').disabled = false;
                document.getElementById('rbtnVoltage').disabled = false;
                selectedGroupId = global.selectedGroupId;
                if (selectedGroupId && selectedGroupId > 0) {
                    let selectedUnitGroupDiv = document.getElementById(selectedGroupId);
                    if (selectedUnitGroupDiv.id === unitGroupDiv.id && selectedUnitGroupDiv.show) {
                        previousSelectedGroup = true;
                    } else {
                        previousSelectedGroup = false;
                    }
                    if (selectedUnitGroupDiv.show) {
                        selectedUnitGroupDiv.show = false;
                        while (selectedUnitGroupDiv.childNodes.length > 1) {
                            selectedUnitGroupDiv.removeChild(selectedUnitGroupDiv.lastChild);
                        }
                    }
                } else { previousSelectedGroup = false;}

                if (!unitGroupDiv.show && groupUnits.length > 0 && !previousSelectedGroup) {
                    unitGroupDiv.show = true;
                    selectedGroupId = unitGroups[i].unitGroupId;
                    //let selectedGroupName = unitGroups[i].getName();
                    global.selectedGroupId = selectedGroupId;
                    let selectedGroupUnits = [];
                    for (const groupUnit of groupUnits) {
                        for (let u = 0; u < units.length; u++) {
                            if (groupUnit.imeiNumber === units[u].imei && groupUnit.unitName === units[u].name){
                                selectedGroupUnits.push(units[u]);
                                let unitDiv = createMainUnitContainer(units[u], units, true);
                                unitGroupDiv.appendChild(unitDiv);
                                unitDiv.onclick = (event) => {
                                    event.stopImmediatePropagation();

                                    global.selectedUnitId = groupUnit;
                                    global.selectedGroupUnits = selectedGroupUnits;

                                    //displayUnitInfo(parseInt(units[u].unitId), units);
                                    //displayDiagramViews(units[u]);
                                    displayUnitDetail('UNIT', units, units[u]);
                                };
                            }
                        }
                    }
                    global.selectedGroupUnits = selectedGroupUnits;
                    displayStatusSummary(selectedGroupUnits || []);
                } else {
                    document.getElementById('rbtnTemperature').disabled = true;
                    document.getElementById('rbtnVoltage').disabled = true;

                    document.getElementById('rbtnPressure').checked = true;
                    global.selectedMetric = 'PRESSURE';

                    global.selectedGroupId = 0;
                    global.selectedGroupUnits = [];

                    displayStatusSummary([]); //all groups collapsed - total of all groups
                }
            }
        }
        if (global.selectedMetric === 'PRESSURE')
        displayStatusSummary([]);
    }
    catch (e) {
        console.error("unitgroupsJS - displayMainUnitsGroupsList: " + e.error);
        return;
    }
}

export function openNewUnitGroupForm(units) {  

    document.getElementById("newgroupform").reset();
    let newUnitGroupModal = document.getElementById('newunitgroupmodal');
    let closeModal = document.getElementById("closenewunitgroupmodal");
    closeModal.onclick = function () {
        newUnitGroupModal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == newUnitGroupModal) {
            newUnitGroupModal.style.display = "none";
        }
    };      

    let btnSubmitGroup = document.getElementById('btnsubmitnewunitgroup');    
    btnSubmitGroup.onclick = async (e) => {
        e.stopImmediatePropagation();
        document.body.style.cursor = 'wait';
        const unitGroupName = document.getElementById('groupname').value;
        const units = [];
        try {
            document.body.style.cursor = 'wait';
            const newGroup = await createUnitGroup(unitGroupName, units);
            if (unitGroupName === newGroup.unitGroupName) {
                console.log('Unit Group Created:', newGroup);
            }            
        } catch (error) {
            console.error('Error Creating Unit Group:', error);
            alert(`Error creating Unit Group: ${error}. Try again.`)
        }
        newUnitGroupModal.style.display = 'none'; 
        //$('#container').overlayMask('hide');
        //$("#loadingBox").modal("hide");
        document.body.style.cursor = 'default';
        return false;
    };

    newUnitGroupModal.style.display = 'block';    
}

export const openEditDeleteUnitsGroupForm = async(units) => {

    document.body.style.cursor = 'wait';
    //$('#container').overlayMask();

    const editDeleteGroupModal = document.getElementById('editdeletegroupmodal');

    const closeModal = document.getElementById("closeeditdeletegroupmodal");
    closeModal.onclick = function () {
        editDeleteGroupModal.style.display = 'none';
    };

    window.onclick = function (event) {
        if (event.target == editDeleteGroupModal) {
            editDeleteGroupModal.style.display = 'none';
        }
    };

    gEditUnitGroup = true;
    const unitGroups = await populateUnitGroupsSelect(gEditUnitGroup);

    let selectedGroupId = "";
    let groupName = "";
    const unitGroupsSelect = document.getElementById('editdeleteunitgroup');
    unitGroupsSelect.onchange = (e) => {
        e.stopImmediatePropagation();
        selectedGroupId = unitGroupsSelect.options[unitGroupsSelect.selectedIndex].value;
        groupName = unitGroupsSelect.options[unitGroupsSelect.selectedIndex].text;
        document.getElementById('editgroupname').value = groupName;         
        return false;
    };

    const btneditgroupname = document.getElementById('btneditgroupname');
    btneditgroupname.onclick = async (e) => { 
        e.stopImmediatePropagation();
        try {
            document.body.style.cursor = 'wait';
            const unitGroup = unitGroups.find(ug => ug.unitGroupId === selectedGroupId);
            const updatedUnitGroup = await updateUnitGroupName(unitGroup, document.getElementById('editgroupname').value)
            if (updatedUnitGroup) {
                console.log("Unit Group successfully renamed.");
                displayMainPane(units);
            }
        } catch (error) {
            console.error(`Unit group NOT renamed: ${error}`);
            alert(`Unit group NOT renamed: ${error}. Try again.`);
        }
        editDeleteGroupModal.style.display = 'none';
        document.body.style.cursor = 'default';
        return false;
    };
    
    const btndeletegroupname = document.getElementById('btndeletegroupname');
    btndeletegroupname.onclick = async (e) => { 
        e.stopImmediatePropagation();        
        try {
            document.body.style.cursor = 'wait';
            const deletedGroup = await deleteUnitGroup(selectedGroupId);
            if (deletedGroup) {
                console.log("Unit group deleted successfully");
                displayMainPane(units);
            }
        } catch (error) {
            console.error(`Unit group NOT deleted: ${error}`);
            alert(`Unit group NOT deleted: ${error}. Try again.`);
        }
        editDeleteGroupModal.style.display = 'none';
        document.body.style.cursor = 'default';
        return false;
    };
    
    document.getElementById("editdeletegroupform").reset();
    editDeleteGroupModal.style.display = "block";

    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    return false;
}

export function openAssignUnitsGroupForm(units) {

    document.body.style.cursor = 'wait';

    const assignUnitsGroupModal = document.getElementById('assignunitsgroupmodal');

    const assignUnitsList = document.getElementById('assignunits');
    while (assignUnitsList.options.length) assignUnitsList.options.remove(0);

    const assignedUnitsList = document.getElementById('assignedunits');
    while (assignedUnitsList.options.length) assignedUnitsList.options.remove(0);

    let closeModal = document.getElementById("closeassignunitsgroupmodal");
    closeModal.onclick = function () {
        assignUnitsGroupModal.style.display = 'none';
    };

    window.onclick = function (event) {
        if (event.target == assignUnitsGroupModal) {
            assignUnitsGroupModal.style.display = 'none';
        }
    };

    gEditUnitGroup = false;
    populateUnitGroupsSelect(gEditUnitGroup);

    let selectedGroupId = 0;
    let unitGroups = [];
    const unitGroupsSelect = document.getElementById('newunitgroup');
    unitGroupsSelect.addEventListener('change', async function () {
        selectedGroupId = unitGroupsSelect.options[unitGroupsSelect.selectedIndex].value;
        unitGroups = await getUnitGroupData();
        populateUnitGroupList(selectedGroupId, units, unitGroups);
    });

    $("#btnRight").click(function (e) {
        let selectedOpts = $("#assignunits option:selected");
        if (selectedOpts.length == 0) {
            //alert("Nothing to move.");
            e.preventDefault();
        }
        $("#assignedunits").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    });
    $("#btnAllRight").click(function (e) {
        let selectedOpts = $("#assignunits option");
        if (selectedOpts.length == 0) {
            //alert("Nothing to move.");
            e.preventDefault();
        }
        $("#assignedunits").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    });
    $("#btnLeft").click(function (e) {
        let selectedOpts = $("#assignedunits option:selected");
        if (selectedOpts.length === 0) {
            //alert("Nothing to move.");
            e.preventDefault();
        }
        $("#assignunits").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    });
    $("#btnAllLeft").click(function (e) {
        let selectedOpts = $("#assignedunits option");
        if (selectedOpts.length == 0) {
            //alert("Nothing to move.");
            e.preventDefault();
        }
        $("#assignunits").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    });

    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    let assignunitsgroupform = document.getElementById('assignunitsgroupform');
    assignunitsgroupform.addEventListener('submit', async function (e) {
        e.preventDefault();
        e.stopPropagation();
        let assignUnits = [];
        const assignedUnits = document.getElementById('assignedunits');
        
        if (assignedUnits.length > 0) {
            for (let u = 0; u < assignedUnits.length; u++) {
                assignUnits.push(assignedUnits[u].value);
            }
        }

        const unitGroup = unitGroups.find(ug => ug.unitGroupId === selectedGroupId);
        const unitsAssigned = await updateUnitGroupUnits(unitGroup, assignUnits);
        if (unitsAssigned) {
            document.getElementById("assignunitsgroupmodal").style.display = 'none';
            displayMainPane(units);
        }
    });

    document.getElementById("assignunitsgroupmodal").style.display = "block";

    return false;
}

async function populateUnitGroupsSelect(gEditUnitGroup) {

    let unitGroupsSelect = document.getElementById('newunitgroup');
    if (gEditUnitGroup)
        unitGroupsSelect = document.getElementById('editdeleteunitgroup');

    while (unitGroupsSelect.options.length) unitGroupsSelect.options.remove(0);
    unitGroupsSelect.value = "";
    const unitGroups = await getUnitGroupData();
    const unitGroupOption = document.createElement("option");
    unitGroupOption.value = "";
    unitGroupOption.text = " -- select a group --";
    unitGroupOption.hidden = true;
    unitGroupsSelect.appendChild(unitGroupOption);
    for (const unitGroup of unitGroups) {
        const unitGroupOption = document.createElement("option");
        unitGroupOption.value = unitGroup.unitGroupId;
        unitGroupOption.text = unitGroup.unitGroupName;
        unitGroupsSelect.appendChild(unitGroupOption);
    }
    return unitGroups;
}

function populateUnitGroupList(selectedGroupId, units, unitGroups) {    
    
    let groupUnits = [];
    for (let g = 0; g < unitGroups.length; g++) {
        if (unitGroups[g].unitGroupId === selectedGroupId) {
            groupUnits = unitGroups[g].unitsDetails;
            break;
        }
    }

    const assignedUnitsList = document.getElementById('assignedunits');
    while (assignedUnitsList.options.length) assignedUnitsList.options.remove(0);
    for (const groupUnit of groupUnits) {
        const assignedUnitOption = document.createElement("option");
        assignedUnitOption.value = groupUnit;
        for (let u = 0; u < units.length; u++) {            
            if (groupUnit.imeiNumber === units[u].imei && groupUnit.unitName === units[u].name) {
                assignedUnitOption.value = units[u].imei;
                assignedUnitOption.text = units[u].name;
                break;
            }
        }
        assignedUnitsList.appendChild(assignedUnitOption);
    }

    units = units.filter(u => 
        !groupUnits.some(g => g.imeiNumber === u.imei && g.unitName === u.name)
    );
    const assignUnitsList = document.getElementById('assignunits');
    while (assignUnitsList.options.length) assignUnitsList.options.remove(0);
    for (const canAssignUnit of units) {
        const canAssignUnitOption = document.createElement("option");
        canAssignUnitOption.value = canAssignUnit.imei;
        canAssignUnitOption.text = canAssignUnit.name;
        assignUnitsList.appendChild(canAssignUnitOption);
    }
}
