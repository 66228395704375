import { createTruck, updateTruck, createTrailer, updateTrailer } from "../../../common/data/units.js";
import { main } from "../mainpage.js";
import { deleteSignalBooster }  from "../../../common/data/repeaters.js";
import { updateRepeaters, createSignalBoosters } from "../../../common/data/handlerepeaterdata.js"
//import { createRepeaterDiagram } from "../diagrams/repeaterdiagram.js";

let signalBoosterCounter = 0;
let newTruck = true;
let isSubmitting = false;

// Named listener function for "input" event
const handleNumberOfAxles = (e) => {     
    e.stopImmediatePropagation(); 
    const axlesContainer = document.getElementById("axlescontainer");
    axlesContainer.innerHTML = "";
    addAxlesStep();
};

// Named listener functions for buttons
const handleAddSignalBoostersClick = (e) => {
    e.stopImmediatePropagation();
    addSignalBooster(); 
};

// Form submit listener
const handleFormSubmit = (unit, fleetAsset, data, axleData, signalBoosters) => {

    if (isSubmitting) return;
    isSubmitting = true; 

    // Function to handle asset creation/updation and call updateRepeaters
    const processFleetAsset = (promise) => {
        promise
            .then(result => {
                fleetAsset = result; // Update fleetAsset with the new/updated unit
                const unitHeaderName = document.getElementById(`unitheadername${unit?.unitId || unit?.imei}`);
                if (unitHeaderName) {
                    unitHeaderName.innerText = data.name || unit?.imei; // Update the header name
                }                
                updateRepeaters(fleetAsset, axleData); // Update repeaters
                createSignalBoosters(fleetAsset, signalBoosters);
                fleetAsset.axleData = axleData;
                //createRepeaterDiagram(fleetAsset);
                closeConfigForm(); // Close form after successful update
                console.log("Fleet asset processed successfully:", result);
            })
            .finally(() => {
                isSubmitting = false;
            })
            .catch(error => {
                console.error("Error processing fleet asset:", error);
            });

    };

    // Determine which promise to handle based on the logic
    if (unit && unit.imei && newTruck) {
        processFleetAsset(createTruck(unit.customerId, data));
    } else if (unit && unit.imei && !newTruck) {
        processFleetAsset(updateTruck(fleetAsset, data));
    } else {
        if (fleetAsset) {
            processFleetAsset(updateTrailer(fleetAsset, data));
        } else {
            processFleetAsset(createTrailer(data));
        }
    }
};   

const resetUnitConfigForm = () => {    

    document.getElementById('unitname').value = '';
    document.getElementById("numberofaxles").value=''
    document.getElementById('numberofsparewheels').value = '';
    document.getElementById('unitconfigform').reset();

    const addSignalBoostersButton = document.getElementById('addsignalboostersbtn');
    addSignalBoostersButton.style.display = 'block';
    document.getElementById('addsignalboostersstep').classList.add('d-none');
    document.getElementById('addaxlesstep').classList.add('d-none');

    clearContainer('axlescontainer');  
    clearContainer('signalboosterscontainer');  

    resetStateVariables();
}

function gatherFormData(imei, fleetAsset, fleetType) {

    const form = document.getElementById('unitconfigform');
    const formData = new FormData(form);

    const data = fleetType === 'TRUCK' ? {
        name: formData.get('unitname'),
        axles: parseInt(formData.get('numberofaxles')),
        spareWheelSlots: parseInt(formData.get('numberofsparewheels')) || 0,
        IMEI: imei,
    } : {
        name: formData.get('unitname'),
        axles: parseInt(formData.get('numberofaxles')),
        spareWheelSlots: parseInt(formData.get('numberofsparewheels')) || 0,
        trailerNumber: parseInt(formData.get('trailerorder'))
    }

    const numberOfAxles = parseInt(formData.get('numberofaxles'), 10);
    const axleData = [];
    let isValid = true;
    for (let i = 1; i <= numberOfAxles; i++) {
        const leftId = document.getElementById(`leftlocationid-${i}`).value;
        const leftWheel = document.getElementById(`leftrepeatertype-${i}`).value;
        const rightId = document.getElementById(`rightlocationid-${i}`).value;
        const rightWheel = document.getElementById(`rightrepeatertype-${i}`).value;

        if (!leftId || !leftWheel || !rightId || !rightWheel) {
            isValid = false;
            alert(`Please fill out all fields for axle ${i}.`);
            break;
        }

        axleData.push({
            left: {
                Id: parseInt(leftId),
                unitId: fleetAsset?.Id || null, 
                unitType: fleetType, 
                config: { side: "Left", axle: i, wheel: leftWheel }
            },
            right: {
                Id: parseInt(rightId),
                unitId: fleetAsset?.Id || null,
                unitType: fleetType,
                config: { side: "Right", axle: i, wheel: rightWheel }
            },
        });
    }

    // Gather signal booster data
    const signalBoosters = [];
    const signalBoosterRows = document.querySelectorAll('.signalbooster-row');
    signalBoosterRows.forEach((row, index) => {
        const input = row.querySelector('input');
        const signalBoosterId = parseInt(input.value, 10);
        if (!isNaN(signalBoosterId)) {
            signalBoosters.push({
                Id: signalBoosterId,
                unitId: fleetAsset?.Id || null,
                unitType: fleetType
            });
        }
    });

    if (!isValid) {
        return { isValid };
    }

    return { data, axleData, signalBoosters, isValid };
}

const resetStateVariables  = () => {
    isSubmitting = false; // Reset submission state
    newTruck = true;
    signalBoosterCounter = 0;
    document.getElementById('unitconfigform').reset(); 
};

function closeConfigForm() {
    const modal = document.getElementById('unitconfigmodal');
    if (!modal) {
        console.error('Modal element not found');
        return;
    }

    modal.classList.remove('show');
    modal.style.display = 'none';
    //modal.setAttribute('inert', ''); 

    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
        backdrop.remove(); // Remove backdrop only if it exists
    } else {
        console.warn("Backdrop not found");
    }

    // Clean up listeners and reset state
    resetUnitConfigForm();
    resetStateVariables();
}

const showSignalBoosters = (fleetAsset) => {
    const signalBoostersContainer = document.getElementById('signalboosterscontainer');
    signalBoostersContainer.innerHTML = ''; // Clear any existing rows

    // Loop through the signalBoosterIds and create rows for each
    fleetAsset.signalBoosterIds.forEach((id, index) => {
        const signalBoosterRow = document.createElement('div');
        signalBoosterRow.classList.add('row', 'signalbooster-row', 'mt-2');
        signalBoosterRow.id = `signalbooster-row-${index + 1}`;

        // Input for Signal Booster ID
        const signalBoosterDiv = document.createElement('div');
        signalBoosterDiv.classList.add('col-md-3');
        const signalBoosterInput = document.createElement('input');
        signalBoosterInput.type = 'text';
        signalBoosterInput.classList.add('form-control');
        signalBoosterInput.placeholder = 'Signal Booster ID';
        signalBoosterInput.value = id; // Prepopulate with the existing ID
        signalBoosterInput.disabled = true; // Disable editing for pre-existing IDs
        signalBoosterDiv.appendChild(signalBoosterInput);

        // Add/Remove Button
        const buttonDiv = document.createElement('div');
        buttonDiv.classList.add('col-md-1', 'd-flex', 'justify-content-center', 'align-items-center');
        const removeButton = document.createElement('button');
        removeButton.type = 'button';
        removeButton.classList.add('btn', 'btn-danger', 'btn-block', 'w-100', 'remove-repeater');
        removeButton.id = `remove-signalbooster-btn-${index + 1}`;
        removeButton.textContent = 'Remove';
        buttonDiv.appendChild(removeButton);

        // Placeholder for additional columns
        const placeHolderDiv =  document.createElement('div');
        placeHolderDiv.classList.add('col-md-8');

        // Append all elements to the row
        signalBoosterRow.append(signalBoosterDiv, buttonDiv, placeHolderDiv);
        signalBoostersContainer.appendChild(signalBoosterRow);

        // Attach remove functionality to the remove button
        removeButton.addEventListener('click', () => removeSignalBoosterRow(fleetAsset, removeButton));
    });

    // Add a button for creating a new signal booster
    if (fleetAsset.signalBoosterIds.length > 0) {
        addSignalBoosterRow(fleetAsset); // Ensure a new empty row for additional boosters
    }
}

export function openUnitConfigForm(unit, fleetAsset, fleetType, repeaters) {

    resetUnitConfigForm();

    const imei = unit ? unit.imei : null;
    const unitConfigModalLabel = document.getElementById('unitconfigmodallabel');
    const sinkIdLabel = document.getElementById('sinkidlabel');
    const sinkId = document.getElementById('sinkid');
    //const trailerOrder = document.getElementById('trailerorder');
    //const configureAxlesButton = document.getElementById('configureaxlesbtn');    
    const unitName = document.getElementById('unitname');
    const numberOfAxles = document.getElementById("numberofaxles");
    const numberOfSpareWheels = document.getElementById('numberofsparewheels');
    
    const addSignalBoostersButton = document.getElementById('addsignalboostersbtn');
    const unitConfigForm = document.getElementById('unitconfigform');

    // Ensure listeners are only added once
    attachEvent(numberOfAxles, "input", handleNumberOfAxles);
    attachEvent(addSignalBoostersButton, "click", handleAddSignalBoostersClick);
    
    unitConfigForm.onsubmit = (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        const { data, axleData, signalBoosters, isValid } = gatherFormData(imei, fleetAsset, fleetType); // Adjust this function as necessary
        if (isValid) {
            handleFormSubmit(unit, fleetAsset, data, axleData, signalBoosters); // Call submit logic with validated data
        }
    }

    if (!imei && fleetAsset) {
        newTruck = false;
        unitName.value = fleetAsset.name;
        numberOfAxles.value = fleetAsset?.axles || getNumberOfAxles(fleetAsset); ;
        //numberOfSpareWheels.value = fleetAsset.spareWheelSlots || "Enter Number of Spare Wheels";
    }
    if (imei && fleetType === 'TRUCK') {
        unitConfigModalLabel.textContent = `Configure Truck for IMEI ${imei}`;
        unitName.value = fleetAsset?.name || 'Enter Truck Name';
        sinkIdLabel.style.display = 'block';
        sinkId.style.display = 'block';  
        if (repeaters.length) sinkId.value = unit.sinkId || '';
        toggleTrailerOrder(false);
    } else {
        unitConfigModalLabel.textContent = `Configure Trailer`;
        toggleTrailerOrder(true, fleetAsset ? fleetAsset?.trailerNumber || '' : '');
        sinkIdLabel.style.display = 'none';        
        sinkId.style.display = 'none';
    }

    fleetAsset?.axles ? numberOfAxles.value = fleetAsset.axles : numberOfAxles.value = "Enter Number of Axles";
   

    if (repeaters && repeaters.length && fleetAsset && fleetAsset.axles > 0) {
        showAxlesConfig(fleetAsset, repeaters);
    } 

    if (fleetAsset && fleetAsset?.signalBoosterIds?.length > 0) {
        document.getElementById('addsignalboostersstep').classList.remove('d-none');
        document.getElementById('addsignalboostersbtn').style.display = 'none';
        showSignalBoosters(fleetAsset);
    } 
    fleetAsset?.spareWheelSlots ? numberOfSpareWheels.value = fleetAsset.spareWheelSlots : numberOfSpareWheels.value = "Enter Number of Spare Wheels";
    const unitConfigModal = new bootstrap.Modal(document.getElementById('unitconfigmodal'), {});
    unitConfigModal.show();
}

const getNumberOfAxles = (fleetAsset) => {
    let maxAxle = 0;
    if (fleetAsset.repeaters && fleetAsset.repeaters.length > 0) {
        let trailerRepeaters = fleetAsset.repeaters.filter(repeater => repeater.unitType === 'Trailer');
        trailerRepeaters.forEach(repeater => {
            if (repeater.config && repeater.config.axle) {
                maxAxle = Math.max(maxAxle, repeater.config.axle);
            }
        });
    }
    fleetAsset.axles = maxAxle;
    return fleetAsset.axles;
}

const toggleTrailerOrder = (shouldShow, trailerOrder) => {
    const trailerOrderLabel = document.getElementById('trailerorderlabel');
    const trailerOrderInput = document.getElementById('trailerorder');    
    if (shouldShow) {
        trailerOrderInput.value = trailerOrder;
        trailerOrderLabel.style.display = 'block';
        trailerOrderInput.style.display = 'block'; // Show the input
        trailerOrderInput.setAttribute('required', 'required'); // Make it required
    } else {
        trailerOrderLabel.style.display = 'none';
        trailerOrderInput.style.display = 'none'; // Hide the input
        trailerOrderInput.removeAttribute('required'); // Remove the required attribute
    }
}

function addSignalBooster() {
    const unitName = document.getElementById('unitname').value.trim();
    if (unitName) {
        const addSignalBoostersButton = document.getElementById('addsignalboostersbtn');
        addSignalBoostersButton.style.display = 'none';
        addSignalBoosterStep();
    } else {
        alert('Please enter a name for the unit.');
    }
}

function handleAddAxlesClick() {
    const unitName = document.getElementById('unitname').value.trim();
    if (unitName) {        
        // const configureAxlesButton = document.getElementById('configureaxlesbtn');
        // configureAxlesButton.style.display = 'none';
        addAxlesStep();
    } else {
        alert('Please enter a name for the unit.');
    }
}

function addSignalBoosterStep() {
    document.getElementById('addsignalboostersstep').classList.remove('d-none');
    addSignalBoosterRow();
}

function addAxlesStep() {

    document.getElementById('addaxlesstep').classList.remove('d-none');
    const numberOfAxles = parseInt(document.getElementById("numberofaxles").value);

        for (let i = 1; i <= numberOfAxles; i++) {
            const axleRow = addAxleRow(i);
            // Append axleRow to the container
            document.getElementById('axlescontainer').appendChild(axleRow);
        }
}

const addAxleRow = (axleNumber) => {

    const axleRow = document.createElement('div');
    axleRow.classList.add('row', 'axle-row', 'mt-2');
    axleRow.id = `axle-row-${axleNumber}`; // Assign a unique ID to the axle row

    // First column: Left Repeater Input (larger column)
    const leftLocationSensorDiv = document.createElement('div');
    leftLocationSensorDiv.classList.add('col-md-3');
    const leftLocationSensorInput = document.createElement('input');
    leftLocationSensorInput.type = 'text';
    leftLocationSensorInput.id = `leftlocationid-${axleNumber}`
    leftLocationSensorInput.classList.add('form-control', 'left-repeater');
    leftLocationSensorInput.placeholder = `Axle ${axleNumber} - Left Location Id`;
    leftLocationSensorDiv.appendChild(leftLocationSensorInput);

    // Second column: Left Wheel Type Select (smaller column)
        const leftWheelDiv = document.createElement('div');
        leftWheelDiv.classList.add('col-md-2');
        const leftWheelSelect = document.createElement('select');
        leftWheelSelect.id = `leftrepeatertype-${axleNumber}`;
        leftWheelSelect.classList.add('form-control', 'left-wheeltype');
        const leftSingleOption = document.createElement('option');
        leftSingleOption.value = 'Single';
        leftSingleOption.textContent = 'Single';
        const leftDoubleOption = document.createElement('option');
        leftDoubleOption.value = 'Double';
        leftDoubleOption.textContent = 'Double';
        leftWheelSelect.append(leftSingleOption, leftDoubleOption);
        leftWheelDiv.appendChild(leftWheelSelect);

    // Third column: Axle SVG Placeholder (takes remaining space)
    const axleSvgDiv = document.createElement('div');
    axleSvgDiv.classList.add('col-md-auto');
    axleSvgDiv.textContent = ' ';

    // Fourth column: Right Repeater Input (larger column)
    const rightLocationSensorDiv = document.createElement('div');
    rightLocationSensorDiv.classList.add('col-md-3');
    const rightLocationSensorInput = document.createElement('input');
    rightLocationSensorInput.type = 'text';
    rightLocationSensorInput.id = `rightlocationid-${axleNumber}`;
    rightLocationSensorInput.classList.add('form-control', 'right-repeater');
    rightLocationSensorInput.placeholder = `Axle ${axleNumber} - Right Location Id`;
    rightLocationSensorDiv.appendChild(rightLocationSensorInput);

    // Fifth column: Right Wheel Type Select (smaller column)
        const rightWheelDiv = document.createElement('div');
        rightWheelDiv.classList.add('col-md-2');
        const rightWheelSelect = document.createElement('select');
        rightWheelSelect.id = `rightrepeatertype-${axleNumber}`;
        rightWheelSelect.classList.add('form-control', 'right-wheeltype');
        const rightSingleOption = document.createElement('option');
        rightSingleOption.value = 'Single';
        rightSingleOption.textContent = 'Single';
        const rightDoubleOption = document.createElement('option');
        rightDoubleOption.value = 'Double';
        rightDoubleOption.textContent = 'Double';
        rightWheelSelect.append(rightSingleOption, rightDoubleOption);
        rightWheelDiv.appendChild(rightWheelSelect);

    // Append all elements to the axleRow
    axleRow.append(leftLocationSensorDiv, leftWheelDiv, axleSvgDiv, rightLocationSensorDiv, rightWheelDiv);

    return axleRow

}

function showAxlesConfig(fleetAsset, repeaters) {
    document.getElementById('addaxlesstep').classList.remove('d-none');

    // Populate axles based on fleetasset.axles and repeaters
    const lastAxle = false;
    for (let axle = 1; axle <= fleetAsset.axles; axle++) {
        const leftRepeater = repeaters.find(
            (rep) => rep.config.side === 'Left' && parseInt(rep.config.axle) === axle
        );
        const rightRepeater = repeaters.find(
            (rep) => rep.config.side === 'Right' && parseInt(rep.config.axle) === axle
        );
        const lastAxle = axle === fleetAsset.axles; 
        // Add row for this axle        
        addAxleConfigRow(axle, lastAxle, leftRepeater, rightRepeater);
    }
}

function addSignalBoosterRow(fleetAsset) {

    // Increment axle counter for the next row
    signalBoosterCounter++;
    const signalBoosterRow = document.createElement('div');
    signalBoosterRow.classList.add('row', 'signalbooster-row', 'mt-2');
    signalBoosterRow.id = `signalbooster-row-${signalBoosterCounter}`; // Assign a unique ID to the repeater row

    // Repeater Input
    const signalBoosterDiv = document.createElement('div');
    signalBoosterDiv.classList.add('col-md-3'); 
    const signalBoosterInput = document.createElement('input');
    signalBoosterInput.type = 'text';
    signalBoosterInput.classList.add('form-control'); 
    signalBoosterInput.placeholder = 'Add signal booster';
    signalBoosterDiv.appendChild(signalBoosterInput);

    const buttonDiv = document.createElement('div');
    buttonDiv.classList.add('col-md-1', 'd-flex', 'justify-content-center', 'align-items-center'); //,  'align-items-center'
    
    const addButton = document.createElement('button');
    addButton.type = 'button';
    addButton.classList.add('btn', 'btn-success', 'btn-block', 'w-100', 'add-signalbooster');
    addButton.id = `add-signalbooster-btn-${signalBoosterCounter}`; // Unique ID for the add button
    addButton.textContent = 'Add';
    buttonDiv.appendChild(addButton);

    // Repeater Input
    const placeHolderDiv = document.createElement('div');
    placeHolderDiv.classList.add('col-md-8');

    // Append all elements to the axleRow
    signalBoosterRow.append(signalBoosterDiv, buttonDiv, placeHolderDiv);

    // Append axleRow to the container
    document.getElementById('signalboosterscontainer').appendChild(signalBoosterRow);

    addButton.addEventListener('click', handleAddSignalBoosterClick);
}

function handleAddSignalBoosterClick() {
    console.log('Add Button clicked');
    // Change the previous row's add button to a remove button
    const lastRow = document.querySelector(`#signalbooster-row-${signalBoosterCounter}`); // The row before this one
    if (lastRow) {

        const lastButton = lastRow.querySelector('.add-signalbooster');
        if (lastButton) {
            // Convert to "Remove" button
            lastButton.classList.remove('btn-success', 'add-signalbooster');
            lastButton.classList.add('btn-danger', 'remove-repeater');
            lastButton.textContent = 'Remove';

            lastButton.removeEventListener('click', handleAddSignalBoosterClick);
            lastButton.addEventListener('click', () => removeSignalBoosterRow(null, lastButton));
        }
    }

    // Add the new repeater row
    addSignalBoosterRow();
}

const removeSignalBoosterRow = (fleetAsset, buttonElement) => {
    const rowId = buttonElement.id.split('-').pop();
    const rowToRemove = document.getElementById('signalbooster-row-' + rowId);

    if (rowToRemove) {
        // Get the value of the signal booster ID from the input field
        const signalBoosterInput = rowToRemove.querySelector('input[type="text"]');
        const signalBoosterId = signalBoosterInput ? signalBoosterInput.value : null;

        if (signalBoosterId && fleetAsset) {
            // Trigger API call to delete the signal booster ID
            deleteSignalBooster(fleetAsset.customerId, signalBoosterId)
                .then(() => {
                    // Remove the row from the DOM after successful deletion
                    rowToRemove.parentNode.removeChild(rowToRemove);
                    console.log(`Signal booster ID ${signalBoosterId} deleted successfully.`);
                })
                .catch((error) => {
                    console.error(`Failed to delete signal booster ID ${signalBoosterId}:`, error);
                });
        } else {
            rowToRemove.parentNode.removeChild(rowToRemove);
            console.warn("No signal booster ID found in the row to remove.");
        }
    } else {
        console.error("Row to remove not found");
    }
};


const addAxleConfigRow = (axleIndex, lastAxle, leftRepeaterData, rightRepeaterData) => {
    
    const axleRow = document.createElement('div');
    axleRow.classList.add('row', 'axle-row', 'mt-2');
    axleRow.id = `axle-row-${axleIndex}`; // Assign a unique ID to the axle row

    // First column: Left Repeater Input (larger column)
    const leftLocationSensorDiv = document.createElement('div');
    leftLocationSensorDiv.classList.add('col-md-3');
    const leftLocationSensorInput = document.createElement('input');
    leftLocationSensorInput.id = `leftlocationid-${axleIndex}`
    leftLocationSensorInput.type = 'text';
    leftLocationSensorInput.classList.add('form-control', 'left-repeater');
    leftLocationSensorInput.placeholder = `Axle ${axleIndex} - Left Location Id`;
    if (leftRepeaterData) {
        leftLocationSensorInput.value = leftRepeaterData.Id;
    }
    leftLocationSensorDiv.appendChild(leftLocationSensorInput);

    // Second column: Left Wheel Type Select (smaller column)
    const leftWheelDiv = document.createElement('div');
    leftWheelDiv.classList.add('col-md-2');
    const leftWheelSelect = document.createElement('select');
    leftWheelSelect.id = `leftrepeatertype-${axleIndex}`;
    leftWheelSelect.classList.add('form-control', 'left-wheeltype');
    const leftSingleOption = document.createElement('option');
    leftSingleOption.value = 'Single';
    leftSingleOption.textContent = 'Single';
    const leftDoubleOption = document.createElement('option');
    leftDoubleOption.value = 'Double';
    leftDoubleOption.textContent = 'Double';

    leftWheelSelect.append(leftSingleOption, leftDoubleOption);
    leftWheelDiv.appendChild(leftWheelSelect);

    if (leftRepeaterData) {
        leftWheelSelect.value = leftRepeaterData.config.wheel;
    }

    // Third column: Axle SVG Placeholder (takes remaining space)
    const axleSvgDiv = document.createElement('div');
    axleSvgDiv.classList.add('col-md-auto');
    axleSvgDiv.textContent = ' ';

    // Fourth column: Right Repeater Input (larger column)
    const rightLocationSensorDiv = document.createElement('div');
    rightLocationSensorDiv.classList.add('col-md-3');
    const rightLocationSensorInput = document.createElement('input');
    rightLocationSensorInput.id = `rightlocationid-${axleIndex}`;
    rightLocationSensorInput.type = 'text';
    rightLocationSensorInput.classList.add('form-control', 'right-repeater');
    rightLocationSensorInput.placeholder = `Axle ${axleIndex} - Right Location Id`;
    if (rightRepeaterData) {
        rightLocationSensorInput.value = rightRepeaterData.Id;
    }
    rightLocationSensorDiv.appendChild(rightLocationSensorInput);

    // Fifth column: Right Wheel Type Select (smaller column)
    const rightWheelDiv = document.createElement('div');
    rightWheelDiv.classList.add('col-md-2');
    const rightWheelSelect = document.createElement('select');
    rightWheelSelect.id = `rightrepeatertype-${axleIndex}`;
    rightWheelSelect.classList.add('form-control', 'right-wheeltype');
    const rightSingleOption = document.createElement('option');
    rightSingleOption.value = 'Single';
    rightSingleOption.textContent = 'Single';
    const rightDoubleOption = document.createElement('option');
    rightDoubleOption.value = 'Double';
    rightDoubleOption.textContent = 'Double';
    rightWheelSelect.append(rightSingleOption, rightDoubleOption);
    
    if (rightRepeaterData) {
        rightWheelSelect.value = rightRepeaterData.config.wheel;
    }
    rightWheelDiv.appendChild(rightWheelSelect);


    // Sixth column: Button container for add/remove buttons
    // Add the "Add Axle" button to the new row

    // Append all elements to the axleRow
    axleRow.append(leftLocationSensorDiv, leftWheelDiv, axleSvgDiv, rightLocationSensorDiv, rightWheelDiv);

    // Append axleRow to the container
    document.getElementById('axlescontainer').appendChild(axleRow);
}

function clearContainer(containerId) {
    const container = document.getElementById(containerId);
    while (container.firstChild) {
        container.removeChild(container.firstChild);
    }
}

function attachEvent(element, eventType, callback) {
    element.removeEventListener(eventType, callback);
    element.addEventListener(eventType, callback);
}