import { getUserData } from "./auth.js";

const baseUrl = 'https://cloud-connector-dashboard.restiolabs.com/api';

const { token } = getUserData() || {};

export async function updateTyreInfo(wheel) {
    try {        

        const requestBody = {
            externalTyreId: wheel.externalTyreId,
            tyreTreadDepth: parseFloat(wheel.tyreTreadDepth)
        };

        const apiUrl = `${baseUrl}/sensor/${wheel.sensorId}/info`;

        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating tyre info:", e);
    }
}

export async function updateTrailerName(sensor, trailerName) {

    const repeaterId = sensor.repeater.repeaterId;
    const apiUrl = `${baseUrl}/repeater/${repeaterId}/info`;

    const requestBody = {
        trailerName: trailerName
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'PUT', // Assuming it's a PUT request, change to POST if necessary
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        // Return true if the update was successful, else return false
        return response.ok;
    } catch (error) {
        console.error(`Error updating trailerName for repeaterId: ${repeaterId}`, error);
        return false; // Indicate that the update failed
    }
}

export async function getUnitSensorHistoryData(imei, date) {
    try {
 
        date = new Date(date);
        const timestamp = date.toISOString();

        const apiUrl = `${baseUrl}/unit/${imei}/sensor-historical?timestamp=${timestamp}`;
        
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: getUnitSensorHistory - ERROR ', e);
    }
}

export async function getStandAloneUnitSensorHistoryData(unitId, date) {
    try {
 
        date = new Date(date);
        const timestamp = date.toISOString();

        const apiUrl = `${baseUrl}/unit/standalone/Trailer/${unitId}/sensor-historical?timestamp=${timestamp}`;
        
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: getUnitSensorHistory - ERROR ', e);
    }
}

export async function getActionLogData(imei, wheelId) {
    try {

        const now = new Date();
        const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));
        const timestamp = sixMonthsAgo.toISOString();

        const apiUrl = `${baseUrl}/unit/${imei}/wheel/${wheelId}/action?startTimestamp=${timestamp}`;            

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: getActionLogData - ERROR ', e);
    }
}

export async function getStandAloneActionLogData(unitId, wheelId) {
    try {

        const now = new Date();
        const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));
        const timestamp = sixMonthsAgo.toISOString();

        const apiUrl = `${baseUrl}/unit/standalone/Trailer/${unitId}/wheel/${wheelId}/action?startTimestamp=${timestamp}`;            

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: getActionLogData - ERROR ', e);
    }
}

export async function updateActionLog(imei, wheelId, action) {
    try {

        const apiUrl = `${baseUrl}/unit/${imei}/wheel/${wheelId}/action`;
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ action: action })
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating action log:", e);
    }
}

export async function updateStandAloneActionLog(unitId, wheelId, action) {
    try {

        const apiUrl = `${baseUrl}/unit/standalone/Trailer/${unitId}/wheel/${wheelId}/action`;
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ action: action })
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating action log:", e);
    }
}

export async function fetchWheelSensorData(imei) {
    try {
        const apiUrl = `${baseUrl}/unit/${imei}/sensor`;

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: fetchWheelSensorData - ERROR ', e);
    }
}

export const fetchSensorHistoryData = async(imei, wheel, date) => {

    let apiUrl;      

    // Check if 'date' is a single date object or an array with two date objects
    if (Array.isArray(date) && date.length === 2) {
        apiUrl = `${baseUrl}/unit/${imei}/wheel/${wheel}/history?datapointInterval=1&startTimestamp=${date[0]}&endTimestamp=${date[1]}`;
    } else if (typeof date === "string") {
        //const dateString = date.toISOString().split('T')[0];
        apiUrl = `${baseUrl}/unit/${imei}/wheel/${wheel}/history?datapointInterval=1&startTimestamp=${date}`;
    } else {
        throw new Error('Invalid date format');    }

    try {
        const response = await fetch(apiUrl, {
           method: 'GET',
           headers: {
               'Authorization': `Bearer ${token}`,
               'Content-Type': 'application/json'
           },
           credentials: 'include' // Include cookies in the request
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

export const fetchSensorHistoryDataStandAlone = async(unitId, wheel, date) => {

    let apiUrl;      

    // Check if 'date' is a single date object or an array with two date objects
    if (Array.isArray(date) && date.length === 2) {        
        apiUrl = `${baseUrl}/unit/standalone/Trailer/${unitId}/wheel/${wheel}/history?datapointInterval=1&startTimestamp=${date[0]}&endTimestamp=${date[1]}`;
    } else if (typeof date === "string") {
        //const dateString = date.toISOString().split('T')[0];
        apiUrl = `${baseUrl}/unit/standalone/Trailer/${unitId}/wheel/${wheel}/history?datapointInterval=1&startTimestamp=${date}`;
    } else {
        throw new Error('Invalid date format');    }

    try {
        const response = await fetch(apiUrl, {
           method: 'GET',
           headers: {
               'Authorization': `Bearer ${token}`,
               'Content-Type': 'application/json'
           },
           credentials: 'include' // Include cookies in the request
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

export const fetchSensorSummaryData = async (imei, date) => {

    let apiUrl;

    // Check if 'date' is a single date object or an array with two date objects
    if (Array.isArray(date) && date.length === 2) {
        apiUrl = `${baseUrl}/unit/${imei}/sensor-summary?startTimestamp=${date[0]}&endTimestamp=${date[1]}`;
    } else if (typeof date === "string") {
        //const dateString = date.toISOString().split('T')[0];
        apiUrl = `${baseUrl}/unit/${imei}/sensor-summary?startTimestamp=${date}`;
    } else {
        throw new Error('Invalid date format');
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include' // Include cookies in the request
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching sensor summary data:', error);
        throw error;
    }
}


export const fetchSensorSummaryDataStandAlone = async (unitId, date) => {

    let apiUrl;

    // Check if 'date' is a single date object or an array with two date objects
    if (Array.isArray(date) && date.length === 2) {
        apiUrl = `${baseUrl}/unit/standalone/Trailer/${unitId}/sensor-summary?startTimestamp=${date[0]}&endTimestamp=${date[1]}`;
    } else if (typeof date === "string") {
        //const dateString = date.toISOString().split('T')[0];
        apiUrl = `${baseUrl}/unit/standalone/Trailer/${unitId}/sensor-summary?startTimestamp=${date}`;
    } else {
        throw new Error('Invalid date format');
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include' // Include cookies in the request
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching sensor summary data:', error);
        throw error;
    }
}

