import { formatZuluTime } from '../../../common/utils/timeformats.js';
import { formatCoordinate, getAddressFromCoordinates } from '../../../common/utils/coordinateformats.js';
import { updateTruck } from "../../../common/data/units.js";

let map, marker; // global variables for maps (leaflet)

function displayUnitInfo(selectedUnit) {

    let unitInfo = document.getElementById(`unitinfo${selectedUnit.unitId}`);
    if (unitInfo) {
        unitInfo.parentNode.removeChild(unitInfo);
        //while (unitInfo.hasChildNodes()) {
        //    unitInfo.removeChild(unitInfo.lastChild);
        //}
    }
    unitInfo = document.createElement('div');
    unitInfo.id = `unitinfo${selectedUnit.unitId}`;
    unitInfo.className = 'unitinfopane';
    unitInfo.canClose = true;
    unitInfo.onclick = function (e) {
        unitInfo.canClose = false;
        e.stopImmediatePropagation();
    }
    
    const unitInfoFragment = document.createDocumentFragment();

    const unitInfoDiv = createUnitInfoElement(selectedUnit);
    const unitDriverInfoDiv = createUnitDriverInfoElement(selectedUnit);
    const unitMapDiv = createUnitMapElement(); 

    unitInfoFragment.appendChild(unitInfoDiv);    
    unitInfoFragment.appendChild(unitDriverInfoDiv);  
    unitInfoFragment.appendChild(unitMapDiv);

    unitInfo.appendChild(unitInfoFragment);

    let displayUnit = document.getElementById(`unit${selectedUnit.unitId}`);
    displayUnit.appendChild(unitInfo);

    initialiseMap('unitmap');
    const location = {
        longitude: formatCoordinate(selectedUnit.location.longitude),
        latitude: formatCoordinate(selectedUnit.location.latitude)
    };
    showUnitOnMap(location);

    displayUnit.scrollIntoView();
}

function initialiseMap(mapDiv) {

    if (map) {
        map.remove(); // This removes the existing map and cleans up event listeners
        map = null; // Reset the map variable so it can be re-initialized
    }

    // create a new map instance
    map = L.map(mapDiv, {
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: 'topleft'
        }
    }).setView([-33.97823, 22.45808], 10);

    // add an OpenStreetMap tile layer
    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    setTimeout(() => {
        map.invalidateSize();
    }, 100);
}

function showUnitOnMap(pos) { 

    if (map) {
        const customIcon = L.divIcon({
            className: 'map-icon'
        });
        if (marker != null) {
            map.removeLayer(marker);
        }
        marker = L.marker({ lat: pos.latitude, lng: pos.longitude }, { icon: customIcon }).addTo(map);
        marker.setLatLng({ lat: pos.latitude, lng: pos.longitude });
        marker.setIcon(customIcon);
        map.invalidateSize();
        map.setView({ lat: pos.latitude, lng: pos.longitude });
        map.invalidateSize();
    }
}

function createAssignDriverButtonElement(unit) {

    let assignDriverButton = document.createElement('button');
    assignDriverButton.id = 'assigndriverbutton';
    assignDriverButton.type = 'button';
    let assignDriverButtonClasses = ['btn', 'btn-primary', 'btn-block'];
    assignDriverButton.classList.add(...assignDriverButtonClasses);
    if (!unit.driverName) assignDriverButton.innerText = "New Driver"; //"Assign Driver"
    else assignDriverButton.innerText = "Edit Driver";
    assignDriverButton.onclick = function (e) {
        e.stopImmediatePropagation();
        openDriverForm(unit);
    };

    return assignDriverButton;
}

function createUnitInfoElement(selectedUnit) {

    let unitInfoDiv = document.createElement('div');
    unitInfoDiv.id = `unitinfo${selectedUnit.unitId}`;
    unitInfoDiv.className = 'paneframe';

    let unitInfoContainerDiv = createUnitInfoContainerElements(selectedUnit);
    unitInfoDiv.appendChild(unitInfoContainerDiv);

    return unitInfoDiv;
}

function createUnitInfoContainerElements(selectedUnit) {
    let unitInfoContainerDiv = document.createElement('div');
    unitInfoContainerDiv.className = 'unitpanecontainer';

    let unitInfoHeaderDiv = createUnitInfoHeaderDiv(selectedUnit);
    unitInfoContainerDiv.appendChild(unitInfoHeaderDiv);

    const unitInfoDiv = createUnitInfoDiv(selectedUnit);
    unitInfoContainerDiv.appendChild(unitInfoDiv);

    // Start fetching the address asynchronously without blocking UI rendering
    updateAddressAsync(selectedUnit, unitInfoDiv, selectedUnit?.location?.latitude, selectedUnit?.location?.longitude);

    return unitInfoContainerDiv;
}

function createUnitInfoHeaderDiv(selectedUnit) {
    let unitInfoHeaderDiv = document.createElement('div');
    //let unitInfoHeaderDivClasses = ['unitinfoheader', 'editunitinfo'];
    const unitInfoHeaderDivClasses = ['unitinfoheader'];
    unitInfoHeaderDiv.classList.add(...unitInfoHeaderDivClasses);

    unitInfoHeaderDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        openEditUnitForm(selectedUnit);
    };

    let unitIcon = document.createElement('svg');
    unitIcon.className = 'trucktrailer-icon';

    let unitName = document.createElement('h6');
    unitName.innerText = selectedUnit.name;

    unitInfoHeaderDiv.appendChild(unitIcon);
    unitInfoHeaderDiv.appendChild(unitName);

    return unitInfoHeaderDiv;
}

function createUnitInfoDiv(selectedUnit) {
    const unitInfoDiv = document.createElement('div');
    unitInfoDiv.className = 'unitinfo';

    // Speed Info
    const unitSpeedDiv = document.createElement('div');
    unitSpeedDiv.id = `unitinfospeed${selectedUnit.imei}`;
    const unitSpeedLabel = document.createElement('b');
    unitSpeedLabel.innerText = "Speed: ";
    const unitSpeed = document.createTextNode(
        selectedUnit.location && selectedUnit.location.speed !== undefined 
            ? `${selectedUnit.location.speed} km/h` 
            : 'Not Available'
    );
    unitSpeedDiv.appendChild(unitSpeedLabel);
    unitSpeedDiv.appendChild(unitSpeed);
    unitInfoDiv.appendChild(unitSpeedDiv);

    // Location Info (with placeholder)
    const unitLocationDiv = document.createElement('div');
    unitLocationDiv.id = `unitinfolocation${selectedUnit.imei}`;
    const unitLocationLabel = document.createElement('b');
    unitLocationLabel.innerText = "Location: ";
    const unitLocationText = document.createTextNode("Loading location...");
    unitLocationDiv.appendChild(unitLocationLabel);
    unitLocationDiv.appendChild(unitLocationText);
    unitInfoDiv.appendChild(unitLocationDiv);

    // Last Message Info
    const unitLastMessageDiv = document.createElement('div');
    unitLastMessageDiv.id = `unitinfotimeupdated${selectedUnit.imei}`;
    const unitLastMessageLabel = document.createElement('b');
    unitLastMessageLabel.innerText = "Time Updated: ";
    //const unitLastMessageText = document.createTextNode(formatZuluTime(selectedUnit.location.recordTimestamp));
    const unitLastMessageText = document.createTextNode(
        selectedUnit.location && selectedUnit.location.recordTimestamp !== undefined 
            ? formatZuluTime(selectedUnit.location.recordTimestamp)
            : 'Not Available'
    );
    unitLastMessageText.id = 'unitinfodate';
    unitLastMessageDiv.appendChild(unitLastMessageLabel);
    unitLastMessageDiv.appendChild(unitLastMessageText);
    unitInfoDiv.appendChild(unitLastMessageDiv);

    return unitInfoDiv;
}

async function updateAddressAsync(selectedUnit, unitInfoDiv, latitude, longitude) {
    const unitLocationDiv = unitInfoDiv.querySelector(`#unitinfolocation${selectedUnit.imei}`);
    try {
        const address = await getAddressFromCoordinates(latitude, longitude);
        unitLocationDiv.childNodes[1].nodeValue = address;
    } catch (error) {
        unitLocationDiv.childNodes[1].nodeValue = "Not vailable";
        console.error('Error updating address:', error);
    }
}

const createUnitDriverInfoElement = (unit) => {
    
    const unitDriverInfoDiv = document.createElement('div');
    unitDriverInfoDiv.id = 'driverinfo';
    unitDriverInfoDiv.className = 'paneframe';
    if(unit.type === 'truck' || unit.unitType === 'TRUCK') {
        const unitDriverInfoContainerDiv = createUnitDriverInfoContainerElements(unit);
        unitDriverInfoDiv.appendChild(unitDriverInfoContainerDiv);
    }
    return unitDriverInfoDiv;
}

function createUnitDriverInfoContainerElements(unit) {

    const existingContainer = document.getElementById('driverinfocontainer');
    if (existingContainer) {
        existingContainer.remove(); // Remove the old container
    }

    const unitDriverInfoContainerDiv = document.createElement('div');
    unitDriverInfoContainerDiv.id = 'driverinfocontainer';
    unitDriverInfoContainerDiv.className = 'panecontainer';
    
    if (unit.driverName) {
        const driverInfoDiv = document.createElement('div');

        const driverNameHeader = document.createElement('div');
        driverNameHeader.id = `drivername${unit.unitId}`;
        driverNameHeader.className = 'drivername';
        driverNameHeader.innerText = unit.driverName || "";

        const driverMobileNoDiv = document.createElement('div');
        driverMobileNoDiv.id = `drivercontactno${unit.unitId}`;
        driverMobileNoDiv.className = 'drivermobile';
        driverMobileNoDiv.innerText = unit.driverContactNumber || "";

        driverInfoDiv.appendChild(driverNameHeader);
        driverInfoDiv.appendChild(driverMobileNoDiv);
        unitDriverInfoContainerDiv.appendChild(driverInfoDiv);

    }

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userRole = userData.role;
    if (userRole === 'Administrator'){  
        const assignDriverButtonElement = createAssignDriverButtonElement(unit);
        unitDriverInfoContainerDiv.appendChild(assignDriverButtonElement);
    }

    return unitDriverInfoContainerDiv;
}

function createUnitMapElement(unit) {

    let unitInfoMapDiv = document.createElement('div');
    unitInfoMapDiv.id = 'unitinfomap';
    unitInfoMapDiv.className = 'paneframe';

    let unitMapContainerDiv = createUnitMapContainerElement('unitmapcontainer', 'unitmap', 'unitmap', unit);
    unitInfoMapDiv.appendChild(unitMapContainerDiv);

    return unitInfoMapDiv;
}

function createUnitMapContainerElement(mapcontainer, mapDiv, mapDivClass) {

    let unitMapContainerDiv = document.createElement('div');
    unitMapContainerDiv.id = mapcontainer;
    unitMapContainerDiv.className = 'panecontainer';
    unitMapContainerDiv.onclick = function (e) {
        e.stopImmediatePropagation();
    };

    let unitMapDiv = document.createElement('div');
    unitMapDiv.id = mapDiv;
    unitMapDiv.className = mapDivClass;

    unitMapContainerDiv.appendChild(unitMapDiv);

    return unitMapContainerDiv;
}

function openDriverForm(unit) {

    document.body.style.cursor = 'wait';

    let newdrivermodal = document.getElementById('newdrivermodal');

    let span = document.getElementById("closenewdrivermodal");
    span.onclick = function () {
        newdrivermodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == newdrivermodal) {
            newdrivermodal.style.display = "none";
        }
    };

    document.body.style.cursor = 'default';

    let newdriverform = document.getElementById('newdriverform');
    newdriverform.onsubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        submitDriverForm(unit);
    }

    document.getElementById("newdriverform").reset();
    //document.getElementById('imei').value = unit.imei;
    document.getElementById("newdrivermodal").style.display = "block";
}

function openEditDriverForm(unit) {

    document.body.style.cursor = 'wait';

    let editdrivermodal = document.getElementById('editdrivermodal');

    let span = document.getElementById("closeeditdrivermodal");
    span.onclick = function () {
        editdrivermodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == editdrivermodal) {
            editdrivermodal.style.display = "none";
        }
    };

    let editdriverform = document.getElementById('editdriverform');
    editdriverform.onsubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        submitEditDriverForm(unit);
    }

    document.body.style.cursor = 'default';

    document.getElementById('editdrivername').value = unit?.driverName || "";
    document.getElementById('editdrivercontactno').value = unit?.driverContactNumber || "";

    document.getElementById("editdrivermodal").style.display = "block";
}

async function submitNewDriverForm() {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();
    document.getElementById("loadingBoxText").innerHTML = "Updating driver data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    let truck = {};
    truck.unitId= document.getElementById('newdrivertruckid').value;
    truck.driverName = document.getElementById('newdrivername').value;
    truck.driverContactNumber = document.getElementById('newdrivercontactno').value;
    //driver.icon = document.getElementById('driverIcon').get(0);
    //driver.icon = icon;
    const updatedDriver = updateDriver(truck);

    document.getElementById("newdrivermodal").style.display = "none";

    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");

    return false;
}

async function submitDriverForm(unit) {

    document.getElementById("loadingBoxText").innerHTML = "Updating driver data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });
    const truck = {};
    truck.Id = unit.unitId;
    truck.customerId = unit.customerId;
    const driverData = {};
    driverData.driverName = document.getElementById('newdrivername').value;
    driverData.driverContactNumber = document.getElementById('newdrivercontactno').value;

    const updatedUnitDriver = await updateTruck(truck, driverData);
    if (updatedUnitDriver) {
        const driverName = document.getElementById(`drivername${unit.unitId}`);
        if (driverName) {
            driverName.innerText = driverData.driverName;
            document.getElementById(`drivercontactno${unit.unitId}`).innerText = driverData.driverContactNumber;
        } else {
            const driverInfoDiv = document.getElementById('driverinfo');
            if(driverInfoDiv) {
                const unitDriverInfoContainerDiv = createUnitDriverInfoContainerElements(unit);
                driverInfoDiv.appendChild(unitDriverInfoContainerDiv);
            }
        }
    }

    document.getElementById("newdrivermodal").style.display = "none";
    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");

    return false;
}

function submitEditUnitForm() {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();

    document.getElementById("loadingBoxText").innerHTML = "Updating unit data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    let unit = {};
    unit.unitId = document.getElementById('eufUnitId').value;
    unit.name = document.getElementById('eufUnitName').value;

    document.getElementById("editunitmodal").style.display = "none";

    $('#container').overlayMask('hide');
    $("#loadingBox").modal("hide");
    document.body.style.cursor = 'default';

    return false;
}

async function editUnit(unit) {

    let updatedUnitName = await updateUnitName(unit);
    let updatedTrailerName = false;
    if (unit.trailerId > 0) {
        updatedTrailerName = await updateTrailerName(unit);
    } else {
        updatedTrailerName = true;
    }

    if (updatedUnitName && updatedTrailerName)
        updateUnitNameChangeNotification(unit);
    else
        console.log("pressuretrackJS: editUnit - UNIT NAME NOT UPDATED");
}


function openEditUnitForm(selectedUnit) {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();

    const editunitmodal = document.getElementById('editunitmodal');
    document.getElementById('editunitheader').innerText = "EDIT UNIT";
    document.getElementById('editunitlabel').innerText = "Unit Name";

    const closeEditUnit = document.getElementById("closeeditunitmodal");
    closeEditUnit.onclick = function () {
        editunitmodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == editunitmodal) {
            editunitmodal.style.display = "none";
        }
    };

    let editunitform = document.getElementById('editunitform');
    editunitform.addEventListener('submit', async function (e) {
        e.preventDefault();
        e.stopPropagation();
        submitEditUnitForm();
    });

    $('#container').overlayMask('hide');
    $("#loadingBox").modal("hide");
    document.body.style.cursor = 'default';

    document.getElementById('eufUnitId').value = selectedUnit.unitId;
    document.getElementById('eufUnitName').value = selectedUnit.name;
    if (selectedUnit.isTrailer)
        document.getElementById('eufTrailerId ').value = selectedUnit.trailerId;
    else
        document.getElementById('eufTrailerId').value = 0;

    document.getElementById("editunitmodal").style.display = "block";
}



export { displayUnitInfo, createUnitMapContainerElement, initialiseMap, showUnitOnMap };
                                                                              