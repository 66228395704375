import global from '../../common/utils/global.js';
import { WebSocketService } from '../../common/data/services/websocketservice.js';
import { logMessage } from '../../common/utils/general.js';
import { formatTime } from '../../common/utils/timeformats.js';
import { formatCoordinate, getAddressFromCoordinates } from '../../common/utils/coordinateformats.js';
import { deleteTruck, deleteTrailer } from '../../common/data/units.js';
import { fetchRepeaters } from '../../common/data/repeaters.js';
import { getImeiTruck, getUnits, getTrailers } from '../../common/data/handleunitdata.js';
import { deleteRepeaters } from '../../common/data/handlerepeaterdata.js'
import { displayMainPane } from './mainpage.js';
import { getUnitGroupName } from '../ui/components/unitgroupsview.js';
import { displayUnitInfo, showUnitOnMap } from '../ui/components/unitinfo.js';
import { openUnitConfigForm } from './components/unitconfig.js';
import { createDiagramView, displaySensorUpdates } from '../ui/diagrams/unitdiagrams.js';

const wsService = new WebSocketService();

export const displayDetailPanes = async () => {
    const units = await getUnits();
    let selectedUnit = global.selectedUnit;

    selectedUnit = units.find(u => u.unitId === selectedUnit.unitId) || 
               units.find(u => u.imei === selectedUnit.imei) || 
               units[0]; //unit's fresh data from getUnits
    global.selectedUnit = selectedUnit;

    getSensorUpdates(); //selectedUnit not always available, have to rely on global
    setDetailInterval(selectedUnit);
    displayUnitDetail('UNIT', units, selectedUnit);   
}

export const displayUnitsListTrailersOnly = async () => {
    try {
        
        const units = await getUnits();
        const trailers = [
            ...units.filter(unit => unit.unitType === 'TRAILER' || unit.type === 'trailer'), 
            ...units.flatMap(unit => unit.trailers || [])         
        ];

        let selectedUnit = global.selectedUnit;
        if (selectedUnit && selectedUnit.trailers) {
            selectedUnit = selectedUnit.trailers[0];
            global.selectedUnit = selectedUnit;
        }
        getSensorUpdates(); 
        setDetailInterval(selectedUnit);
        if (trailers.length > 0) {  
            //selectedUnit = units.find(u => u.unitId === selectedUnit.unitId);
            if(!selectedUnit) {
                selectedUnit = trailers[0];  
                global.selectedUnit = selectedUnit;
            }   
            displayUnitDetail('TRAILER', trailers, selectedUnit); 
        } else {
            let unitsListContainer = document.getElementById('unitslistcontainer');
            while (unitsListContainer.hasChildNodes()) {
                unitsListContainer.removeChild(unitsListContainer.lastChild);
            }
            let unitDiagrams = document.getElementsByClassName('unitbox');
            while (unitDiagrams.length > 0) {
                unitDiagrams[0].remove();
            }
            selectedUnit = null;
        }    
    }
    catch (e) {
        console.error("detailpageJS - displayUnitsListTrailersOnly: " + e.message);
        global.selectedUnit = null;
        return;
    }
}

export function displayTrailerDetail(trailers, selectedUnit) {

    document.body.style.cursor = 'wait';
    displayUnitsList('TRAILER', trailers, selectedUnit);    
    displayDiagramViews(selectedUnit);       
    // document.getElementById('detailspane').style.display = 'block';
    // global.showMainPage = false;
    // removeMainPane();
    document.body.style.cursor = 'default';    
}

export async function setDetailInterval(unit) {

    if (global.detailIntervalId) {
        clearInterval(global.detailIntervalId);
    }

    global.detailIntervalId = setInterval(async () => {
        const units = await getUnits();
        const selectedUnit = units.find(u => u.unitId === unit.unitId); //unit's fresh data from getUnits
        updateDisplayedUnit(selectedUnit).catch((error) => {
            //logMessage(`${error} Error detail in console`);
            console.log("detailpageJS: setDetailInterval - ERROR " + error + ")");
            console.error(error);
        });
    }, 300000); //5 minutes: 1 minute = 60,000 milliseconds; 5 minutes = 5 * 60000 = 300000 milliseconds;  
}

export function getSensorUpdates() {
    try {

        stopSensorUpdates();

        wsService.connect('sensorUpdates');

        let lastReceivedMessageId = null;
        wsService.addEventListener('message', (event) => {
            const data = event.detail;
            const unit = global.selectedUnit;
            // Ensuring messages are not duplicated
            if (data && data.imei === unit.imei) {
                const dataId = `${data.imei}${data.wheelSensorUID}${data.payloadTS}`
                if (dataId !== lastReceivedMessageId) { // Assuming 'id' or a unique field is available
                    lastReceivedMessageId = dataId;
                    console.log('Received message for detail page:', data);

                    displaySensorUpdates(unit, data);
                } else {
                    console.log('Duplicate message ignored:', data);
                }
            }
        });

        window.addEventListener('unload', () => {
            wsService.disconnect();
        });
    } catch (e) {
        console.error('detailpageJS (getSensorUpdates): WebSocket - ERROR: ', e);
    }
}

export function stopSensorUpdates() {
    try {

        wsService.removeListeners(); // Remove all event listeners
        wsService.disconnect(); // Ensure any previous connection is closed        

    } catch (e) {
        console.error('detailpageJS (stopSensorUpdates): WebSocket - ERROR: ', e);
    }
}

export async function updateDisplayedUnit(selectedUnit) {
    
    if (document.getElementById(`unitinfotimeupdated${selectedUnit.imei}`)) {        
        document.getElementById(`unitinfotimeupdated${selectedUnit.imei}`).innerText = `Time Updated: ${selectedUnit.timeUpdated}`;
        const address = await getAddressFromCoordinates(selectedUnit.location.latitude, selectedUnit.location.longitude);
        document.getElementById(`unitinfolocation${selectedUnit.imei}`).innerText = `Location: ${address}`;
        document.getElementById(`unitinfospeed${selectedUnit.imei}`).innerText = `Speed: ${selectedUnit.location.speed} km/h`;
        const location = {
            longitude: formatCoordinate(selectedUnit.location.longitude),
            latitude: formatCoordinate(selectedUnit.location.latitude)
        };
        showUnitOnMap(location);
    }

    const sensors = [...selectedUnit.wheelSensors, ...selectedUnit.spareSensors]
    for (let s = 0; s < sensors.length; s++) {

        const now = new Date();
        const currentTimeFormatted =
            `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;

        const payloadTS = sensors[s]?.payloadTS ? new Date(sensors[s].payloadTS) : null;

        // Format payloadTS in the same way as currentTimeFormatted
        const payloadTimeFormatted = payloadTS
            ? `${payloadTS.getHours().toString().padStart(2, '0')}:${payloadTS.getMinutes().toString().padStart(2, '0')}:${payloadTS.getSeconds().toString().padStart(2, '0')}`
            : currentTimeFormatted;

        if (document.getElementById(`sv${sensors[s].Id}timeupdated`)) {
            document.getElementById(`sv${sensors[s].Id}timeupdated`).innerText = payloadTimeFormatted;
        }
        if (document.getElementById(`sv${sensors[s].Id}sensorid`)) {
            document.getElementById(`sv${sensors[s].Id}sensorid`).innerText = sensors[s].id;
        }
        if (document.getElementById(`sv${sensors[s].Id}tyreid`)) {
            document.getElementById(`sv${sensors[s].Id}tyreid`).innerText = `${sensors[s].externalTyreId}`;
        }
        if (document.getElementById(`sv${sensors[s].Id}treaddepth`)) {
            document.getElementById(`sv${sensors[s].Id}treaddepth`).innerText = `${sensors[s].tyreTreadDepth} mm`;
        }
        //if (document.getElementById(`sv${sensors[s].id}signalstrength`)) {
        //    document.getElementById(`sv${sensors[s].id}signalstrength`).innerText = `${sensors[s].signalStrength}`;
        //}
        if (document.getElementById(`sv${sensors[s].Id}rotation`)) {
            document.getElementById(`sv${sensors[s].id}rotation`).innerText = Math.abs(parseInt(sensors[s].currentRotation)) < 1000 ? 'Stopped' : 'Rolling';
            //document.getElementById(`sv${sensors[s].Id}rotation`).innerText = `${sensors[s].imeiNumber}`;
        }
    }
}

function removeMainPane() {

    let unitsListMain = document.getElementById('unitslistmain');
    while (unitsListMain.hasChildNodes()) {
        unitsListMain.removeChild(unitsListMain.lastChild);
    }
    unitsListMain.style.display = "none";

    let mainPane = document.getElementById('mainpane');
    mainPane.style.display = "none";
}

export function displayUnitDetail(unitType, units, selectedUnit) {

    document.body.style.cursor = 'wait';
    displayUnitsList(unitType, units, selectedUnit);    
    displayDiagramViews(selectedUnit);       
    document.getElementById('detailspane').style.display = 'block';
    global.showMainPage = false;
    removeMainPane();
    document.body.style.cursor = 'default';    
}

function displayUnitsList(unitType, units, selectedUnit) {

    try {
        let unitsListDiv = document.getElementById('unitslist');
        while (unitsListDiv.hasChildNodes()) {
            unitsListDiv.removeChild(unitsListDiv.lastChild);
        }

        let unitsListFragment = document.createDocumentFragment();

        let displayUnits = [...units]; 
        if (!global.mainUnitsListSelected) {
            displayUnits = global.selectedGroupUnits;
            let groupNameDiv = document.createElement('div');
            let selectedGroupName = getUnitGroupName();
            groupNameDiv.id = selectedGroupName;
            let groupNameDivClasses = ['unitgroup', 'unitgroupcenter'];
            groupNameDiv.classList.add(...groupNameDivClasses);

            groupNameDiv.innerHTML = selectedGroupName;
            unitsListFragment.appendChild(groupNameDiv);

            groupNameDiv.onclick = (e) => {
                e.stopImmediatePropagation();
                global.mainUnitsListSelected = false;
                displayMainPane(units);  //***dalk eerder main() hier roep;
                //main();
            };
        }

        let unitsListContainerDiv = createUnitsListContainer();
        unitsListFragment.appendChild(unitsListContainerDiv);
        unitsListDiv.appendChild(unitsListFragment);

        // let selectedUnitInfoDisplayed = true;
        let unitDivClassAdded = false;
        for (let i = 0; i < displayUnits.length; i++) {

            let u = displayUnits[i];

            //const isGroupUnit = false;
            //let unitDiv = createUnitContainer(u, uwt, isGroupUnit);
            let unitDiv = createUnitContainer(unitType, u);
            unitsListContainerDiv.appendChild(unitDiv);
            
            if (selectedUnit && ((unitType !== 'TRAILER' && selectedUnit.unitId && selectedUnit.unitId === u.unitId)) || selectedUnit.unitId === u.unitId) {                
                unitDiv.classList.add('selectedunit');
                unitDiv.selected = true; 
                if (selectedUnit.imei ) { 
                    displayUnitInfo(selectedUnit);
                }
            }

            if (selectedUnit && unitType === 'TRAILER' && selectedUnit.imei && selectedUnit.trailers) {
                for (let trailer of selectedUnit.trailers) {
                    if (!unitDivClassAdded && trailer.Id === u.Id) {
                        selectedUnit = u;
                        global.selectedUnit = selectedUnit;
                        unitDiv.classList.add('selectedunit');
                        unitDivClassAdded = true
                        unitDiv.selected = true;
                        displayUnitInfo(selectedUnit);
                        break; // Exit the loop once a match is found
                    }
                }
            }

            unitDiv.onclick = async (e) => {
                e.stopImmediatePropagation();
                

                const selectedUnitId = u.unitId;
            
                // Get the div for the currently selected unit's info
                let currentUnitInfoPaneDiv = document.getElementById(`unitinfo${selectedUnitId}`);
                console.log("Selected Unit:", selectedUnitId, "Previous Unit:", global?.selectedUnit?.unitId || 'None');
            
                // If there is already a selected unit and it's different from the current unit
                if (global?.selectedUnit?.unitId && global?.selectedUnit?.unitId !== selectedUnitId) {
                    // Close the previously selected unit's info pane
                    let prevUnitInfoPaneDiv = document.getElementById(`unitinfo${global.selectedUnit.unitId}`);
                    if (prevUnitInfoPaneDiv && prevUnitInfoPaneDiv.hasChildNodes()) {
                        while (prevUnitInfoPaneDiv.hasChildNodes()) {
                            prevUnitInfoPaneDiv.removeChild(prevUnitInfoPaneDiv.lastChild);
                        }
                    }
                }
            
                // Toggle current unit's info: if open, close it; if closed, open it
                if (currentUnitInfoPaneDiv && currentUnitInfoPaneDiv.hasChildNodes()) {
                    // Close the current unit's info pane
                    while (currentUnitInfoPaneDiv.hasChildNodes()) {
                        currentUnitInfoPaneDiv.removeChild(currentUnitInfoPaneDiv.lastChild);
                    }
                } else {
                    // Open the current unit's info pane by displaying its details
                    if (u.unitId) displayUnitInfo(u);
                }
            
                // Update the global state with the currently selected unit's ID
                //global.selectedUnit.unitId = selectedUnitId;

            
                // Ensure the 'selectedunit' class is only applied to the current selected unit
                let selectedUnitContainers = document.getElementsByClassName('selectedunit');
                if (selectedUnitContainers.length > 0) {
                    selectedUnitContainers[0].classList.remove('selectedunit');
                }
            
                // Mark the current unit as the selected unit
                unitDiv.classList.add('selectedunit');
           
                const units = await getUnits();
                selectedUnit = units.find(unit => unit.unitId === u.unitId);  //unit's fresh data from getUnits
                if (!selectedUnit) selectedUnit = u; //Trailer view = selected trailer might not be part of units
                global.selectedUnit = selectedUnit;
            
                // Additional logic for displaying diagrams or other unit-related data
                displayDiagramViews(selectedUnit);
                updateDisplayedUnit(selectedUnit);
                getSensorUpdates(); //selectedUnit not always available, have to rely on global
                setDetailInterval(selectedUnit);
            };
        }
        
        $("#loadingBox").modal('hide');
        document.body.style.cursor = 'default';
    }
    catch (e) {
        console.error(`detailpageJS - displayUnitsList: ${e}`);
        return;
    }
}

export function displayDiagramViews(unit) {
    createDiagramView(unit, 'WHEELSDIAGRAM', 'rightpane');
    if (!global.sensorValuesDiagramDateSelected) {
        createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
    }
}

const createUnitContainer = (unitType, unit) => {

    try {
        let unitDiv = document.createElement('div');
        //if (isGroupUnit) unitDiv.id = `groupunit${unit.imei}`
        //else unitDiv.id = unit.imei;
        unitDiv.id = `unit${unit.unitId}`;
        unitDiv.className = 'unit';
        unitDiv.selected = false;
        //unitDiv.classList.add('selectunit');

        const unitHeaderDiv = document.createElement('div');
        unitHeaderDiv.className = 'unitheader';
        unitDiv.appendChild(unitHeaderDiv);

        const listIcon = document.createElement('svg');
        listIcon.className = 'trucktrailer-listicon';
        unitHeaderDiv.appendChild(listIcon);

        const unitTextSpan = document.createElement('span');
        unitTextSpan.id = `unitheadername${unit.unitId || unit.imei}`;
        unitTextSpan.innerText = unit.name || unit.imei; // Set initial text
        unitHeaderDiv.appendChild(unitTextSpan);

        //Metric flags
        const metricFlagsSpan = document.createElement("span");
        metricFlagsSpan.className = "listspanicons";
        unitHeaderDiv.appendChild(metricFlagsSpan);        

        if (unit.yellowFlags > 0) {
            let vspan = document.createElement("span");
            let vtxt = document.createTextNode("V");
            vspan.className += "yellowflag";
            vspan.appendChild(vtxt);
            metricFlagsSpan.appendChild(vspan);
        }
        if (unit.orangeFlags > 0) {
            let tspan = document.createElement("span");
            let ttxt = document.createTextNode("T");
            tspan.className += "orangeflag";
            tspan.appendChild(ttxt);
            metricFlagsSpan.appendChild(tspan);
        }
        if (unit.blinkFlags > 0) {
            let pspan = document.createElement("span");
            let ptxt = document.createTextNode("P");
            pspan.className += "blinkflag";
            pspan.appendChild(ptxt);
            metricFlagsSpan.appendChild(pspan);
        } else {
            if (unit.redFlags > 0) {
                let pspan = document.createElement("span");
                let ptxt = document.createTextNode("P");
                pspan.className += "redflag";
                pspan.appendChild(ptxt);
                metricFlagsSpan.appendChild(pspan);
            }
        }
        if (unit.purpleFlags > 0) {
            let pspan = document.createElement("span");
            let ptxt = document.createTextNode("P");
            pspan.className += "purpleflag";
            pspan.appendChild(ptxt);
            metricFlagsSpan.appendChild(pspan);
        }
        if (unit.grayFlags > 0) {
            let ospan = document.createElement("span");
            let otxt = document.createTextNode("S");
            ospan.className += "blueflag";
            ospan.appendChild(otxt);
            metricFlagsSpan.appendChild(ospan);
        }

        const userData = JSON.parse(localStorage.getItem('userData'));
        const userRole = userData.role;

        if (userRole === 'Administrator') {
            const unitEditBtn = document.createElement("button");
            unitEditBtn.classList.add('btn-inline', 'btn-edit-inline');        
            unitEditBtn.textContent = 'Edit';
            unitEditBtn.id = unit.unitId ? `edit${unit.unitId}btn` : (unit.imei ? `edit${unit.imei}btn` : 'editunitbtn');
            metricFlagsSpan.appendChild(unitEditBtn);
            unitEditBtn.onclick = async (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();
                let repeaters = [];
                if (unit.imei  && !unit.trailerNumber) {
                    const truck = await getImeiTruck(unit);
                    repeaters = await fetchRepeaters(truck);         
                    openUnitConfigForm(unit, truck, 'TRUCK', repeaters); 
                } else {
                    repeaters = await fetchRepeaters(); 
                    try {
                        repeaters = repeaters.filter(
                            repeater => repeater?.unitName && unit?.name && repeater?.unitName === unit?.name && repeater?.unitType === 'Trailer'
                        );
                    } catch (error) {
                        console.error('Error while filtering repeaters:', error);
                        // or handle the fallback logic here
                    }                
                    openUnitConfigForm(null, unit, "TRAILER", repeaters);
                }
                
            };        

            const unitDeleteBtn = document.createElement("button");
            unitDeleteBtn.classList.add('btn-inline', 'btn-delete-inline');    
            unitDeleteBtn.textContent = 'Delete';
            unitDeleteBtn.id = unit.unitId ? `delete${unit.unitId}btn` : (unit.imei ? `delete${unit.imei}btn` : 'deleteunitbtn');
            metricFlagsSpan.appendChild(unitDeleteBtn);
            
            unitDeleteBtn.onclick = async (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();
            
                let message = 'Are you sure you want to delete this unit?<br><br>';
                let truck, repeaters = [];
                if (unit.imei  && !unit.trailerNumber) {
                    truck = await getImeiTruck(unit);
                    if (truck) {
                        message += 'Before deleting a truck, make sure to contact your supplier and cancel your subscription.  If not, you will still be billed for the unit.  ';
                        message += 'Once you have canceled your subscription, you can proceed with deleting the truck.<br><br>';
                        repeaters = await fetchRepeaters(truck);
                        if (repeaters.length > 0) {
                            message += 'Please note that this truck has linked trailers that will not be deleted.<br><br>';                    
                        }
                        message += 'Do you want to continue?';
                    } else {
                        message = 'The Truck for this IMEI number is not defined yet.';
                        const confirmDeleteBtn = document.getElementById('confirmdeletebtn');
                        confirmDeleteBtn.disabled = true;
                    }
                }
            
                document.getElementById('deleteconfirmationmessage').innerHTML = message;
                const deleteConfirmationModal = new bootstrap.Modal(document.getElementById('deleteconfirmationmodal'));
                deleteConfirmationModal.show();
            
                document.getElementById('confirmdeletebtn').onclick = async () => {
                    deleteConfirmationModal.hide();
                    try {
                        await deleteUnitAndRepeaters(unit, repeaters);
                        //remove unit from UI
                        displayDetailPanes();
                    } catch (error) {
                        console.error('Error while deleting fleet asset and repeaters:', error);
                        // Handle the error appropriately
                }
                };
            };
        }
        return unitDiv;
    } catch (e) {
        console.log("unitgroupjs - createUnitContainer: " + e.error);
        return;
    }
}
  
async function deleteUnitAndRepeaters(unit, repeaters) {
    if (unit.imei  && !unit.trailerNumber) {
        await deleteTruck(unit.customerId, unit.unitId);
        await deleteRepeaters(unit.customerId, repeaters);
    } else {
        repeaters = await fetchRepeaters();
        repeaters = repeaters.filter(
            repeater => repeater?.unitName && unit?.name && repeater?.unitName === unit?.name && repeater?.unitType === 'Trailer'
        );
        await deleteTrailer(unit.customerId, unit.unitId);
        await deleteRepeaters(unit.customerId, repeaters);
    }
}

const createUnitsListContainer = () => {

    let unitsListContainerDiv = document.createElement('div');
    unitsListContainerDiv.id = 'unitslistcontainer';
    unitsListContainerDiv.className = 'unitslistcontainer';
    if (!global.mainUnitsListSelected) {
        unitsListContainerDiv.classList.add('unitsgrouplistcontainer');
    }

    return unitsListContainerDiv;
}