import { getUserData } from "./auth.js";

const baseUrl = 'https://cloud-connector-dashboard.restiolabs.com/api';

const { token, parentCustomerId } = getUserData() || {};

export const getUnitGroupData = async () => {
    try {

        // const token = localStorage.getItem('token');
        // const tokenData = getTokenData(token);

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${parentCustomerId}/unit-group`;

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error('getunitgroupdataJS: getUnitGroupData - ERROR ', e);
    }
}

export async function getUnitGroupUnits(unitGroup) {
    try {

        //const token = localStorage.getItem('token');
        //const tokenData = getTokenData(token);

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${unitGroup.customerId}/unit-group/${unitGroup.unitGroupId}`;

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        console.error('getunitgroupdataJS: getUnitGroupData - ERROR ', e);
    }
}

export async function updateUnitGroupUnits(unitGroup, units) {
    try {
        //const token = localStorage.getItem('token');
        //const tokenData = getTokenData(token);

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${unitGroup.customerId}/unit-group/${unitGroup.unitGroupId}`;

        const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ units })
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Bad network response: ${response.status} - ${errorText}`);
        }

        const data = await response.json();
        return data;

    } catch (e) {
        console.error('unitgroupdataJS: updateUnitGroup - ERROR', e);
        throw e;  // Re-throw the error for higher-level handling if necessary
    }
}

export async function createUnitGroup(unitGroupName, units) {  // Accept `units` as a parameter
    try {
        // const token = localStorage.getItem('token');
        // const tokenData = getTokenData(token);
        const customer = "66d01e14612839b73ad70439"
        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${parentCustomerId}/unit-group`;
        //const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${customer}/unit-group`;

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ unitGroupName, units })
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Bad network response: ${response.status} - ${errorText}`);
        }

        const data = await response.json();
        return data;

    } catch (e) {
        console.error('unitgroupdataJS: createUnitGroup - ERROR', e);
        throw e;  // Re-throw the error for higher-level handling if necessary
    }}

export async function updateUnitGroupName(unitGroup, unitGroupName) { 
    try {
        // const token = localStorage.getItem('token');
        // const tokenData = getTokenData(token);
        
        //const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${tokenData.decodedToken.user.customerId}/unit-group/${unitGroupId}`;
        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${unitGroup.customerId}/unit-group/${unitGroup.unitGroupId}`;
        
        const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ unitGroupName })
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Bad network response: ${response.status} - ${errorText}`);
        }

        const data = await response.json();
        return data;

    } catch (e) {
        console.error('unitgroupdataJS: updateUnitGroup - ERROR', e);
        throw e;  // Re-throw the error for higher-level handling if necessary
    }
}

export async function deleteUnitGroup(unitGroupId) {  // Accept `units` as a parameter
    try {
        // const token = localStorage.getItem('token');
        // const tokenData = getTokenData(token);

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/${parentCustomerId}/unit-group/${unitGroupId}`;

        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Bad network response: ${response.status} - ${errorText}`);
        }

        const data = await response.json();
        return data;

    } catch (e) {
        console.error('unitgroupdataJS: deleteUnitGroup - ERROR', e);
        throw e;  // Re-throw the error for higher-level handling if necessary
    }
}
