import { SPARE_AXLE, UNPAIRED_AXLE } from "../utils/global.js";

import { fetchSensorHistoryData, fetchSensorHistoryDataStandAlone, fetchSensorSummaryData, fetchSensorSummaryDataStandAlone } from "./sensors.js";

export const getUnitStatuses = (units) => {

    const unitStatuses = {};

    let unitTotal = units.length;

    for (let u = 0; u < units.length; u++) {
        //if (units[u].trailers.length) unitTotal = unitTotal + units[u].trailers.length;
    }

    unitStatuses.unitTotal = unitTotal;

    let totalSensorValues = 0;
    let redStatus = 0;
    let purpleStatus = 0;
    let blueStatus = 0;

    for (let unit = 0; unit < units.length; unit++) {
        totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].sensors.length);

        if (units[unit].linkedredFlags > 0) {
            redStatus = parseInt(redStatus) + parseInt(units[unit].linkedredFlags);
        }
        if (units[unit].linkedpurpleFlags > 0) {
            purpleStatus = parseInt(purpleStatus) + parseInt(units[unit].linkedpurpleFlags);
        }

        if (units[unit].linkedblueFlags > 0) {
            blueStatus = parseInt(blueStatus) + parseInt(units[unit].linkedblueFlags);
        }

        // if (units[unit].trailers.length > 0) {
        //     for (let t = 0; t < units[unit].trailers.length; t++) {
        //         totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].trailers[t].sensors.length);
        //     }
        // }
    }

    unitStatuses.totalSensorValues = totalSensorValues; 
    unitStatuses.greenStatus = parseInt(totalSensorValues) - parseInt((parseInt(redStatus) + parseInt(purpleStatus) + parseInt(blueStatus)));
    unitStatuses.redStatus = redStatus;
    unitStatuses.purpleStatus = purpleStatus;
    unitStatuses.blueStatus = blueStatus;

    unitStatuses.percCorrectPressure = Math.round(unitStatuses.greenStatus / totalSensorValues * 100);
    unitStatuses.percUnderInflated = Math.round(unitStatuses.redStatus / totalSensorValues * 100);
    unitStatuses.percOverInflated = Math.round(unitStatuses.purpleStatus / totalSensorValues * 100);
    unitStatuses.percNoSignal = Math.round(unitStatuses.blueStatus / totalSensorValues * 100);

    return unitStatuses;

}

export const getWheelProperties = (sensors) => {

    try { 
        if (sensors.length > 0) {
            for (let s = 0; s < sensors.length; s++) {
                sensors[s].wheelType = "wheelactive";
                sensors[s].sensorType = "tyre-correct";
                const { wheelId } = sensors[s]; // Destructure for cleaner access
                const axle = parseInt(wheelId.slice(1,3));

                if (axle !== SPARE_AXLE && axle !== UNPAIRED_AXLE) {
                    // Handle non-spare and non-unpaired axles
                    sensors[s].tyreName = `A${wheelId.slice(1, 3).replace(/^0+/, "")}-T${wheelId.slice(0, 1)}`;
                } else {
                    // Handle spare and unpaired axles
                    const prefix = axle === SPARE_AXLE ? 'S' : 'U';
                    sensors[s].tyreName = `${prefix}-${wheelId.slice(0, 1)}`;
                }
                sensors[s].pressureLabelColour = "green";
                sensors[s].temperatureLabelColour = "green";
                sensors[s].voltageLabelColour = "green";
                sensors[s].rotationLabelColour = "green";
                sensors[s].noSignalLabelColour = "green";

                if (sensors[s].currentPressure == null && sensors[s].currentTemperature == null && sensors[s].currentVoltage == null) {
                    sensors[s].sensorType = "tyre-nosignal";
                    sensors[s].noSignalLabelColour = "blue";
                }

                if (!sensors[s].signal) {
                    sensors[s].sensorType = "tyre-nosignal";
                    sensors[s].noSignalLabelColour = "blue";
                }

                if (parseFloat(sensors[s].currentPressure) > parseFloat(sensors[s].maxPressure)) {
                    sensors[s].sensorType = "tyre-purple";
                    sensors[s].pressureLabelColour = "purple";
                }

                if (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].minPressure)) {
                    sensors[s].sensorType = "tyre-red";
                    if (parseInt(sensors[s].wheelId.slice(1, 3)) !== SPARE_AXLE && (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].recommendedPressure) * 20/100)) {
                        sensors[s].sensorType = "tyre-blink"; 
                    }
                    sensors[s].pressureLabelColour = "red";
                }

                if (parseInt(sensors[s].currentTemperature) > parseInt(sensors[s].maxTemperature)) {
                    if (sensors[s].sensorType !== "tyre-red") {
                        sensors[s].sensorType = "tyre-orange";
                    }
                    sensors[s].temperatureLabelColour = "orange";
                }

                if (parseFloat(sensors[s].currentVoltage) < parseFloat(sensors[s].minVoltage)) {
                    if (sensors[s].sensorType !== "tyre-purple" && sensors[s].sensorType !== "tyre-red" && sensors[s].sensorType !== "tyre-orange") {
                        sensors[s].sensorType = "tyre-yellow";
                    }
                    sensors[s].voltageLabelColour = "yellow";
                }

                if (Math.abs(parseInt(sensors[s].currentRotation)) < 1000) {
                    sensors[s].rotationText = 'Stopped';
                    sensors[s].rotationLabelColour = 'green';
                } else {
                    sensors[s].rotationText = 'Rolling';
                    sensors[s].rotationLabelColour = 'signalgreen';   
                }
            }
        }

        return sensors;

    } catch (e) {        
        console.error('unitsJS: getWheelProperties - ERROR: ', e);
    }
}

export const getTyreArrayIndex = (tyre, axleSensors) => {

    let tyreArrayIndex = 0;
    for (let s = 0; s < axleSensors.length; s++) {
        if (parseInt(axleSensors[s].wheelId.slice(0, 1)) === tyre) {
            tyreArrayIndex = s;
            break;
        }
    }
    return tyreArrayIndex;
}

export const getSensorSummaryData = async(unit, date) => {

    if (unit.wheelSensors.length === 0) return [];
    
    const results = unit.unitType === 'TRAILER' ? await fetchSensorSummaryDataStandAlone(unit.unitId, date) : await fetchSensorSummaryData(unit.imei, date);

    return await new Promise((resolve, reject) => {

        if (results.data.length) {
            //const data = filterObjectsByTimestamp(results.data);
            const data = results.data;
            let sensorSummaryValues = [];
            for (let s = 0; s < data.length; s++) {
                const sensorSummary = {};
                //sensorSummary.imei = unit.imei;
                //sensorSummary.unitNumber = data[s].wheelId.slice(-1);
                sensorSummary.wheelId = data[s].wheelId;
                //sensorSummary.axle = parseInt(data[s].wheelId.slice(1, 3));
                //sensorSummary.wheelAxle = data[s].wheelId.slice(0, -1);
                sensorSummary.minPressure = data[s].minPressure;
                sensorSummary.maxPressure = data[s].maxPressure;
                //sensorSummary.recommendedPressure = wheel.recommendedPressure;
                sensorSummary.maxTemperature = data[s].maxTemperature;
                sensorSummary.minVoltageValue = data[s].minVoltage;
                sensorSummaryValues.push(sensorSummary);
            }

            if (sensorSummaryValues.length) {
                resolve(sensorSummaryValues);
            } else {
                reject(new Error(`Whoops!`));
            }
        }

    }).catch(alert);
}

export const getSensorHistoryData = async(unit, wheel, date) => {

    if (typeof variable === 'object' && variable !== null && !Array.isArray(variable)) {
        // variable is an object and not a string or array
    }

    const wheelLocation = typeof wheel === 'object' && wheel !== null ? wheel.wheelId : wheel;
    const results = unit.unitType === 'TRAILER' ? await fetchSensorHistoryDataStandAlone(unit.unitId, wheelLocation, date) : await fetchSensorHistoryData(unit.imei, wheelLocation, date);

    return await new Promise((resolve, reject) => {

        if (results.data.length) {
            //const data = filterObjectsByTimestamp(results.data);
            const sensors = wheelLocation.slice(1, 3) === SPARE_AXLE.toString() ? unit.spareSensors : unit.wheelSensors;
            const data = results.data;
            let sensorValues = [];
            for (let s = 0; s < data.length; s++) {
                const sensorValue = {};
                sensorValue.imei = unit.imei;
                sensorValue.unitNumber = wheelLocation.slice(-1);
                sensorValue.sensorId = data[s].sensorId;
                sensorValue.wheelId = wheelLocation;
                sensorValue.axle = parseInt(wheelLocation.slice(1, 3));
                sensorValue.wheelAxle = wheelLocation.slice(1);
                sensorValue.minPressure = sensors.find(s => s.wheelId === wheelLocation)?.minPressure || wheel.minPressure || 0;
                sensorValue.maxPressure = sensors.find(s => s.wheelId === wheelLocation)?.maxPressure || wheel.maxPressure || 0;
                sensorValue.recommendedPressure = sensors.find(s => s.wheelId === wheelLocation)?.recommendedPressure || wheel.recommendedPressure || 0;
                sensorValue.maxTemperature = sensors.find(s => s.wheelId === wheelLocation)?.maxTemperature || wheel.maxTemperature || 0;
                sensorValue.minVoltageValue = sensors.find(s => s.wheelId === wheelLocation)?.minVoltage || wheel.minVoltage || 0;
                sensorValue.unixTime = new Date(data[s].payloadTS).getTime() / 1000;

                const apiTimestamp = data[s].payloadTS;           
                const entryTime = new Date(apiTimestamp);
                const startTime = new Date(Date.UTC(2024, 6, 4, 7, 0, 0)); // Note: month is zero-based, so July is 6
                const endTime = new Date(Date.UTC(2024, 6, 4, 8, 0, 0));

                // Check if the entry's timestamp is within the range
                //if (entryTime >= startTime && entryTime < endTime) {
                //    console.log(`${entryTime} ${data[s].pressure} bar`);
                //} 

                sensorValue.time = data[s].payloadTS;
                sensorValue.posx = data[s].pos?.x || 0;
                sensorValue.posy = data[s].pos?.y || 0;
                sensorValue.pressure = (parseFloat(data[s].pressure)).toFixed(2);
                sensorValue.temperature = parseInt(data[s].temperature);
                sensorValue.voltage = parseFloat(data[s].voltage);
                sensorValues.push(sensorValue);

            }

            if (sensorValues.length) {
                sensorValues.sort((a, b) => a.unixTime - b.unixTime);     
                resolve(sensorValues);
            } else {
                reject(new Error(`Whoops! getSensorHistoryData`));
            }
        }

    }).catch(alert);
}