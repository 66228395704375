/* Psalm 127:1-2
As die HERE die huis nie bou nie, tevergeefs werk die wat daaraan bou;
as die HERE die stad nie bewaar nie, tevergeefs waak die wagter.
Tevergeefs dat julle vroeg opstaan, laat opbly, brood van smarte eet —
net so goed gee Hy dit aan sy beminde in die slaap! */

/*“Walking on water and developing software from a specification
 are easy if both are frozen”*/

export const PRIMARY_COLOUR = '#185785';
export const ACCENT_COLOUR = '#aece25';
export const LOW_PRESSURE_COLOUR = '#ad0505';
export const HIGH_PRESSURE_COLOUR = '#320e75';
export const HIGH_TEMPERATURE_COLOUR = '#ff7e25';
export const LOW_VOLTAGE_COLOUR = '#ffce00';

export const SPARE_AXLE = 17;
export const UNPAIRED_AXLE = 18;

export default {
    token: null,
    customerId: null,
    mainIntervalId: 0,
    units: [],
    selectedUnit: {},

    //desktop
    detailIntervalId: 0,
    selectedUnitId: 0,
    unitGroups: [],
    selectedGroupUnits: [],
    selectedGroupId: 0,
    repeaters:[],
    showMainPage: false,
    sensorValuesDiagramDateSelected: null,
    unitsGroupsEventsRegistered: false,
    mainUnitsListSelected: true,

    selectedMetric: "PRESSURE",
    pressureMetricAttributesUpdated: false,
    sensorValuesChartMessages: [],
    isProcessingChartMessages: false,
    sensorValuesDiagramDateSelected: false,
    selectedDateTime: "",

    //mobile
    currentView: 'UNITSLIST',
    wheelReport: false
    
}

