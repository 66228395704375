import global from '../../../common/utils/global.js'
import { getTyreArrayIndexTest } from '../diagrams/unitdiagrams.js'
import { JExcel } from '../../../common/thirdparty/excelexport/jsegarraexcel.js';
import { formatDateTime } from '../../../common/utils/timeformats.js'

var gSheetRowIndex;
var gSheetPages;

function randomDate(start, end) {
	var d = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
	return d;
}

function exportSensorValues(unit, exportOptions) {

	 /*excel.set is the main function to generate content:
	  - We can use parameter notation excel.set(sheetValue,columnValue,rowValue,cellValue,styleValue) 
	  - OR object notation excel.set({sheet:sheetValue,column:columnValue,row:rowValue,value:cellValue,style:styleValue })
	  - null or 0 are used as default values for undefined entries*/	
    
    gSheetRowIndex = 0;
    let excel = JExcel.new("Calibri 12 #333333"); /*Default font*/

    excel = exportDiagram(excel, unit, exportOptions);
    excel.generate("Sensor Values " + unit.name.toString() + ".xlsx");

	/*excel.set(sheet, 1, undefined, 30);										// Set COLUMN 1 to 30 chars width	*/
	/*excel.set(sheet, 3, 3, undefined, excel.addStyle({ align: "R B" }));		// Align cell 3-3  to RIGHT-BOTTOM  */
}

function writeMainHeader(excel, sheet, unit) {

    let sensorValuesExportAlignment = excel.addStyle({ 																	
        align: "C C"
    });
    for (var i = 2; i < 45; i++) excel.set({ sheet: sheet, row: i, style: sensorValuesExportAlignment });

    let formatHeaderRow = excel.addStyle({
        border: "none, none, none, thin #333333"
    });
    for (var i = 0; i <= 7; i++) {
        excel.set(sheet, i, 0, "", formatHeaderRow);
    }

    let formatHeader = excel.addStyle({
        border: "none, none, none, thin #333333",
        font: "Calibri 16 #333333 B",
        align: "L"
    });
    excel.set(sheet, 0, 0, "Values Report", formatHeader);

    let formatDateHeader = excel.addStyle({
        border: "none, none, none, thin #333333",
        align: "L"
    });

    //let wUnit = wialon.core.Session.getInstance().getItem(unit.imei);
    //if (!wUnit) { console.log("excelexportJS: writeMainHeader - unit not found"); return; }
    //let unitPosition = wUnit.getPosition();
    let time = unit.timeUpdated;
    if (global.sensorValuesDiagramDateSelected)
        time = formatDateTime(global.selectedDateTime);
    excel.set(sheet, 6, 0, time, formatDateHeader);
    var dateStyle = excel.addStyle({
        format: "yyyy-mm-dd hh:mm:ss", /*format: "yyyy.mm.dd hh:mm:ss" - using DATE mask, Check $JExcel.formats for built-in formats or provide your own */
    });

    //let formatFooterRow = excel.addStyle({
    //    border: "none, none, none, thin #333333"
    //});
    //for (var i = 0; i <= 7; i++) {
    //    excel.set(sheet, i, 45, "", formatFooterRow);
    //}

    return excel;
}

function writeSubHeader(excel, sheet, unitName) {

    excel.set({ sheet: sheet, column: 0, value: 12 })
    let formatSubHeader = excel.addStyle({
        align: "L", font: "Calibri 12 #333333 B"
    });

    //if (sheet === 0) {
        excel.set(sheet, 0, 1, "\u200c" + unitName, formatSubHeader);
    //} else {
    //    const trailer = unit.trailers.filter(t => t.order === sheet)[0];
    //    // If an object is found, return its name; otherwise, return null or any other default value
    //    excel.set(sheet, 0, 1, "\u200c" + trailer ? trailer.name : "", formatSubHeader);
    //}

    return excel;
}

function exportDiagram(excel, unit, exportOptions) {

    const unitConfig = unit.unitConfig;
    
    for (let un = 0; un < unitConfig.length; un++) {
        if (un === 0) {
            excel.set({ sheet: un, value: unit.name.toString() });
            excel = writeMainHeader(excel, un, unit);
            excel = writeSubHeader(excel, un, unit.name);
            excel = exportUnitSensorValues(excel, un, unit, exportOptions);
        } else {
            gSheetRowIndex = 0;

            const trailerName = unit?.trailers?.filter(t => t.order === un)[0]?.name || `Trailer ${un}`;

            excel.addSheet();
            excel.set({ sheet: un, value: trailerName});

            excel = writeMainHeader(excel, un, unit);
            excel = writeSubHeader(excel, un, trailerName);

            excel = exportUnitSensorValues(excel, un, unit, exportOptions);
            //excel = exportUnitSensorValues(excel, un, unitNumbers[un].toString(), unit.locationSensors, unit.wheelSensors, exportOptions);
        }   
    }    

	return excel;
}

function exportUnitSensorValues(excel, un, unit, exportOptions) {

    const unitAxles = unit.unitConfig[un].axles;
    for (let a = 0; a < unitAxles.length; a++) {
        const axleUnit = unit.trailerNumber ? unitAxles[a].axle + unit.trailerNumber : unitAxles[a].axle + un;
        const wheelConfig = unitAxles[a].wheelConfig;
        const axlesensors = getUnitAxleSensors(unit.wheelSensors, axleUnit);
        excel = createUnitAxleSensor(excel, un, a + +1, wheelConfig, axlesensors, exportOptions);
     }

    const unitSpareSensors = unit.trailerNumber ? unit?.spareSensors?.filter(sensor => 
        sensor.wheelId.toString().slice(-1) === unit.trailerNumber.toString()) : unit?.spareSensors?.filter(sensor => 
        sensor.wheelId.toString().slice(-1) === un.toString()
    );
    if (unitSpareSensors.length) {
        excel = createSpareAxleSensors(excel, un, unitAxles.length + +1, unitSpareSensors, exportOptions);
    }

    //let axleMin = 0;
    //let axleMax = 0;
    //let axles = [];
    //for (let s = 0; s < unit.sensors.length; s++) { //if axle!==9 (Spare)
    //    if (parseInt(unit.wheelSensors[s].wheelId.slice(0, 1)) !== 17)
    //        axles.push(parseInt(unit.sensors[s].wheelId.slice(0, 1)));
    //}
    //axleMin = Math.min.apply(this, axles);
    //axleMax = Math.max.apply(this, axles);

    //for (let a = axleMin; a <= axleMax; a++) {
    //    let axleSensors = unit.sensors.filter(s => parseInt(s.wheelId.slice(0, 1)) === a);
    //    excel = createUnitAxleSensor(excel, sheet, a, axleSensors, exportOptions); /* axleCount */
    //}
    //let axleSpareSensors = false;
    //unit.sensors.filter(s => parseInt(s.wheelId.slice(0, 1)) === 17).length === 0 ? axleSpareSensors = false : axleSpareSensors = true;
    //if (axleSpareSensors) {
    //    let axleSensors = unit.sensors.filter(s => parseInt(s.wheelId.slice(0, 1)) === 17);
    //    excel = createSpareAxleSensors(excel, sheet, axleMax + +1, axleSensors, exportOptions);
    //}

    return excel;
}

function createUnitAxleSensor(excel, sheet, axle, wheelConfig, axleSensors, exportOptions) {
   
    excel = createSensorAxleLeftSideExcel(excel, sheet, axle, wheelConfig, axleSensors, exportOptions);
    excel = createSensorAxleRightSideExcel(excel, sheet, axle, wheelConfig, axleSensors, exportOptions);

    return excel;
}

function createSpareAxleSensors(excel, sheet, axleCount, axleSensors, exportOptions) {

    /* Spare Sensors : position (excel column) 3 & 4 */ 
    excel = setSensorElement(excel, sheet, axleCount, 1, axleSensors, exportOptions);

    if (axleSensors.length > 1) {
        excel = setSensorElement(excel, sheet, axleCount, 2, axleSensors, exportOptions);
    }

    return excel;
}

function createSensorAxleLeftSideExcel(excel, sheet, axle, wheelConfig, axleSensors, exportOptions) {

    excel = setSensorElement(excel, sheet, axle, 1, axleSensors, exportOptions);

        if (wheelConfig === 1) {
            excel = setSensorElement(excel, sheet, axle, 2, axleSensors, exportOptions);
        }

    return excel;
}

function createSensorAxleRightSideExcel(excel, sheet, axle, wheelConfig, axleSensors, exportOptions) {

    if (wheelConfig === 1) {
        excel = setSensorElement(excel, sheet, axle, 3, axleSensors, exportOptions);
    }

    excel = setSensorElement(excel, sheet, axle, 4, axleSensors, exportOptions);

    return excel;
}

function setSensorElement(excel, sheet, axleCount, tyre, axleSensors, exportOptions) {

    let optionsToExport = [];
    Object.keys(exportOptions).forEach(option => exportOptions[option] === true && optionsToExport.push(option));
    let lineCount = optionsToExport.length + +3;    

    let tyreColumnIndex = 0;
    let tyreArrayIndex = -1;
    tyre <= 2 ? tyreColumnIndex = tyre : tyreColumnIndex = tyre + +2;
    let axle = axleCount;
    if (axleSensors.length) {
        tyreArrayIndex = getTyreArrayIndexTest(tyre, axleSensors);
        if (axleSensors[0].wheelId.slice(-3) === "170") {
            tyreColumnIndex = tyre + +2;
            axle = "S";
        }
        else {
            //tyre <= 2 ? tyreColumnIndex = tyre : tyreColumnIndex = tyre + +2;
            axle = parseInt(axleSensors[0].wheelId.slice(1, 3));
        }
    }

    excel = setTyreName(excel, sheet, axleCount, tyreColumnIndex, axle, tyre, lineCount);    

    if (exportOptions.tyreIdCheck)
        excel = setTyreId(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

    if (exportOptions.treadDepthCheck)
        excel = setTreadDepth(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

    if (!global.sensorValuesDiagramDateSelected) {
        let sensorType = "tyre-nosignal";
        if (tyreArrayIndex !== -1)
            sensorType = axleSensors[tyreArrayIndex].sensorType;

        if (sensorType !== "tyre-nosignal") {
            if (exportOptions.pressureCheck)
                excel = setSensorPressure(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

            if (exportOptions.temperatureCheck)
                excel = setSensorTemperature(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

            if (exportOptions.voltageCheck)
                excel = setSensorVoltage(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

        } else {
            excel = setSensorNoSignal(excel, sheet, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);
        }
    } else {
        let selectedDateSensorType = "tyre-nosignal";
        if (tyreArrayIndex !== -1) {
            if (exportOptions.pressureCheck)
                excel = setSensorPressure(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

            if (exportOptions.temperatureCheck)
                excel = setSensorTemperature(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

            if (exportOptions.voltageCheck)
                excel = setSensorVoltage(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

        } else {
            excel = setSensorNoSignal(excel, sheet, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);
        }
    }

    excel = setNoteField(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount);

    return excel;
}

function setTyreName(excel, sheet, axleCount, tyreColumnIndex, axle, tyre, lineCount) {

    let style = excel.addStyle({ 												
        border: "thin #333333,thin #333333,thin #333333,thin #333333", 
        font: "Calibri 12",
        fill: "#5e87a7"
    });

    gSheetRowIndex = ((axleCount - +1) * lineCount) + +2;
 
    if (axle === "S") {        
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "Spare " + tyre, style);
    } else {
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "A" + axle + "-T" + tyre, style);
    }

    return excel;
}

function setTyreId(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount) {

    let style = excel.addStyle({ 															// Format for headers
        border: "thin #333333,thin #333333,thin #333333,thin #333333", 													// 		Border for header
        font: "Calibri 9 B",
        //fill: "#696969"
    });

    let tyreId = "No Signal"
    if (tyreArrayIndex !== -1)
        tyreId = axleSensors[tyreArrayIndex]?.externalTyreId?.toString() || "-";

    gSheetRowIndex = ((axleCount - +1) * lineCount) + +3;

    if (exportOptions.tyreIdCheck) {
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, tyreId, style);
    }
 
    return excel;
}

function setTreadDepth(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount) {

    let style = excel.addStyle({ 															
        border: "thin #333333,thin #333333,thin #333333,thin #333333", 											
        //font: "Calibri 8 B"
    });

    let treadDepth = "";
    if (tyreArrayIndex !== -1)
        treadDepth = axleSensors[tyreArrayIndex]?.tyreTreadDepth?.toString() || "-";

    gSheetRowIndex = ((axleCount - +1) * lineCount) + +3;

    if (exportOptions.tyreIdCheck) {
        gSheetRowIndex++;
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, `${treadDepth} mm`, style);
    }
    else {
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, treadDepth, style);
    }

    return excel;
}

function setSensorPressure(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount) {

    let style = excel.addStyle({ 															// Format for headers
        border: "thin #333333, thin #333333, thin #333333, thin #333333"
    });

    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDatePressureLabelColour) {
        if (axleSensors[tyreArrayIndex].selectedDatePressureLabelColour === "red") {
            style = excel.addStyle({ 															/* Format for headers */
                border: "thin #333333, thin #333333, thin #333333, thin #333333", 				/* Border for header  */ 
                font: "Calibri 12 #FFFFFF B",
                fill: "#FF0000"
            });
        }

        if (axleSensors[tyreArrayIndex].selectedDatePressureLabelColour === "purple") {
            style = excel.addStyle({ 															/* Format for headers */
                border: "thin #333333, thin #333333, thin #333333, thin #333333", 				/* Border for header  */
                font: "Calibri 12 #FFFFFF B",
                fill: "#7557AE"
            });
        }
    } else {
        if (axleSensors[tyreArrayIndex].pressureLabelColour === "red") {
            style = excel.addStyle({ 															/* Format for headers */
                border: "thin #333333, thin #333333, thin #333333, thin #333333", 				/* Border for header  */
                font: "Calibri 12 #FFFFFF B",
                fill: "#FF0000"
            });
        }

        if (axleSensors[tyreArrayIndex].pressureLabelColour === "purple") {
            style = excel.addStyle({ 															/* Format for headers */
                border: "thin #333333, thin #333333, thin #333333, thin #333333", 				/* Border for header  */
                font: "Calibri 12 #FFFFFF B",
                fill: "#7557AE"
            });
        }

    }

    let pressure = parseFloat(axleSensors[tyreArrayIndex].currentPressure).toFixed(2);
    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDatePressureValue) {       
        pressure = parseFloat(axleSensors[tyreArrayIndex].selectedDatePressureValue).toFixed(2);
    }

    gSheetRowIndex = ((axleCount - +1) * lineCount) + +3;

    if (exportOptions.tyreIdCheck && exportOptions.treadDepthCheck) {
        gSheetRowIndex = gSheetRowIndex + +2;
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, pressure + " bar", style);
    }
    else {
        if (exportOptions.tyreIdCheck || exportOptions.treadDepthCheck) {
            gSheetRowIndex++;
            excel.set(sheet, tyreColumnIndex, gSheetRowIndex, pressure + " bar", style);
        }
        else {
            gSheetRowIndex = ((axleCount - +1) * lineCount) + +3;
            excel.set(sheet, tyreColumnIndex, gSheetRowIndex, pressure + " bar", style);
        }
    }

    return excel;
}

function setSensorTemperature(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount) {

    let style = excel.addStyle({ 															
        border: "thin #333333, thin #333333, thin #333333, thin #333333"
    });

    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateTemperatureLabelColour) {
        if (axleSensors[tyreArrayIndex].selectedDateTemperatureLabelColour === "orange") {
            style = excel.addStyle({
                border: "thin #333333, thin #333333, thin #333333, thin #333333", 
                font: "Calibri 12 #FFFFFF B",
                fill: "#FF580F"
            });
        }
    } else {
        if (axleSensors[tyreArrayIndex].temperatureLabelColour === "orange") {
            style = excel.addStyle({
                border: "thin #333333, thin #333333, thin #333333, thin #333333", 													// 		Border for header
                font: "Calibri 12 #FFFFFF B",
                fill: "#FF580F"
            });
        }
    }

    let temperature = axleSensors[tyreArrayIndex].currentTemperature;
    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateTemperatureValue) {
             temperature = axleSensors[tyreArrayIndex].selectedDateTemperatureValue;
    }

    if (exportOptions.tyreIdCheck && exportOptions.treadDepthCheck && exportOptions.pressureCheck) {
        
       gSheetRowIndex = ((axleCount - +1) * lineCount) + +6;

       excel.set(sheet, tyreColumnIndex, gSheetRowIndex, temperature + " C", style);
    }
    else {
        if (exportOptions.voltageCheck) {
            gSheetRowIndex = ((axleCount - +1) * lineCount) + +(lineCount - 2);
            
            excel.set(sheet, tyreColumnIndex, gSheetRowIndex, temperature + " C", style);
        }
        else {
           gSheetRowIndex = ((axleCount - +1) * lineCount) + +(lineCount - 1);
            
            excel.set(sheet, tyreColumnIndex, gSheetRowIndex, temperature + " C", style);
        }
    }

    return excel;
}

function setSensorVoltage(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount) {

    let style = excel.addStyle({ 															
        border: "thin #333333, thin #333333, thin #333333, thin #333333"
    });

    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateVoltageLabelColour) {
        if (axleSensors[tyreArrayIndex].selectedDateVoltageLabelColour === "yellow") {
            style = excel.addStyle({
                border: "thin #333333, thin #333333, thin #333333, thin #333333", 													// 		Border for header
                font: "Calibri 12 #FFFFFF B",
                fill: "#FFCC00"
            });
        }
    } else {
        if (axleSensors[tyreArrayIndex].voltageLabelColour === "yellow") {
            style = excel.addStyle({
                border: "thin #333333, thin #333333, thin #333333, thin #333333", 													// 		Border for header
                font: "Calibri 12 #FFFFFF B",
                fill: "#FFCC00"
            });
        }
    }

    let voltage = axleSensors[tyreArrayIndex].currentVoltage;
    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateVoltageValue) {
        voltage = axleSensors[tyreArrayIndex].selectedDateVoltageValue;
    }    

    if (exportOptions.tyreIdCheck && exportOptions.treadDepthCheck && exportOptions.pressureCheck && exportOptions.temperatureCheck) {
    
        gSheetRowIndex = ((axleCount - +1) * lineCount) + +7;
        
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, voltage + " V", style);
    }
    else {
            gSheetRowIndex = ((axleCount - +1) * lineCount) + +(lineCount - 1);
        
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, voltage + " V", style);
    }

    return excel;
}

function setNoteField(excel, sheet, tyreArrayIndex, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount) {

    let style = excel.addStyle({
        border: "thin #333333, thin #333333, thin #333333, thin #333333",
        font: "Calibri 12 #e5e5e5 B"
    });

    if (exportOptions.tyreIdCheck && exportOptions.treadDepthCheck && exportOptions.pressureCheck && exportOptions.temperatureCheck && exportOptions.voltageCheck) {
     
        gSheetRowIndex = ((axleCount - +1) * lineCount) + +8;

        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "", style);
    }
    else {

        gSheetRowIndex = ((axleCount - +1) * lineCount) + +(lineCount);

        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "", style);
        //if (exportOptions.voltageCheck) {
        //    gSheetRowIndex = ((axleCount - +1) * lineCount) + +(lineCount - 1);

        //    excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "Note", style);
        //}
        //else {
        //    gSheetRowIndex = ((axleCount - +1) * lineCount) + +(lineCount);

        //    excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "Note", style);
        //}
    }

    return excel;
}

function setSensorNoSignal(excel, sheet, tyreColumnIndex, axleCount, axleSensors, exportOptions, lineCount) {



     const style = excel.addStyle({ 															
            border: "thin #333333, thin #333333, thin #333333, thin #333333", 													// 		Border for header
            font: "Calibri 12 #FFFFFF B",
            fill: "#009DFF"
        });


    if (exportOptions.tyreIdCheck && exportOptions.treadDepthCheck) {
        gSheetRowIndex = ((axleCount - +1) * lineCount) + +5;
        excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "No Signal", style);
    }
    else {
        if (exportOptions.tyreIdCheck || exportOptions.treadDepthCheck) {
            gSheetRowIndex = ((axleCount - +1) * lineCount) + +4;
            excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "No Signal", style);
        }
        else {
            gSheetRowIndex = ((axleCount - +1) * lineCount) + +3;
            excel.set(sheet, tyreColumnIndex, gSheetRowIndex, "No Signal", style);
        }
    }

    return excel;
}

const getUnitAxleSensors = (wheelSensors, axleUnit) => {

    return wheelSensors.filter(s => s.wheelId.slice(-3) === axleUnit);    
}


export { exportSensorValues };



