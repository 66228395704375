import global from '../utils/global.js'
import { getUserData } from './auth.js';

const baseUrl = 'https://cloud-connector-dashboard.restiolabs.com/api';

const { token, parentCustomerId } = getUserData() || {};

global.token = token;
global.customerId = parentCustomerId;

export const createTruck = async (customerId, truckData) => {

    const apiUrl = `${baseUrl}/customer/${customerId}/truck`; 
    
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(truckData),
      });
  
      if (!response.ok) {
        // Handle errors (e.g., validation issues, customer not found, etc.)
        const errorDetails = await response.json();
        console.error("Error creating truck:", errorDetails);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Parse and return the JSON response for a successful request
      const responseData = await response.json();
      console.log("Truck created successfully:", responseData);
      return responseData;
  
    } catch (error) {
      console.error("An error occurred:", error.message);
      throw error;
    }
}

export const updateTruck = async (truck, truckData) => {

    const apiUrl = `${baseUrl}/customer/${truck.customerId}/truck/${truck.Id}`;
    
    try {
      const response = await fetch(apiUrl, {
        method: "PATCH", // PATCH method for partial updates
        headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(truckData), // Convert the truck data to JSON format
      });
  
      if (!response.ok) {
        // Handle errors, e.g., invalid data or IDs not found
        const errorDetails = await response.json();
        console.error("Error updating truck:", errorDetails);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Parse and return the JSON response for a successful request
      const responseData = await response.json();
      console.log("Truck updated successfully:", responseData);
      return responseData;
  
    } catch (error) {
      console.error("An error occurred:", error.message);
      throw error;
    }
}

export const fetchYards = async (customerId) => {
    
    try {   

        let customerId = global.customerId || parentCustomerId;
        let userToken = global.token || token;

        if (!customerId || !userToken) {
            const { token, parentCustomerId } = getUserData() || {};
            customerId = parentCustomerId;
            userToken = token;
        }  

        const apiUrl = `${baseUrl}/customer/${customerId}/yard?inclChild=true&limit=0`;
                
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error('unitsJS: fetchYards - ERROR ', e);
    }
}

export const fetchTrucks = async (customerId) => {
    
    try { 
        let userToken = global.token || token;  
        if(!customerId) {
            customerId = global.customerId || parentCustomerId;            
    
            if (!customerId || !userToken) {
                const { token, parentCustomerId } = getUserData() || {};
                customerId = parentCustomerId;
                userToken = token;
            } 
        }



 
        const apiUrl = `${baseUrl}/customer/${customerId}/truck?inclChild=true&limit=0`;
                
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error('unitsJS: fetchUnits - ERROR ', e);
    }
}

export const createTrailer = async (trailerData) => {

    const customerId = parentCustomerId;
    const apiUrl = `${baseUrl}/customer/${customerId}/trailer`; 
    
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(trailerData),
      });
  
      if (!response.ok) {
        // Handle errors (e.g., validation issues, customer not found, etc.)
        const errorDetails = await response.json();
        console.error("Error creating trailer:", errorDetails);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Parse and return the JSON response for a successful request
      const responseData = await response.json();
      console.log("Trailer created successfully:", responseData);
      return responseData;
  
    } catch (error) {
      console.error("An error occurred:", error.message);
      throw error;
    }
}

export const deleteTruck = async (customerId, truckId) => {

    const apiUrl = `${baseUrl}/customer/${customerId}/truck/${truckId}`;

    try {
        const response = await fetch(apiUrl, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            // Handle errors (e.g., trailer not found, unauthorized, etc.)
            const errorDetails = await response.json();
            console.error("Error deleting trailer:", errorDetails);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse and return the JSON response for a successful request
        const responseData = await response.json();
        console.log("Truck deleted successfully:", responseData);
        return responseData;

    } catch (error) {
        console.error("An error occurred:", error.message);
        throw error;
    }
}

export const deleteTrailer = async (customerId, trailerId) => {

    const apiUrl = `${baseUrl}/customer/${customerId}/trailer/${trailerId}`;

    try {
        const response = await fetch(apiUrl, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            // Handle errors (e.g., trailer not found, unauthorized, etc.)
            const errorDetails = await response.json();
            console.error("Error deleting trailer:", errorDetails);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse and return the JSON response for a successful request
        const responseData = await response.json();
        console.log("Trailer deleted successfully:", responseData);
        return responseData;

    } catch (error) {
        console.error("An error occurred:", error.message);
        throw error;
    }
}

export const updateTrailer = async (trailer, trailerData) => {

    const apiUrl = `${baseUrl}/customer/${trailer.customerId}/trailer/${trailer.Id}`;
    
    try {
      const response = await fetch(apiUrl, {
        method: "PATCH", // PATCH method for partial updates
        headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(trailerData), // Convert the truck data to JSON format
      });
  
      if (!response.ok) {
        // Handle errors, e.g., invalid data or IDs not found
        const errorDetails = await response.json();
        console.error("Error updating trailer:", errorDetails);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Parse and return the JSON response for a successful request
      const responseData = await response.json();
      console.log("Trailer updated successfully:", responseData);
      return responseData;
  
    } catch (error) {
      console.error("An error occurred:", error.message);
      throw error;
    }
  }

export const fetchTrailers = async () =>{
    try {   

        let customerId = global.customerId || parentCustomerId;
        let userToken = global.token || token;

        if (!customerId || !userToken) {
            const { token, parentCustomerId } = getUserData() || {};
            customerId = parentCustomerId;
            userToken = token;
        }  

        const apiUrl = `${baseUrl}/customer/${customerId}/trailer?inclChild=true&limit=0`;
                
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error('unitsJS: fetchUnits - ERROR ', e);
    }
}

export const fetchUnits = async () =>{

    try { 

        let customerId = global.customerId || parentCustomerId;
        let userToken = global.token || token;

        if (!customerId || !userToken) {
            const { token, parentCustomerId } = getUserData() || {};
            customerId = parentCustomerId;
            userToken = token;
        }  
        // console.log("line 324 customerId", customerId);
        // console.log("line 325 userToken", userToken);
        const apiUrl = `${baseUrl}/customer/${customerId}/unit?inclChild=true`;
        //const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/66b3d6c6aaea3e64381a3ae0/unit?inclChild=true`;
        
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error('unitsJS: fetchUnits - ERROR ', e);
    }
}

export const updateAxleRanges =  async (valuesOutOfRangeParams) => {
    try {

        const requestBody = {
            axleNumber: valuesOutOfRangeParams.axleNo,
            recommendedPressure: valuesOutOfRangeParams.recommendedPressure,
            pressureDeviationAllowedLow: valuesOutOfRangeParams.pressureDeviationAllowedLow,
            pressureDeviationAllowedHigh: valuesOutOfRangeParams.pressureDeviationAllowedHigh,
            minPressure: valuesOutOfRangeParams.minPressure,
            maxPressure: valuesOutOfRangeParams.maxPressure,
            maxTemperature: valuesOutOfRangeParams.maxTemperature,
            minVoltage: valuesOutOfRangeParams.minVoltage
        };

        const apiUrl = `${baseUrl}/customer/${valuesOutOfRangeParams.customerId}/${valuesOutOfRangeParams.unitType}/${valuesOutOfRangeParams.unitId}/ranges`;

        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Update Axle Ranges:", responseData.message);
        } else {
            console.error("Update Axle Ranges - Error:", responseData);
        }

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error("Error updating axle ranges:", e);
    }
}

export async function updateNotificationSettings(unit) {
    try {

        const requestBody = { notificationSettings: unit.notificationSettings };

        const apiUrl = `${baseUrl}/customer/${unit.customerId}/unit/${unit.imei}`;

        const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating notification settings:", e);
    }
}

export async function getLocationCoordinates(imei, timestamp) {

    let apiUrl = `${baseUrl}/unit/${imei}/location?timestamp=${timestamp}`;
    try {

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching location data:', error);
        throw error;
    }
}

export async function updateDriver(truck) {
    try {
 
        const requestBody = { driverName: truck.driverName, driverContactNumber: truck.driverContactNumber };

        const apiUrl = `${baseUrl}/customer/${truck.customerId}/unit/${truck.unitId}`;

        const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating driver info:", e);
    }
}