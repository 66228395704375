/* Psalm 127:1-2
As die HERE die huis nie bou nie, tevergeefs werk die wat daaraan bou;
as die HERE die stad nie bewaar nie, tevergeefs waak die wagter.
Tevergeefs dat julle vroeg opstaan, laat opbly, brood van smarte eet —
net so goed gee Hy dit aan sy beminde in die slaap! */

import global from '../common/utils/global.js';

import { validateToken, getUserData } from '../common/data/auth.js';

import { showSplash } from '../common/ui/splash.js';
import { handleAuthentication } from '../common/utils/authhandler.js';

import { onlineConnection } from '../common/utils/connectionhandler.js';

import { openUsersForm } from './ui/components/usersview.js';
import { setMainInterval } from './ui/mainpage.js';

// window.onload = () => {  
//   'use strict';   
//   console.log(`register`);
//   if ("serviceWorker" in navigator) {
//     console.log(`register1`);
//     navigator.serviceWorker.register("../sw.js");
//   }
// }
//global.selectedUnit = {};

var gIntervalId = 0;

document.addEventListener('DOMContentLoaded', async function () {
  let customerToken = validateToken();
  const version = getVersion();  
  if (customerToken?.valid) {      
    showSplash(customerToken, version);  
    await loadApp();
  } else {
    customerToken = await handleAuthentication();
    if (customerToken) {
      setTimeout(async () => {        
        showSplash(customerToken, version);  
        // Wait for local storage to be ready before loading the app
        let retries = 0;
        while (!localStorage.getItem("userData") && retries < 10) {
          console.log(`Waiting for local storage... Retry ${retries + 1}/10`);
          await new Promise((resolve) => setTimeout(resolve, 500));
          retries++;
        }        
        loadApp();
      }, 1500);
    }
  }
});

const getVersion = () => {

  const currentVersion = "2.0.44"; 
  const previousVersion = localStorage.getItem("version-d");

  if (previousVersion) {
      if (`PressureTrack v${currentVersion}` !== previousVersion) {
          localStorage.setItem("version-d", `PressureTrack v${currentVersion}`);
          location.reload(true);
      }
  } else {
      localStorage.setItem("version-d", `PressureTrack v${currentVersion}`);
      location.reload(true);
  }

  document.getElementById('version').innerText = `version ${currentVersion}`;

  return currentVersion;
}

async function loadApp() {
  try { 
    let retries = 0;
    while (!localStorage.getItem("userData") && retries < 10) {
        console.log(`⏳ Waiting for local storage... Retry ${retries + 1}/10`);
        await new Promise((resolve) => setTimeout(resolve, 500));
        retries++;
    }
    const { eMail, userRole } = getUserData() || {};    
    const user = document.getElementById('currentuser');
    user.textContent = eMail;
    if (userRole === 'Administrator') {
      enableManageUsersItem();
    }  

    const usersManagementItem = document.getElementById('usersmanagement');
    usersManagementItem.onclick = (e) => {
        e.stopImmediatePropagation();
        openUsersForm();
    };

    const signOutItem = document.getElementById('signout');
    signOutItem.onclick = (e) => {
        e.stopImmediatePropagation();
        openSignOutModal();
    };

    global.showMainPage = true;
    setMainInterval();

    const container = document.getElementById('container');
    if (container) container.style.display = 'block';
     
  } catch (error) {
      console.error('Error loading app:', error);
      displayError('Failed to load the application. Please try again.');
  }
}

const enableManageUsersItem = () => {
  const usersmanagement = document.getElementById('usersmanagement');
  usersmanagement.onclick = (e) => {
      e.stopImmediatePropagation();
      openUsersForm();
  };
  usersmanagement.classList.remove("disabled");
  usersmanagement.style.pointerEvents = "auto"; // Enable interaction
  usersmanagement.style.opacity = "1"; // Restore visibility
}

const openSignOutModal = () => {

  const signOutModal = new bootstrap.Modal(document.getElementById('signoutmodal'), {});
    signOutModal.show();
    const signOutButton = document.getElementById('signoutbutton');
    signOutButton.onclick = () => {

      if (global.mainIntervalId) {
        clearInterval(global.mainIntervalId);
      }

      if (global.detailIntervalId) {
        clearInterval(global.detailIntervalId);
      }

      localStorage.clear(); 
      sessionStorage.clear();
      location.reload();
      // Hide the modal after sign out
      signOutModal.hide();
      window.location.href = window.location.href;
      window.location.replace(window.location.href);
    };
}

function hideAppSections() {
  const sectionsToHide = ['container', 'mainpane', 'detailspane'];
  sectionsToHide.forEach(id => {
      const element = document.getElementById(id);
      if (element) element.style.display = 'none';
  });
}

// Generic function to show errors
function displayError(message) {
  const statusElement = document.getElementById('status');
  if (statusElement) {
      statusElement.textContent = message;
  }
}

async function handleVerification(verificationToken) {
    toggleVerifySection(true);

    try {
      const statusElement = document.getElementById('status');
        const tokenData = await verifyToken(verificationToken);  // Fetch token via the separated function
        

        if (tokenData.valid) {
            //const customerToken = validateToken(data.jwtToken);

            if (statusElement) statusElement.textContent = 'Verification successful! Redirecting...';
            
            setTimeout(() => {
                // Hide the login form
                const loginForm = document.getElementById('login');
                if (loginForm) loginForm.style.display = 'none';

                // Get app version and show splash
                const version = getVersion();
                showSplash(tokenData.decodedToken.user.email, version);

                // Load the app
                loadApp();
            }, 1500);
        } else {
            if (statusElement) statusElement.textContent = 'Verification failed. Please try again.';
        }
    } catch (error) {
        displayError('Error during verification. Please try again.');
    }
}

async function login(siteType) {
  try {
      document.getElementById('login').addEventListener('submit', async function (event) {
          event.preventDefault();

          const emailAddress = document.getElementById('username').value;
          const emailSent = await sendLoginMail(emailAddress, siteType);

          if (emailSent.success) {
              const loginForm = document.getElementById('login');
              if (loginForm) loginForm.style.display = 'none';
              const emailSentSection = document.getElementById('email-sent');
              if (emailSentSection) emailSentSection.style.display = 'block';
          } else {
              alert('Something went wrong. Try again!');
          }
      });
  } catch (e) {
      displayError('Login failed. Please try again.');
  }
}

function toggleVerifySection(showVerify) {
  const verifySection = document.getElementById('verify');

  //if (emailSentSection) emailSentSection.style.display = showVerify ? 'none' : 'block';
  if (verifySection) verifySection.style.display = showVerify ? 'block' : 'none';
}

export function logStatus(logText) {

  const onlineStatus = document.getElementById('appstatus');
  const onlineStatusDiv = document.createElement('div');
  onlineStatusDiv.className = 'appstatus offline';

  const spanTime = document.createElement('span');
  spanTime.innerText = `[${new Date().toLocaleTimeString()} - ${global.currentView}] `;

  const spanOnlineStatus = document.createElement('span');  
  spanOnlineStatus.innerText = logText;
  onlineStatusDiv.appendChild(spanTime);
  onlineStatusDiv.appendChild(spanOnlineStatus);

  onlineStatus.appendChild(onlineStatusDiv);

  return;
}

function getOnlineStatus() {
  try {  
    window.addEventListener("load", (ev) => {
      ev.preventDefault();
      isOnline(onlineConnection());

      window.addEventListener("online", (e) => {
        e.preventDefault();
        //isOnline(true);              
        isOnline(onlineConnection());  
      });
    
      window.addEventListener("offline", (e) => {
        e.preventDefault();
        isOnline(false);
      });    
    });
  } catch (error) {
      handleError(error, "", "mainJS -> getOnlineStatus");
  }
}

const showLoginPage = (version) => {

  const headerNavMain = document.getElementById('headernavmain');
  headerNavMain.className ='headernavmain-login d-flex justify-content-center align-items-center';     
  while (headerNavMain.hasChildNodes()) {
    headerNavMain.removeChild(headerNavMain.lastChild);
  }

  const headerLogo = document.createElement('div');
  headerLogo.id = 'headerlogo';
  headerLogo.className = 'headerlogo-login';
  headerNavMain.appendChild(headerLogo);   

  const footerLogin = document.getElementById('diagramtoggle');    
  while (footerLogin.hasChildNodes()) {
    footerLogin.removeChild(footerLogin.lastChild);
  }
  footerLogin.className = 'footermain-login'; // align-items-end d-flex 

  const loginVersion = document.createElement('div');
  loginVersion.className = 'version';
  loginVersion.innerText = `version ${version}`;   

  footerLogin.appendChild(loginVersion);
}

const clearStorage = () => {
  // localStorage.removeItem("customer");
  // localStorage.removeItem("e-mail");
  // localStorage.removeItem("role");  
  // localStorage.removeItem("token");  
  localStorage.removeItem("userData"); 
  localStorage.removeItem("version-d");   
  localStorage.removeItem("version-m");  
}

export const onSleepSignOut = () => {
  
  window.onfocus = (e) => { 
  //window.addEventListener('focus', (e) => {
    e.preventDefault();
    // logStatus(` event: window.onfocus -> getToken()`);
    //getToken();
  }
  //);

  window.onresize = () => {
    //getToken();
  }

  window.addEventListener("touchend", () => {
    location.reload(true); 
    //getToken();
  });

  // addEventListener('pageshow', () => {
  //   history.replaceState({}, document.title, window.location.pathname);
  //   // called on initial load and first back
  // });

  // function isSafariMobile() {
  //   return navigator && /Safari/.test(navigator.userAgent) && /iPhone|iPad/.test(navigator.userAgent)
  // }

  // let myCustomEvent =
  // navigator.userAgent.match('iPhone') != null ? 'popstate' : 'pageshow';
  // window.on(myCustomEvent, function (e) {
  //   alert(`add this!`); // 'popstate' called on all back events
  // });

  // window.onpageshow = function(event) { //Persisted is false on initial page load, so you can check against it, and if it false, it is your first page load.
  //   if (!event.persisted) {
  //       alert("hello");
  //   }
  // };

  window.addEventListener('pageshow', (e) => { 
    e.preventDefault();
    // logStatus(` event: window.addEventListener PAGESHOW`);
    location.reload(true); 
    //getToken();
  });

  if (gIntervalId) {
    clearInterval(gIntervalId);
    gIntervalId = 0;  
    // logStatus(` onSleepSignOut(): gIntervalId = 0`);        
  }
  const session = wialon.core.Session.getInstance();
  console.log(` onSleepSignOut() => session ${session}`)
  console.log(session);
  // logStatus(` onSleepSignOut() => sessionId ${session.getId()}`)
  if (session && session.getId()) {
      session.logout( 
          (error) => { 
              if (error) {
                  console.log(`mainJS: onSleepSignOut(): wialon.core.Session.getInstance().logout ERROR: ${error} ${wialon.core.Errors.getErrorText(error)}`);
                  // logStatus(` onSleepSignOut() => session.logout ERROR: ${error} ${wialon.core.Errors.getErrorText(error)}`);
                  // handleError(error, "", "onSleepSignOut => session.logout");
                  // clearStorage();
              }
                // logStatus(` onSleepSignOut() => session.logout: Logged OUT success!`);
                location.reload(true);           
          }          
      );
  } else {
      console.log(`mainJS: onSleepSignOut => No Session`);
      // logStatus(` onSleepSignOut => No Session`);
      location.reload(true);
  } 
}

export const signOut = () => {
 
    if (global.mainIntervalId) {
        clearInterval(global.mainIntervalId);
    }

    if (global.detailIntervalId) {
        clearInterval(global.detailIntervalId);
    }

    clearStorage();
    location.reload(true);  
}

const storeToken = (token) => {  
  if(token === ""){
    localStorage.removeItem("tePressureTrack");
  }
  else {
    localStorage.setItem("tePressureTrack", eString(token));
  }  
  return;
}

function getTokenString() {

  let tString = "";
  const eString = localStorage.getItem("tePressureTrack"); 

  if (eString) {
     tString = deString(eString); 
     global.currentView = localStorage.getItem("vPressureTrack");  
     global.selectedUnit = JSON.parse(localStorage.getItem("uPressureTrack"));  
  } 

  return tString;
}

function eString(str) {

  let result = "";
  for (let i = 0; i < str.length; i++) {
    let char = (str.charCodeAt(i) + 32) % 256;
    result += String.fromCharCode(char);
  }  
  return result;

}

function deString(str) {

  let result = "";
  for (let i = 0; i < str.length; i++) {
    let char = (str.charCodeAt(i) - 32 + 256) % 256;
    result += String.fromCharCode(char);
  }
  return result;

}

