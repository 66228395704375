import { getUsers, addUser, editUser, deleteUser } from "../../../common/data/users.js";

export const openUsersForm = () => {

    document.getElementById('adduserform').reset();

    const usersModal = new bootstrap.Modal(document.getElementById('usersmodal'), {});
    usersModal.show();
    loadUsers();

    document.getElementById('adduserform').addEventListener('submit', function (e) {
        e.preventDefault();
        const newUser = {
            name: document.getElementById('name').value,
            emailAddress: document.getElementById('emailaddress').value,
            userRole: document.getElementById('userrole').value,
            companyRole: document.getElementById('companyrole').value
        };

        addNewUser(newUser);
    });
}

async function addNewUser(user) {
    await addUser(user);
    loadUsers(); // Reload users after adding
    document.getElementById('adduserform').reset();
}

async function loadUsers() {
    await getUsers().then(users => {
        const tableBody = document.getElementById('usertablebody');
        tableBody.innerHTML = ''; // Clear the table first
        users.forEach(user => {
            const row = document.createElement('tr');
            row.id = `user${user.userId}`;

            // Create and append individual table cells (td)
            const nameCell = document.createElement('td');
            nameCell.textContent = user.name;
            row.appendChild(nameCell);

            const emailCell = document.createElement('td');
            emailCell.textContent = user.emailAddress;
            row.appendChild(emailCell);

            const userRoleCell = document.createElement('td');
            userRoleCell.textContent = user.userRole;
            row.appendChild(userRoleCell);

            const companyRoleCell = document.createElement('td');
            companyRoleCell.textContent = user.companyRole;
            row.appendChild(companyRoleCell);

            // Create the actions cell
            const actionsCell = document.createElement('td');

            // Create Edit button
            const editButton = document.createElement('button');
            editButton.classList.add('btn', 'btn-success', 'btn-sm');
            editButton.textContent = 'Edit';
            editButton.addEventListener('click', () => editSelectedUser(user.userId));
            actionsCell.appendChild(editButton);

            // Create Delete button
            const deleteButton = document.createElement('button');
            deleteButton.classList.add('btn', 'btn-danger', 'btn-sm');
            deleteButton.textContent = 'Delete';
            deleteButton.addEventListener('click', () => deleteSelectedUser(user.userId));
            actionsCell.appendChild(deleteButton);

            // Append the actions cell to the row
            row.appendChild(actionsCell);

            tableBody.appendChild(row);
        });
    });
}

function deleteSelectedUser(userId) {
    deleteUser(userId);
    document.getElementById(`user${userId}`).remove(); // Remove row from table   
}

async function editSelectedUser(userId) {
    const row = document.getElementById(`user${userId}`);
    const user = await getUsers().then(users => users.find(u => u.userId === userId));

    // Replace table cells with input fields for inline editing
    row.innerHTML = '';

    // Create input fields for each cell
    const nameInput = document.createElement('input');
    nameInput.type = 'text';
    nameInput.classList.add('form-control');
    nameInput.value = user.name;

    const emailInput = document.createElement('input');
    emailInput.type = 'email';
    emailInput.classList.add('form-control');
    emailInput.value = user.emailAddress;

    // Create a select dropdown for user role
    const userRoleInput = document.createElement('select');
    userRoleInput.classList.add('form-control');

    // Create options for the dropdown
    const adminOption = document.createElement('option');
    adminOption.value = 'Administrator';
    adminOption.textContent = 'Administrator';
    userRoleInput.appendChild(adminOption);

    const standardOption = document.createElement('option');
    standardOption.value = 'Standard';
    standardOption.textContent = 'Standard';
    userRoleInput.appendChild(standardOption);

    // Set the selected value
    userRoleInput.value = user.userRole;

    const companyRoleInput = document.createElement('input');
    companyRoleInput.type = 'text';
    companyRoleInput.classList.add('form-control');
    companyRoleInput.value = user.companyRole;

    // Create cells and append inputs
    const nameCell = document.createElement('td');
    nameCell.appendChild(nameInput);
    row.appendChild(nameCell);

    const emailCell = document.createElement('td');
    emailCell.appendChild(emailInput);
    row.appendChild(emailCell);

    const userRoleCell = document.createElement('td');
    userRoleCell.appendChild(userRoleInput);
    row.appendChild(userRoleCell);

    const companyRoleCell = document.createElement('td');
    companyRoleCell.appendChild(companyRoleInput);
    row.appendChild(companyRoleCell);

    // Create the actions cell
    const actionsCell = document.createElement('td');

    // Create Save button
    const saveButton = document.createElement('button');
    saveButton.classList.add('btn', 'btn-table-save', 'btn-sm');
    saveButton.textContent = 'Save';
    saveButton.addEventListener('click', async () => {
        // Get updated values from input fields
        const updatedUser = {
            name: nameInput.value,
            //emailAddress: emailInput.value,
            userRole: userRoleInput.value,
            companyRole: companyRoleInput.value
        };

        await editUser(userId, updatedUser); // API call to update the user
        loadUsers(); // Reload users to show the updated data
    });
    actionsCell.appendChild(saveButton);

    // Create Cancel button
    const cancelButton = document.createElement('button');
    cancelButton.classList.add('btn', 'btn-secondary', 'btn-sm');
    cancelButton.textContent = 'Cancel';
    cancelButton.addEventListener('click', () => {
        loadUsers(); // Reload users to revert the changes and restore original view
    });
    actionsCell.appendChild(cancelButton);

    // Append the actions cell to the row
    row.appendChild(actionsCell);
}


