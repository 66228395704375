export const isObjectEmpty = (objectName) => {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  };

export const stringify = (obj) => {
    let cache = [];
    let str = JSON.stringify(obj, function(key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null; // reset the cache
    return str;
  }

export const addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number.toString();
}

export function getPressureLabelColour(value, min, max) {
  if (min !== undefined && max !== undefined) {
      if (value < min) return "red"; // Below minimum
      if (value > max) return "purple"; // Above maximum
      return "green";
  }
  return "green"; //default to green
}

export function getTemperatureLabelColour(value, max) {
  if (max !== undefined) {
      if (value > max) return "orange"; // Above maximum
      return "green";
  }
  return "green"; //default to green
}

export function getVoltageLabelColour(value, min) {
  if (min !== undefined) {
      if (value < min) return "yellow"; // Below minimum
      return "green";
  }
  return "green"; //default to green
}

export function cloneObject(obj) {
  if (obj === null || typeof obj !== 'object') {
      return obj;
  }

  if (Array.isArray(obj)) {
      return obj.map(item => cloneObject(item));
  }

  const clonedObj = {};
  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          clonedObj[key] = cloneObject(obj[key]);
      }
  }
  return clonedObj;
}

